/* eslint-disable */
export default {
    "newbie": "Adı olmayan",
    "logout": "Çıxış",
    days: "gün",
    "onBoardingMixin": {
        "title-1": "Psixotipin tərifi",
        "title-2": "Mənfəət",
        "description-1":
            "Merlin AI sizin psixotipinizi müəyyən etdikdən sonra siz və səhifənizin bütün qonaqları onu görəcəklər. Merlin də sizi sosial şəbəkənin digər istifadəçilərinə tövsiyə etməyə başlayacaq",
        "description-2": "Sertifikat almaq istədiyinizə əminsinizmi?",
    },
    "errors": {
        "unknown-error": "Bilinməyən səhv",
        error: "Xəta baş verdi",
        unknownError: "Naməlum xəta",
        attention: "Diqqət",
        wrongPhotoTitle: "Foto uyğun gəlmir",
        noAccess: "Giriş yoxdur",
        alreadyFriendsTitle: "Siz artıq dostsunuz!",
        info: "Məlumat",
        reEnter: "Hesabınıza yenidən daxil olun və ya bizimlə əlaqə saxlayın.",
        notEnoughMoneyTitle: "Kifayət qədər vəsait yoxdur",
        compareNotAllowedTitle: "Müqayisə mümkün deyil",
        retry: "Xəta baş verdi, zəhmət olmasa daha sonra yenidən cəhd edin.",
        noAccessText:
            "Siz sizə əlçatan olmayan bir funksiyadan istifadə etməyə çalışırsınız.",
        personDataNotReceived:
            "Şəxsiyyət məlumatlarının alınmasında xəta baş verdi, səhifəni yenidən yükləməyiniz tələb oluna bilər.",
        wrongProfilePhoto:
            "Profil üçün şəkil uyğun deyil, başqa şəkil yükləyin. Standart nümunələrə diqqət edin.",
        wrongPhotoToTheRight:
            "Başınızı sağa çox güclü çevirmisiniz, zəhmət olmasa bir daha cəhd edin.",
        wrongPhotoToTheLeft:
            "Şəkil uyğun deyil, başınızı sola çox güclü çevirmisiniz, zəhmət olmasa bir daha cəhd edin.",
        wrongPhotoToTheBottom:
            "Şəkil uyğun deyil, başınızı çox aşağı əyginiz, zəhmət olmasa bir daha cəhd edin.",
        wrongPhotoFewPersons:
            "Şəkildə bir neçə üz aşkarlandı, lütfən fotokəsili şəkildə yalnız lazım olan üzü qoyub kəsin",
        wrongPhotoToTheUp:
            "Şəkil uyğun deyil, başınızı çox yuxarı qaldırmısınız, zəhmət olmasa bir daha cəhd edin.",
        wrongPhotoSame:
            "Siz anfas və profildə eyni şəkilləri yükləmisiniz, zəhmət olmasa başqa şəkil yükləyin",
        wrongPhotoFeatures:
            "Şəkillərinizdən üz cizgilərini əldə etmək mümkün olmadı. Zəhmət olmasa hər iki şəkili dəyişdirin.",
        wrongPhotoYouAreNot:
            "Yeni şəkildə sizin üzünüz tapılmadı. Zəhmət olmasa üzünüz olan şəkili yükləyin",
        wrongAnfasPhoto:
            "Anfas şəkil uyğun deyil, zəhmət olmasa başqa şəkil yükləyin. Standart nümunələrə diqqət edin.",
        wrongCelebrityPhoto:
            "Seçilmiş şəxsin fotolarının analizi zamanı xəta baş verdi. İstifadəçi düzgün şəkilləri yüklədikdə, müqayisə və təsvir mövcud olacaq",
        photoNotUploaded:
            "Şəkili yükləmək mümkün olmadı, zəhmət olmasa daha sonra bir daha cəhd edin...",
        myPhotoNotUploaded:
            "Şəkillərinizi yükləmək mümkün olmadı. Zəhmət olmasa, səhifəni yeniləyin.",
        paymentsListNotReceived:
            "Ödənişlərin siyahısını əldə etmək mümkün deyil, zəhmət olmasa daha sonra cəhd edin.",
        incorrectPaymentData:
            "Yanlış ödəniş məlumatları. Zəhmət olmasa, daha sonra cəhd edin.",
        paymentGateWayNotRespond:
            "Ödəniş şlyuzu cavab vermir. Zəhmət olmasa, daha sonra cəhd edin.",
        friendStatusNotReceived:
            "Dostun statusunu əldə etmək mümkün deyil, zəhmət olmasa daha sonra cəhd edin.",
        pageError:
            "Bu səhifə ilə bağlı naməlum bir problem yaranmışdır, səhifəni yeniləyin və əməliyyatı yenidən cəhd edin.",
        friendRequestError:
            "Dostluq təklifi göndərilə bilmir, daha sonra yenidən cəhd edin.",
        alreadyFriends:
            'Əgər "Dost əlavə et" düyməsini görürsünüzsə, zəhmət olmasa səhifəni yeniləyin.',
        alreadySendFriendRequest:
            "Siz artıq əvvəllər təklif göndərmisiniz. Zəhmət olmasa dostunuzun təklifinizi qəbul etməsini gözləyin.",
        friendRequestDecline:
            "Təəssüflər olsun ki, dostluq təklifiniz rədd edilmişdir. Son cəhddən 24 saat sonra təkrar təklif göndərməyi cəhd edə bilərsiniz.",
        notificationsNotReceived:
            "Sizin bildirişlərinizin siyahısını əldə etmək mümkün deyil, daha sonra yenidən cəhd edin.",
        servicePriceNotReceived:
            "Xidmətin qiyməti alınamadı. Səhifəni yeniləyin və təkrar cəhd edin.",
        needAuth:
            "Bu əməliyyatı yerinə yetirmək üçün avtorizasiya tələb olunur",
        notEnoughMoney:
            "Zəhmət olmasa, hesabınızı əlverişli olduğunuz üsulla doldurun.",
        personListNotReceived:
            "Şəxslərin siyahısını almaqda xəta baş verdi, daha sonra yenidən cəhd edin.",
        friendListNotReceived:
            "Dostların siyahısını almaqda xəta baş verdi, daha sonra yenidən cəhd edin.",
        badName:
            "Seçilmiş ad uyğun gəlmədi, başqa adlara baxın və ya sonra yenidən cəhd edin.",
        nameLimit: "Ad dəyişikliyi yalnız hər 24 saatdan bir mümkündür.",
        friendsCount:
            "Dostların sayını almaqda xəta baş verdi. Sorğunu daha sonra təkrarlayın.",
        friendRequestsCount:
            "Dostluq təkliflərinin sayını almaqda xəta baş verdi. Sorğunu daha sonra təkrarlayın.",
        compareNotAllowed:
            "Özünüzü digər şəxslərlə müqayisə etmək üçün, öz şəkillərinizi yükləməli olursunuz",
        photoUploadPersonIsNotFound: "Şəkil qoyduğunuz səhifə tapılmadı.",
        photoUploadPersonIsNotYours:
            "Şəkil yükləmək istədiyiniz səhifə sizə məxsus deyil.",
        yourFaceIsNotFound:
            "Yeni şəkildə üzünüz tapılmadı, zəhmət olmasa üzünüz olan bir şəkil yükləyin.",
        partnerStatusNotReceived: "Partnyor statusunu almak mümkün deyil.",
        uuidError:
            "İstifadəçi ID'si səhv keçirilib, administratora müraciət edin.",
        alreadyPartner: "İstifadəçi artıq sizin partnyorunuzdur.",
        waitingResponse:
            "Sorğu əvvəlcədən göndərilib, partnyorun qərarını gözləyin.",
        partnerRequestDeclined:
            "Sizin sorğunuz rədd edildi, sorğunu son sorğudan 24 saat sonra yenidən göndərə bilərsiniz.",
        partnerResponseAlreadySend:
            "Sorğu tapılmadı. Ehtimal ki, siz onu artıq qəbul etmisiniz.",
        partnerResponseNotSend:
            "Səhv məlumatlar göndərilib. Texniki dəstəyə müraciət edin",
        createDescriptionError:
            "Şəxsiyyət təsvirinizi yaratmaq mümkün olmadı. Texniki dəstəyə müraciət edin",
        firstUploadAnfas: "Əvvəlcə üzünüzün ön tərəfindən foto yükləməlisiniz",
        descriptionNotReady:
            "Merlin üzünüzün ön tərəfdən alınan şəkildən üz cizgilərini oxumadan profilə foto yükləmək olmaz. Bir dəqiqə sonra yenidən cəhd edin.",
        REACH_THE_GOAL_MESSAGE_DAILY_LIMIT_REACHED:
            "Məqsədi bağlamağı təklif etmək gündə bir dəfədən çox olmamalıdır",
        BUSINESS_PROPOSAL_MESSAGE_DAILY_LIMIT_REACHED:
            "İş təkliflərini gündə bir dəfədən çox göndərmək olmaz",
        AVATAR_LIMIT_REACHED:
            "Gündəlik foto yükləmə limitinə çatmışınız. Cəhdi sabah təkrar edin.",
    },
    "cookies": {
        "prefix-text": "Biz toplayırıq ",
        "text": "Cookies",
        "btn-text": "OK",
    },
    "DescriptionPopup": {
        "learn-about": "Siz şəxs haqqında oxuyursunuz:",
        "light-background": "Yüngül fon",
        "dark-background": "Tünd fon",
        "start-learn-profession": "Bugündən doğru peşəni öyrənməyə başlayın:",
        "start-learn-profession-btn-text": "Başlamaq",
    },
    MerlinDating: {
        "title":
            "{name} şəxsiyyəti ilə tanış olmaq üçün addım-addım alqoritm istərdinizmi?",
        "subtitle": "Bizim app yükləyin.",
    },
    "FooterLinks": {
        about: "Layihə haqqında",
        contacts: "Əlaqə",
    },
    "FriendsFilter": {
        "any": "Hər hansı",
        "all": "Hamısı",
        "by-age-title": "Yaşa görə:",
        "by-person-type-title": "Şəxsiyyət tipi:",
        "by-person-category-title": "Şəxsiyyət kateqoriyası:",
        "sort-by-title": "Sırala:",
        "sort-by-default": "Əlavə edilmə tarixi",
        "sort-by-gender-title": "Cinsiyyətə görə:",
        "reset-text": "Filtrin resetlənməsi",
        "gender": {
            all: "Hamısı",
            male: "Kişilər",
            female: "Qadınlar",
        },
        age: {
            any: "Hər hansı",
            interval_1: "18 ilə 24 yaş arası",
            interval_2: "25 ilə 34 yaş arası",
            interval_3: "35 ilə 44 yaş arası",
            interval_4: "45 və daha çox",
        },
        typeList: [
            {
                id: "ALL",
                name: "Hər hansısa",
            },
            {
                id: "ADMINISTRATOR",
                name: "Administrator",
            },
            {
                id: "ACTIVIST",
                name: "Aktivist",
            },
            {
                id: "ALGORITHMIST",
                name: "Alqoritmçi",
            },
            {
                id: "ARTIST",
                name: "Rəssam",
            },
            {
                id: "MASTERMIND",
                name: "Asudəci",
            },
            {
                id: "HEDONIST",
                name: "Hedonist",
            },
            {
                id: "GENERATOR",
                name: "Generator",
            },
            {
                id: "DIPLOMAT_INVADER",
                name: "Diplomat İşğalçı",
            },
            {
                id: "IDEOLOGIST",
                name: "Ideoloq",
            },
            {
                id: "IDEOLOGICAL_MASTERMIND",
                name: "Ideoloji Asudəci",
            },
            {
                id: "IDEOLOGICAL_LEADER",
                name: "Fikir Lideri",
            },
            {
                id: "IMAGE_MAKER",
                name: "Şəkil Yaradıcı",
            },
            {
                id: "ENGINEER",
                name: "Mühəndis",
            },
            {
                id: "INITIATOR",
                name: "İnxişaf Edən",
            },
            {
                id: "INSPECTOR_PERFECTIONIST",
                name: "Mükəmməlliyat Inspektoru",
            },
            {
                id: "INTEGRATOR",
                name: "Inteqrator",
            },
            {
                id: "RESEARCHER",
                name: "Araşdırmacı",
            },
            {
                id: "RESEARCHER_ANALYST",
                name: "Araşdırma Analitiki",
            },
            {
                id: "COMMUNICATOR",
                name: "Kommunikativ",
            },
            {
                id: "CONNECTOR",
                name: "Bəğlənti Yaradan",
            },
            {
                id: "CONTROLLER",
                name: "Kontroller",
            },
            {
                id: "CRITIC",
                name: "Tənqidçi",
            },
            {
                id: "LEGIONARY",
                name: "Leqioner",
            },
            {
                id: "LEADER",
                name: "Lider",
            },
            {
                id: "LOBBYIST",
                name: "Lobbiçi",
            },
            {
                id: "MASS_MEDIA_ENTERTAINER",
                name: "Kilovatlı Enterteyner",
            },
            {
                id: "MASTER_IMPLEMENTOR",
                name: "Uzman Tətbiq Edən",
            },
            {
                id: "MATERIALIST",
                name: "Materialist",
            },
            {
                id: "MAECENAS",
                name: "Məcənas",
            },
            {
                id: "DREAMER",
                name: "Xəyalperest",
            },
            {
                id: "MORALIST",
                name: "Moralist",
            },
            {
                id: "MENTOR",
                name: "Mentor",
            },
            {
                id: "ASSERTIVE_INVADER",
                name: "Müdaxilə Edən Zorak",
            },
            {
                id: "INFORMAL_LEADER_MANIPULATOR",
                name: "Manipulator Neformal Lider",
            },
            {
                id: "INNOVATOR",
                name: "İnovator",
            },
            {
                id: "INNOVATOR_ENTHUSIAST",
                name: "İnovator İstehlakçı",
            },
            {
                id: "OPTIMIZER",
                name: "Optimizator",
            },
            {
                id: "OPTIMIST",
                name: "Optimist",
            },
            {
                id: "ORGANIZER",
                name: "Təşkilatçı",
            },
            {
                id: "EDUCATOR",
                name: "Müəllim",
            },
            {
                id: "PERFECTIONIST",
                name: "Mükəmməlliyat",
            },
            {
                id: "POLITICIAN",
                name: "Politikan",
            },
            {
                id: "MEDIATOR_DIPLOMAT",
                name: "Diplomat Münaqişəçi",
            },
            {
                id: "PRACTITIONER",
                name: "Təcrübəçi",
            },
            {
                id: "PRACTITIONER_CONSERVATIVE",
                name: "Konservativ Təcrübəçi",
            },
            {
                id: "PRACTITIONER_MANAGER",
                name: "İdarə Edən Təcrübəçi",
            },
            {
                id: "ENTREPRENEUR_INNOVATOR",
                name: "İnovativ Baş Girişimci",
            },
            {
                id: "ADHERENT",
                name: "İştirakçı",
            },
            {
                id: "FORECASTER",
                name: "Proqnozlaşdıran",
            },
            {
                id: "PREACHER_MENTOR",
                name: "Vaiz Mentor",
            },
            {
                id: "RATIONALIZATOR",
                name: "Innovator",
            },
            {
                id: "IMPLEMENTATOR",
                name: "Reallaşdıran",
            },
            {
                id: "ROMANTIC",
                name: "Romantik",
            },
            {
                id: "EMINENCE_GRISE",
                name: "Qırmızı Kardinal",
            },
            {
                id: "SYSTEMATIZER",
                name: "Sistematizator",
            },
            {
                id: "SKEPTIC",
                name: "Ekimist",
            },
            {
                id: "SOCIALIZER",
                name: "Cəmiyyətləşdirən",
            },
            {
                id: "STABILIZER",
                name: "Stabilizator",
            },
            {
                id: "TECHIE",
                name: "Texnik Adam",
            },
            {
                id: "HANDYMAN",
                name: "Əlşər",
            },
            {
                id: "MANAGER",
                name: "Murahkib",
            },
            {
                id: "KEEPER",
                name: "Mühafizə edən",
            },
            {
                id: "EXPERIMENTER",
                name: "Əlçatan",
            },
            {
                id: "ENTHUSIAST",
                name: "Heyecanlandıran",
            },
        ],
        searchSortList: [
            {
                id: "DATE",
                name: "Əlavə edilən tarix",
            },
            {
                id: "NAME",
                name: "Ad",
            },
        ],
        searchList: [
            {
                id: "DATE",
                name: "Əlavə edilən tarix",
            },
            {
                id: "NAME",
                name: "Ad",
            },
            {
                id: "COMPATIBILITY",
                name: "Uyğunluq",
            },
            {
                id: "SIMILARITY",
                name: "Oxşarlıqlar",
            },
            {
                id: "BUSINESS",
                name: "Biznes Uyğunluğu",
            },
            {
                id: "INTERACTION_BUSINESS",
                name: "Biznes Əlaqəsi",
            },
            {
                id: "INTERACTION_INTERPERSONAL",
                name: "Şəxsi Əlaqə",
            },
        ],
    },
    "InviteFriends": {
        "btn-text": "Dost əlavə et",
    },
    "LeftAside": {
        "user-not-uploaded-photo": "Bu istifadəçi fotoşəkil yükləməmişdir!",
        "feedback": "Dəstəklə əlaqə",
        "referral-program": "Referral Programı",
        "bonus-program": "Bonus Programı",
        "legal-info-title": "Qanuni Məlumat",
        "contacts-and-requisites": "Kontaktlar və tələblər",
        "about": "Şirkət haqqında",
        "service-pay": "Xidmətin ödənişi",
        "term-of-use": "İstifadəçi Sahibkarlığı",
        "privacy-policy": "Məxfiliyin Siyasəti",
    },
    "RightAsideSocial": {
        "title": "Paylaş",
        "vk": "VK.com",
        "ok": "ok.ru",
    },
    "loading": {
        "network-error":
            "İnternet bağlantısını yoxlayın <br/> və səhifəni yenidən yükləyin",
    },
    "MainHeader": {
        "back-to-profile": "Profila qayıt",
        "createAccount": "Hesab yarat",
        "support": "Müştəri Xidməti",
        "logout": "Hesabdan çıx",
    },
    "MobileMenu": {
        support: "DƏSTƏK",
    },
    "NotificationsModule": {
        "all": "Bütün xəbərdarlıqlar",
        "partner": "Sizinə tərəfdaş olmağı təklif etdilər",
        "deposit": "Depozit",
        "bonus": "Siz bonus qazandınız",
        "reward": "Referanslıq proqramı çərçivəsindəki mükafat",
        "referral": "{name} sizin referansınız oldu<br/>Merlin Dating-də",
        "free-period":
            "Sizin referansınız {name} Merlin Dating-də sınaq abunə olmuşdur {message}",
        "cancel-subscribe":
            "Sizin referansınız {name} Merlin Dating-dən abunəliyi ləğv etdi {message}",
        "pay-subscribe":
            "Siz mükafat qazandınız. Sizin referansınız {name} Merlin Dating-də ödənişli abunə olmuşdur {message}",
        "subscribe-error":
            "{0} Merlin Dating-də abunəliyimi yeniləyə bilmədiyim bir səbəb",
        "mapp_celebrity_compare": "Merlin Dating uyğunluq alış mükafatı",
        "bonus_coach_partner": "Tərəfdaşınızın hərəkəti üçün bonus",
        "person_compare": "Biri sizinlə yoxladı",
        "compatibility": "uyğunluq",
        "similarity": "oxşarlıq",
        "business": "biznes tərəfdaşlığı üçün uyğunluq",
        "person_indicator": "Biri tanıdı",
        "interaction_business": "biznes əlaqəsi növü",
        "interaction_interpersonal": "şəxsi əlaqələr növü",
        "mma_champion": "çempion potensialı",
        "mma_potential": "döyüş sənətləri potensiali",
        "aggression_common": "sizin ümumi aqressiyanız",
        "aggression_emotional": "sizin emosional aqressiyanız",
        "aggression_social": "sizin sosial aqressiyanız",
        "aggression_intellectual": "sizin intellektual aqressiyanız",
        "aggression_physical": "sizin fiziki aqressiyanız",
        "analyse_item_unlock":
            "Biri sizin kateqoriya içərisindəki açıqlamanızı oxudu",
        "professionaltype": "Profotip",
        "neurotype": "neyrotip",
        "leadertype": "liderlik",
        "character": "karakter",
        "relations": "münasibətlər və ailə",
        "motivation": "motivasiya və enerji",
        "selfAssessmen": "öz-qiymətləndirmə və özünüzə inamınız",
        "professional": "professiya və öz-inkişaf",
        "person_review_created": "Şəxsiyyət üzrə rəy hazırdı",
        "new_in_top": "Uyğunluq topunuz yeniləndi: ",
        "phone_confirm": "Siz bonus aldınız {count} $",
        "upload_photos_request": "Biri sizə şəkil yükləməyi təklif edir",
        "upload_photos_completed": "{user} özünün şəkilini yüklədi",
        "coach_partner_request": "{name} sizə tərəfdaş olmağı təklif etdi",
        "coach_partner_request_accept": "Qəbul et",
        "coach_partner_request_decline": "Rədd et",
        "analyse_item_unlock_reward": "Siz bonus əldə etdiniz {bonus} $",
        "new_in_top_show": "Baxmaq üçün vurun",
        "phone_confirm_reward": "Telefon nömrəsini təsdiqləmək üçün",
        "loading": "Yüklənir...",
        "no-more": "Daha çox xəbərdarlıq tapılmadı",
        "no-notifications": "Sizdə yeni xəbərdarlıq yoxdur.",
        "show-all": "Hamısını göstər",
    },
    "OfflineIndicator": {
        title: "İnternet bağlantısı yoxdur.",
        retry: "Zəhmət olmasa daha sonra yenidən cəhd edin...",
    },
    "OnboardingDescriptionPopup": {
        text: "Bu, nə üçün bunu etməkdə maraqlı olduğunuzu və nə üçün kömək edəcək keyfiyyətləriniz olduğunu açıqlayır.",
        error: "Siz bütün peşələr üçün səs verməmisiniz:",
    },
    "Search": {
        "findFriends": "Dostlar tap",
        "found": "İstifadəçilər tapıldı:",
        "limit": "Ən azı 3 simvol daxil edin",
        "not-found": "Heç nə tapılmadı",
        "show-all": "HAMISINI GÖSTƏR",
    },
    "SearchPeople": {
        "full-name": "Adınızı və ya soyadınızı daxil edin",
        "all": "Hamısı",
        "friends": "Dostlar",
        "celebrity": "Məşhurlar",
        "fans": "Azərbaycanlılar",
        "type-is-not-available": "Şəxsiyyət növü hələlik aşkarlanmayıb",
        "limit": "Ən azı 3 simvol daxil edin",
        "empty-filter": "Verilən parametrlərə əsasən heç kim tapılmadı",
    },
    "Select": {
        nothing: "Sorğuya uyğun heç bir nəticə yoxdur",
        choose: "Seçin",
    },
    "ServiceMenu": {
        "with-friends": "Dostlarınızla müqayisə edin",
        "about-me": "Özünüz haqqında daha çox öyrənin",
        "about-another": "Daha çox öyrənin:",
        "aggression": "Agressiya səviyyəsinə baxın<br/>",
        "aggression-another": "Agressiya səviyyəsi:",
        "person-aggression": "Agressiya səviyyəsi:",
    },
    "UserNav": {
        "my-page": "Mənim səhifəm",
        "friends": "Dostlar",
        "compare-with-me": "Mənimlə müqayisə edin",
        "celebrities": "Məşhurlar",
        "messages": "Mesajlar",
        "analyze": "Merlin AI-analiz",
        "recommendations": "İnsanlardan tövsiyələr",
        "settings": "Tənzimləmələr",
        "manager": "Şəxsiyyətlər idarəetmə",
    },
    "Auth": {
        "back": "Geri",
        "another-phone": "Başqa bir telefon nömrəsi daxil edin",
        "prev-step": "Geri qayıdın",
        "auth": "Təsdiq",
        "confirm-phone":
            "Sizin əsl insan olduğunuzu yoxlamaq istəyirik. Zəhmət olmasa telefon nömrənizi daxil edin:",
        "retry-after":
            "Siz tez-tez zəng istəyirsiniz, yeni birini tələb edə bilərsiniz {{smsTimerCountdown}} saniyədən sonra.",
        "not-found": "Heç nə tapılmadı",
        "country-code": "Ölkə kodu",
        "phone": "Telefon nömrəsi",
        "agree_1":
            "Telefon nömrənizi daxil edərkən siz, tanış oldunuzu təsdiqləyirsiniz ",
        "agree_2": "istifadə şərtləri ilə",
        "agree_3": "məxfilik siyasəti ilə",
        "telegram":
            "Qeydiyyatınızı tamamlamaq üçün, zəhmət olmasa, sizin əlaqəniz paylaşın - bu, Telegrama daxil olmağı itirərsinizsə istifadə olunacaq.",
        "call": "Gələn zəng",
        "message": "Gələn mesaj",
        "blocked":
            "Nömrəniz son zamanlarda bloklandı, yenidən başlaya bilərsiniz {{smsTimerCountdown}} saniyədən sonra.",
        "retry-error":
            "Nə baş verirsə olsun,size çatıla bilmir və ya mətn mesajı alınmırsa, {{smsTimerCountdown}} saniyədən sonra kodu yenidən tələb edə bilərsiniz.",
        "telegramTitle": "Botdan tələb alındı.Avtorizasiya davam edir.",
        "telegramTitle2":
            "Merlin sizə bir dəqiqədən az bir zamanda bir telegram mesajı göndərəcəkdir.",
        "enter-call-code":
            "Aşağıda gələn nömrənin son 4&#nbsp;raqamını daxil edin.",
        "enter-message-code": "Mesajdan kodu daxil edin",
        "code": "təsdiq kodu:",
        "confirm": "təsdiqlə",
        "request": "tələb edir...",
        "enter": "daxil et",
        "loading": "yüklənir...",
        "requestError":
            "Zəng tələbinin göndərməsində səhv, bir neçə saniyədən sonra yenidən cəhd edin.",
        "phoneError":
            "Düzgün telefon nömrəsini beynəlxalq formatda daxil edin. Rusiya üçün nümunə: +79174246633",
        "codeExpired":
            "Siz son dörd rəqəmi 60 saniyəliyə daxil etməyə vaxt tapmadınız. Zəhmət olmasa təsdiq prosedurunu yenidən başladın.",
        "codeError":
            "Düzgün olmayan rəqəmlər, heç bir cəhd qalmıyor: {attempts} 3-dən",
    },
    "PhoneForm": {
        "back": "Geri",
        "another-phone": "Başqa bir telefon nömrəsi daxil edin",
        "prev-step": "Geri qayıdın",
        "auth": "Səlahiyyət",
        "confirm-phone":
            "Sizin həqiqi bir insan olduğunuzu təsdiqləməliyik. Xahiş edirik telefon nömrənizi daxil edin:",
        "retry-after":
            "Siz tezliklə bir zəng tələb etmisiniz, beləliklə, yenisini tələb edə bilərsiniz {{smsTimerCountdown}} saniyədən sonra.",
        "not-found": "Heç nə tapılmadı",
        "country-code": "Ölkə kodu",
        "phone": "Telefon nömrəsi",
        "agree_1":
            "Telefon nömrənizi daxil edərək, siz təsdiqləyirsiniz ki, bizim ilə tanışsınız ",
        "agree_and": "Və",
        "agree_2": "istifadə qaydaları",
        "agree_3": "gizlilik siyasəti",
        "telegram":
            "Qeydiyyatınızı tamamlamaq üçün, zəhmət olmasa, əlaqənizi paylaşın - əgər Telegram-a girişiniz itirilərsə, bu istifadə olunacaq.",
        "call": "Gələn zəng",
        "message": "Gələn mesaj",
        "blocked":
            "Sizin nömrəniz tezliklə bloklanmışdı, {{smsTimerCountdown}} saniyədən sonra yenidən başlaya bilərsiniz.",
        "retry-error":
            "Əgər sizə bir səbəbdən ötürü əlaqə edilə bilmirsə və ya sizin mətn mesajınız alınmır, siz kodu yenidən tələb edə bilərsiniz {{smsTimerCountdown}} saniyədən sonra.",
        "telegramTitle": "Botdan tələb alınmışdır. Səlahiyyət təmin olunur.",
        "telegramTitle2":
            "Merlin sizi bir neçə dəqiqəyə Telegram mesajı göndərəcək.",
        "enter-call-code":
            "Aşağıdakı gələn nömrənin son 4&nbsp;rəqəmini daxil edin.",
        "enter-message-code": "Mətn mesajından kodu daxil edin",
        "code": "təsdiq kodu:",
        "confirm": "təsdiqlə",
        "request": "tələbət...",
        "enter": "daxil edin",
        "loading": "yüklənir...",
        "requestError":
            "Zəng tələbinin göndərilməsində səhv, bir neçə saniyədən sonra yenidən cəhd edin.",
        "phoneError":
            "Düzgün telefon nömrəsini beynəlxalq formatda daxil edin. Rusiya üçün nümunə: +79174246633",
        "codeExpired":
            "Siz 60 saniyə ərzində son dörd rəqəmi daxil etmək üçün vaxtınız olmayıb. Xahiş edirik təsdiq prosedurunu yenidən başlayın.",
        "codeError": "Yanlış rəqəmler, cəhdlər qalmayıb: {attempts} dan 3",
    },
    "choose-want": {
        "choose-1-1": "Pul",
        "choose-1-2": "peşə",
        "choose-1-3": "özünü-həyata keçirmək",
        "choose-2-1": "Enerji",
        "choose-2-2": "motivasiya",
        "choose-2-3": "anlam",
        "choose-3-1": "Münasibətlər",
        "choose-3-2": "ailə",
        "choose-3-3": "kolleqalar",
        "choose-4-1": "Əminlik",
        "choose-4-2": "özünü-qiymətləndirme",
        "choose-4-3": "sükunət",
    },
    "CelebrityProgram": {
        "compare":
            "Özünüzün məşhurlarla uyğunluğunu yoxlayın və əgər siz listin üstündə olarsanız, onlarla şəxsi görüşə bilərsiniz",
        "desc": "Bu proqram adamların və məşhurların aşağıdakılara imkan verir:",
        "get-know": "Tanış olun",
        "collaborate": "Əməkdaşlıq edin",
        "talk": "Danışın",
        "date": "Randevuya çıxın",
        "all": "Proqramdaki bütün məşhurlar",
    },
    "MessagesHistory": {
        "accessError": "Bu istifadəçiyə mesaj yaza bilməzsiniz. Bunun səhv olduğunu düşünürsənsə, dəstəklə əlaqə saxla.",
        "no-messages": "Mesaj yoxdur",
        
    },
    "MessageHistoryHead": {
        "new-chat": "Yeni chat",
        "history": "Sohbetler siyahısı",
        "delete-chat": "Söhbəti sil",
        "disable-notifications": "Bildirişləri deaktiv et",
        "enable-notifications": "Bildirişləri aktiv et",        
        "attempts-left": "Qalan {0} sorğu"
    },
    "ChatMessage": {
        "myMessageTitle": "Sən",
        "upscale": "Upscale {0}",
        "seconds": " sec.",
        "generated-by-stable-diffusion": "Image generated via Stable Diffusion",
        "generated-by-midjorney": "Image generated by Midjorney",
        "generated-by-midjorney-error":
            "The image could not be created. Don't worry, we are already aware of the problem and will solve it soon. Try again a little later",
        "midjorney-parameters-error": "Unknown parameters:",
        "midjourney-queue":
            "Stand by, you're {0} in the queue to create a picture",
        "midjorney-stress-error":
            "Server load is too high. Try again in 1 minute",
        "midjorney-moderator-error":
            "I'm sorry, but your picture request violates the rules. I do not create pictures that:\n" +
            "- May offend or mislead someone.\n" +
            "- Contain offensive words or violence.\n" +
            "- Depict someone without clothes or in inappropriate situations.\n" +
            "- May be considered disrespectful in different cultures.",
        "midjorney-single-image-error":
            "Generation from the transmitted image only is not possible, add a text query and try again",
        "midjorney-unknown-error":
            "Oops, I think something went wrong with your image request. \n" +
            "\n" +
            "Examples of private errors:\n" +
            "1️⃣ Using --version 5.2 parameter only with --style: raw. \n" +
            "2️⃣ Correctness of entered parameters. Such parameters as -ar, 21:9, -style, raw, -q2, -seed, 302280510.\n" +
            "3️⃣ Correctness of the specified aspect ratio. Must be W:W, such as 16:9, not 'da'.\n" +
            "\n" +
            "These are just examples of what might be wrong. Please check your query and try again.",
        "optimization-midjorney": "Producing image optimization",
        "gpt-error-try-again": "Something went wrong. Try again.",
        "generated-by-pika": "The video was generated through Pika",
        "pika-generating": "Generating video...",
        "pika-queue": "Stand by, you're {0} in the queue to generate the video",
        "pika-unknown-error":
            "Oops, I think something went wrong with your image request. Please try again.",
        "download": "Download",
    },
    "AddMessageForm": {
        "inputPlaceholder": "Mesaj daxil edin...",
    },
    "Chat": {
        "loading": "Dialoq yüklənir...",
        "write": "yazır...",
        "online": "Onlayn",
        "offline": "Onlayndan çıxıb",
        "partner": "Siz iş təklifi etsəniz",
        "partner-for-you": "Sizə iş təklifi edilmişdir",
        "target": "Siz hədəfi bağlamağınızı təklif etsəniz",
        "target-for-you": "Sizdən hədəfinizi bağlamaq təklif edilir",
        "AIPlaceholder": "Şəkli yaratmaq üçün sətiri daxil edin",
        "edit": "Tərtib et",
        "copy": "Köçür",
        "delete": "Sil",
        "read": "Oxunmuş",
        "notRead": "Oxunmamış",
        "empty-error": "Mesaj boş olmamalıdır",
        "length-error-min": "Mesaj {min} simvoldan az olmamalıdır",
        "length-error-max": "Mesajın uzunluğu {max} simvoldan çox olmamalıdır",
        "target-message": "Hədəfinizi bağlamağı təklif edirsiniz",
        "partner-message": "Siz iş təklif edirsiniz",
        "stable-diffusion-message":
            "Şəkli Stable Diffusion vasitəsilə yaratma rejimi",
        "midjorney-message": "Şəkli Midjorney vasitəsilə yaratma rejimi",
        "editing": "Tərtib edilir",
        "confirmTitle": "əməliyyatı təsdiq edin",
        "confirmDeleteMessageText":
            "Siz həqiqətən mesajı silmək istəyirsinizmi?",
        "confirmDeleteAllMessagesText":
            "Siz həqiqətən bütün müsahibəni silmək istəyirsinizmi?",
        "manyMessage5seconds":
            "Çox qısa müddətdə çoxlu mesajlar, 5 saniyə gözləyin.",
        "manyMessage60seconds":
            "Dəqiqədə 30 mesajdan çox olmamalıdır, lütfən təxminən 60 saniyə gözləyin.",
        "userDisable":
            "İstifadəçi mesaj ala biləcəyiniz imkanı məhdudlaşdırıb.",
        "sendError": "Mesaj göndərmədə xəta, yenidən cəhd edin.",
        "upscale": "Yuxarı çıx",
        "generated-by-stable-diffusion":
            "Şəkil Stable Diffusion vasitəsilə yaradılıb",
        "generated-by-midjorney": "Şəkil Midjorney ile yaradılıb",
        "optimization-midjorney": "Şəklin optimallasdırılması",
    },
    "Dialogs": {
        "search": "Dostlarınızı axtarın",
        "open": "Chatı aç",
        "nothing": "Sizdə aktiv söhbətlər yoxdur",
        "image": "Şəkil",
    },
    "Messages": {
        "loading": "Söhbət yüklənir...",
        "chooseAI": "İstifadə etmək istədiyiniz sinir şəbəkəsini seçin",
        "chooseChat": "Mesajlara baxmaq üçün chat seçin",
    },
    ChatMenu: {
        title: "Menyu",
        stableDiffusionTitle: "Sabitledirilmiş Difüziya",
        stableDiffusionDescription:
            "Hər hansı bir mətnə əsaslanan foto-realistik şəkillər yarat",
        midjorneyTitle: "Məktəbəyarı",
        midjorneyDescription:
            "Hər hansı bir mətnə əsaslanan foto-realistik şəkillər yarat",
        enable: "Aktiv et",
        disable: "Deaktiv et",
    },
    PayoutModal: {
        balance: "Sizin balansınız:",
        title: "Vəsait çıxart:",
        sum: "Çıxartma miqdarı:",
        sumRequiredError: "Çıxartma miqdarını göstərin",
        sumMinError:
            "Bağışlayın, biz müraciəti qəbul edə bilmirik. Çıxartma üçün minimum məbləğ {min} rubldir. Fəal olaraq davam edin və tezliklə qazandığınız pulu çıxara bilərsiniz.",
        sumMaxError: "Maksimal çıxartma məbləği {max}",
        success:
            "Vəsait çıxartma müraciəti qəbul edildi! Menecer təzliklə sizinlə əlaqə saxlayacaq",
        newFormat:
            "Biz abunə formatına keçdik, siz hazırkı balansınızı dostlarınızla və ya məşhurlarla müqayisə etmək üçün xərcləyə bilərsiniz. Və bütün çiplərdən istifadə etmək üçün bir və ya bir neçə bölməye abunə olun",
        deposits: "Depozitlər",
        payments: "Ödənişlər",
        bonuses: "Bonuslar",
        table: {
            "date": "Tarix",
            "sum": "Məbləğ",
            "description": "Təsvir",
            "remainder": "Qalıq",
            loading: "Yüklənir...",
            noMore: "Daha çox giriş yoxdir",
            no: "Əməliyyatlar tapılmadı",
        },
    },
    purchaseDescriptions: {
        category: {
            deposit: "Hesabın doldurulması",
            analyse_item_unlock: "Kateqoriyada satın alınan təsvirin açılması",
            bonus_analyse_item_unlock:
                "Birisi kateqoriyanızdaki təsvir maddənizi açdı",
            referral_reward: "Referal proqramından mükafat",
            merlinapp_referral_created: "Mobil tətbiqdə referal üçün mükafat",
            person_compare: "Siz yoxladınızdır",
            bonus_person_compare: "Birisi sizi yoxladı",
            person_compare_photos: "Şəkillərə əsasən təhlil et",
            person_indicator: "Göstəricinin açılması:",
            bonus_person_indicator: "Birisi sizin göstəricinizi açdı:",
            phone_confirm: "Telefon Təsdiqləmə Bonusu",
            bonus_coach_partner: "Məşqçi Tərəfdaşlıq Proqramı Bonusu",
            payout: "Çıxartma",
            refund: "Pul qaytarılması",
        },
        subcategory: {
            professionaltype: "peşəkar növ",
            neurotype: "neurotip",
            leadertype: "liderlik",
            character: "xarakter",
            relations: "münasibətlər və ailə",
            motivation: "motivasiya və enerji",
            selfAssessmen: "öz-qiymətləndirme və özünə-əminlik",
            professional: "peşə və öz-realizasiya",
            compatibility: "uyğunluq",
            similarity: "oxşarlıq",
            business: "biznes tərəfdaşlığı üçün uyğunluq",
            interaction_business: "biznes əlaqə növü",
            interaction_interpersonal: "interpersonal əlaqə növü",
            mma_champion: "çempion potensialı",
            mma_potential: "döyüş idmanları potensiali",
            aggression_common: "umumi agressiya",
            aggression_physical: "fiziki agressiya",
            aggression_emotional: "emocional agressiya",
            aggression_social: "sosial agressiya",
            aggression_intellectual: "intellektual agressiya",
        },
    },
    serviceTypes: {
        COMPATIBILITY: "Əlaqə uyğunluğu",
        COMPATIBILITY_PLAN: "Münasibətlər və ailə",
        SIMILARITY: "Xarakterlərin oxşarlığı",
        SIMILARITY_PLAN: "Özünə inam və özünə inam",
        BUSINESS: "İş uyğunluğu",
        BUSINESS_PLAN: "Peşə və məqsəd",
        INTERACTION_BUSINESS: "İş münasibətləri",
        INTERACTION_BUSINESS_MY: "Mən necə iş aparıram",
        INTERACTION_BUSINESS_OTHER: "O, biznesdə necə davranır",
        INTERACTION_BUSINESS_PLAN: "Peşə və məqsəd",
        INTERACTION_INTERPERSONAL: "İnsanlarla münasibətlər",
        INTERACTION_INTERPERSONAL_MY: "Mən insanlarla necə davranıram",
        INTERACTION_INTERPERSONAL_OTHER: "O, insanlarla necə davranır",
        INTERACTION_INTERPERSONAL_PLAN: "Münasibətlər və ailə",
        AGGRESSION_COMMON: "Ümumi Agressiya",
        AGGRESSION_COMMON_PLAN: "Münasibətlər və ailə",
        AGGRESSION_PHYSICAL: "Fiziki Agressiya",
        AGGRESSION_PHYSICAL_PLAN: "Peşə və məqsəd",
        AGGRESSION_EMOTIONAL: "Emosional Agressiya",
        AGGRESSION_EMOTIONAL_PLAN: "Peşə və məqsəd",
        AGGRESSION_SOCIAL: "Sosial Agressiya",
        AGGRESSION_SOCIAL_PLAN: "Peşə və məqsəd",
        AGGRESSION_INTELLECTUAL: "Intellectual Agressiya",
        AGGRESSION_INTELLECTUAL_PLAN: "Münasibətlər və ailə",
        INTELLIGENCE_LEVEL: "İntellektual səviyyə",
    },
    buyActions: {
        "compareUnlimited": "Müqayisələrin sayı məhdud deyil",
        "compareLimit": "Qalan müqayisələr: {limit}",
        "indicatorUnlimited": "Yoxlamaların sayı məhdud deyil",
        "indicatorLimit": "Qalan sınaq sayı: {limit}",
        compareCelebrity: "məşhurlar",
        compareUser: "istifadəçilər",
        indicatorCelebrity: "məşhurlar",
        indicatorUser: "istifadəçi",
        compareConfirmTitle: "Müqayisəni təsdiqlə",
        compareConfirmText:
            '{userType} {name} ilə "{activeType}" kateqoriyasında özünüzü müqayisə etmək istədiyinizə əminsinizmi?',
        compareConfirmBtn: "Satın almanı təsdiqlə",
        indicatorConfirmTitle: "Satın almanı təsdiqlə",
        indicatorConfirmText:
            '{userType} {name} nə bağlı "{activeType}" kateqoriyasını açmaq istədiyinizə əminsinizmi?',
        indicatorConfirmBtn: "Satın almanı təsdiqlə",
    },
    "compare-result-dialog": {
        more: "Şəxs haqqında daha çox öyrən",
    },
    "ConfirmDialog": {
        price: "Qiymət:",
        timer: "Abunəlik çərçivəsində yoxlama qədər:",
        cancel: "Ləğv et",
        apply: "Təsdiqlə",
    },
    "LegalLinksMobile": {
        "referral": "Referal proqramı",
        "legal-title": "Hüquqi Məlumatlar",
        "requisites": "Əlaqə və detallar",
        "about": "Şirkət haqqında",
        "payments": "Xidmətlərin ödənişi",
        "term-of-use": "İstifadəçi Razılaşması",
        "privacy-policy": "Məxfilik Siyasəti",
        "payout": "Bonus Proqramı",
        "support": "Dəstəklə yazın",
        "logout": "Çıxış",
    },
    "AnotherPerson": {
        "rating": "Sizin qiymətləndirmələriniz",
        "person-rate": "Adamı qiymətləndirin",
        "compare-with-me": "Özünüz ilə müqayisə edin",
        "more": "Daha çox öyrənin",
        "aggression": "Agressiya səviyyəsi",
    },
    "AnotherPersonActions": {
        "sendMessage": "MESAJ GÖNDƏR",
        "sendTarget": "HEDEF BAĞLAMAQ ÜÇÜN TƏKLİF EDİN",
        "sendBusiness": "İŞ TƏKLİFİ",
        "targetConfirmTitle": "qeyd",
        "targetConfirmText":
            "Bu hərəkəti həyata keçirmək üçün ilk öncə onu dost əlavə etməlisiniz.",
        "businessConfirmText":
            'Şəxsə iş təklifi etmək üçün, sizin "Peşə və Məqsəd" kateqoriyasına abunə olmanız lazımdır',
        "add": "Dostlara əlavə et",
        "subscribe": "abunə ol",
    },
    "AnotherPersonInfo": {
        "getType": "Mənim psixo-tipimi müəyyən et",
        "typeHidden": "Şəxsiyyət tipi hələki aşkar edilməyib",
        "web": "Veb-sayt",
        "webUndefined": "Müəyyən edilməyib",
        "selfEsteem": "Özünüdəyərlilik səviyyəsini yüksəldirir",
        "selfEsteemUndefined": "Müəyyən edilməyib",
        "occupation": "Status",
        "occupationUndefined": "Müəyyən edilməyib",
        "personalInfo": "Şəxsi məlumatlar",
        "age_1": "il",
        "age_2": "il",
        "age_3": "illər",
        "height": "sm",
        "weight": "kq",
        "noChildren": "Uşaqlar yoxdur",
        "children_1": "uşaq",
        "children_2": "uşaq",
        "children_3": "uşaqlar",
    },
    "AnotherPersonBusinessCard": {
        "merlin": "Merlindan",
        "aboutMe": "{name} özü hakkında",
        "blocked": "Bloklandı",
        "unlock": "Aç",
        "more": "Daha çox oxu",
        "mrln_strengths": "Güc noktələri",
        "mrln_talents": "Bacarıq",
        "mrln_self_esteem": "Özünə inamının artmasına nə səbəb olur",
        "mrln_motivation": "Motivasiyanın artmasına nə səbəb olur",
        "mrln_tasks": "Hansı işləri uğurla həyata keçirir",
        "mrln_activities": "Fəaliyyət sahələri ki, ona həvəslidir",
        "self_esteem": "Özünə inamının artmasına nə səbəb olur",
        "user_like": "Nələrə xoşbəxt olur",
        "user_good": "Nələr yaxşıdır",
        "user_usually": "Adətən nə edir",
        "user_work": "Kimlə işləmək istəyir",
        "user_goal": "Qazanmaq istəyir",
        "user_skill": "Öyrənmək istəyir",
        "career": "TƏCRÜBƏ",
        "education": "TƏHSİL",
        self_esteemUndefined: "Heç nə",
        user_likeUndefined: "Heç nə",
        user_goodUndefined: "Heç nə",
        user_usuallyUndefined: "Heç nə",
        user_workUndefined: "Heç kim",
        user_goalUndefined: "Saata 5$",
        user_skillUndefined: "yatağın sol tərəfi",
        fromNow: "İndiyə qədər",
        categoryProfession: "Peşə və təyinat",
        categorySelfEsteem: "Özünə inam və özünə etimad",
        categoryMotivation: "Motivasiya və effektivlik",
        needSubscribe: "Abunəlik tələb olunur",
        needSubscribeText:
            '"{category}" kateqoriyasına abunəliyi etmək üçün bu maddəni açmaq lazımdır.',
        subscribe: "Abunə ol",
    },
    "AvailableSocial": {
        "notSelected": "HEÇ NƏ TƏYİN EDİLMƏYİB",
    },
    "AvatarGallery": {
        retry: "Yenidən cəhd et",
        new: "Yeni avatar yüklə",
    },
    "ChangeName": {
        "placeholder": "Adınızı və soyadınızı daxil edin",
        "error": "Ad və soyad 2 simvoldan çox olmalıdır",
        "saving": "Yaddaşa yazılır...",
        "save": "Yadda saxla",
    },
    "ChangePhotos": {
        "another": "Digərini yüklə",
        "anotherAnfasDrag": "Buraya başqa <br/> tam üz foto yüklə",
        "anotherProfileDrag": "Profilə başqa bir <br/> foto yüklə",
        "there": "buraya",
        "didntFit": "Foto uyğun deyil",
        "limit": "<br/> Bugünkü yükləmə limiti tükənmiş",
        "example": "Dəqiqləşdirmə fotoşəkli",
        anfas: {
            title: "Fotoşəkillərinizi yalnız neyron şəbəkə alqoritmi görəcəkdir. Fotoşəkillər üçüncü tərəflərin icazəsi olmadan əlyetimli deyil.",
            desktopText: "Burada tam üz foto yüklə.",
            mobileText: "Burada tam üz foto yüklə.",
            dragText: "Burada tam üz foto yüklə.",
        },
        profile: {
            desktopText: "Burada profil fotosunu yüklə",
            mobileText: "Burada profil fotosunu yüklə",
            dragText: "Burada profil fotosunu yüklə",
        },
        avatar: {
            title: "Avatarınızı yükləyin, hər kim səhifənizi ziyarət edirsə görə bilər.",
            text: "Avatarını yüklə",
            dragText: "Buraya avatarı sürüklə",
            help: "Mən sizə daha yaxşı görünən bir fotoşəkili yükləməyi məsləhət görürəm. Bu, digər istifadəçilərin gördüyü fotoşəkildir. Mən başqalarının fotosunu təşviq etmək üçün istifadə edə bilmərəm.",
        },
    },
    "CompareWithPhoto": {
        "back": "Şəxsiyyət təsviriylə geri dön",
        "another-type": "Başqa bir müqayisə növü seçin",
        "choose-in-list": "Dostlar siyahısından seçin",
        "searchFriends": "dostAxtarış",
        "noFriends":
            "hələ dostun yoxdur. Dostları dəvət edə bilər və onlar qoşulsa, bonus alarsınız",
        "invite":
            "Bağışlayın, bu şəxs hələ dostlarınız arasında deyil. Onu dəvət edə bilərsiniz və o qoşulsa bonus alacaqsınız",
        "loading": "Yüklənir...",
        "no": "Başqa kimse tapılmadı :)",
        "noFriendsShort": "Sizin dostunuz yoxdur",
        "upload-friend-photo": "dostunuzun fotosunu yükləyin",
    },
    "MobileAvatarGallery": {
        "photo-uploaded": "Şəkil yükləndi",
        "choose-main": "Əsas etmək üçün",
        "confirmDeleteTitle": "Silməyi təsdiqləyin",
        "confirmDeleteText": "Şəkli silmək istədiyinizə əminsinizmi?",
        "confirmDeleteBtn": "Sil",
    },
    "PersonCompareMobile": {
        compare: {
            myProfile: "Dostlarınızla müqayisə edin",
            withUser: "<span>{user}</span> ilə özünüzü müqayisə edin",
        },
        indicator: {
            myProfile: "Özünüz haqqında daha çox öyrənin",
            withUser: "Daha çox öyrənin:<br/> {user}",
        },
    },
    "PersonDescription": {
        "back": "Geri",
        "choose-type": "Sizin marağınızdakı istiqaməti seçin",
        "need-subscribe": "Abunə olmaq tələb olunur",
        "need-subscribe-text":
            "Seçilmiş bölmənin təsvirini oxumaq üçün kateqoriyaya abunə olmaq lazımdır",
        "btn": "Qeydiyyatdan keçin",
    },
    "PersonInfo": {
        "age": "Yaş",
        "age_1": "yaşında",
        "age_2": "yaşında",
        "age_3": "yaşında",
        "no": "Təyin olunmayıb",
        "children": "Uşaqlar",
        "children_1": "uşaq",
        "children_2": "uşaqlar",
        "children_3": "uşaqlar",
        "height": "Böyüklük",
        "weight": "Çəki",
        "subscriptions": "Abunəliklər",
        "photo-error":
            "Əgər sizdə öz şəkillərinizdən bir neçəsi varsa - vizit kartı daha maraqlı görünəcək",
    },
    "PersonMobileBox": {
        history: "Tarixçə",
    },
    "PersonMobileBusinessCard": {
        merlin: "Merlindən",
        user: "{user}dən",
        career: "Karyera",
        education: "Təhsil",
    },
    "PersonMobileBusinessCardPreview": {
        "title": "Mənim vizit kartımın başqa bir nəfər üçün necə görünəcəyi",
        "send": "MƏNİM SƏHİFƏMƏ KƏŞİDİ GÖNDƏR",
    },
    "PersonMobileHeader": {
        "noName": "Ad təyin olunmayıb",
        "changeName": "Adı təyin et",
        "my-type": "Mənim psixik tipimi müəyyənləşdir",
        "type-not-available": "Şəxsiyyət tipi hələ dəfinsizlaşmamışdır",
    },
    "PersonalRecommendations": {
        title: "Şəxsi tövsiyələr",
    },
    "PersonalRecommendationsDialog": {
        title: "Sizin üçün fərdiləşdirilmiş tövsiyələr",
        canOpen: "Siz aça bilərsiniz",
        number: "Tövsiyə No",
        openAfter: "Bunu aşağıdakı yolu ilə açasınız: ",
    },
    "PhotoExamples": {
        title: "Referans şəkillərinin nümunələri",
        text: "Şəkil standarta yaxın olacaqsa, neyro ağ sizin haqqınızda daha dəqiq danışacaqdır. <br/> Şəkillər yalnız sizə açıq olacaq.",
        anfas: "Tamüz görüntüdə şəkil",
        profile: "Profil şəkili",
        btn: "Əlavə et öz şəkillərini",
    },
    "AboutSettingsForm": {
        "user_like": "Nəyə xoş qəbul edirsən?",
        "user_good": "Nədə yaxşısan?",
        "user_usually": "Nə edirsən adətən?",
        "user_work": "Kim olaraq işləmək istəyirsən?",
        "user_goal": "Nə qədər qazanmaq istəyirsən?",
        "user_skill": "Neyi öyrənmək istəyirsən?",
        "saved": "Yaddaşa verildi",
    },

    "CareerSettingsForm": {
        "company-name": "Şirkətin adı",
        "search-title": "Bəlkə bu nə demək istəyirsiz:",
        "company-name-error": "Şirkətin adını daxil edin",
        "company-position": "Vəzifə",
        "company-position-error": "Vəzifəni daxil edin",
        "company-start": "İşə başlama",
        "company-start-error": "Başlama tarixini daxil edin",
        "company-end": "İşin sonu",
        "company-end-now":
            "Hələ də şirkətdə işləyirsinizsə, bu sahəni boş buraxın",
        "saved": "Yadda saxlandı",
        "save": "Yadda saxla",
        "andContinue": " və davam et",
        "delete": "İşi sil",
        "add": "İş əlavə et",
    },
    "EducationSettingsForm": {
        "country": "Ölkə:",
        "selectCountry": "Ölkə seçin",
        "alreadySelectCountry": "İlk öncə ölkəni seçin",
        "alreadySelectCity": "İlk öncə şəhəri seçin",
        "city": "Şəhər: ",
        "searchCity": "Şəhərə görə axtarış",
        "no-cities": "Axtarışınıza uyğun şəhər tapılmadı",
        "education-place": "Məktəbin adı",
        "education-place-nothing": "Axtarışınıza uyğun məktəb tapılmadı",
        "education-palce-error": "Məktəbin adını daxil edin",
        "education-position": "İxtisas",
        "education-position-error": "Öyrəndiyiniz sahəni daxil edin",
        "education-start": "Təhsilə başlama ili",
        "education-start-error": "Təhsilə başlama ilini daxil edin",
        "education-end": "Təhsilin bitmə ili",
        "education-end-now":
            "Hələ də təhsil almaqdasınızsa, bu sahəni boş buraxın",
        "saved": "Yadda saxlandı",
        "save": "Yadda saxla",
        "andContinue": " və davam et",
        "delete": "Təhsil yerini sil",
        "add": "Təhsil yeri əlavə et",
    },
    "Settings": {
        lang: "Sayt dili",
        "information": "Əsas",
        "added-info": "Məlumat",
        "about": "Marifətlər",
        "career": "Karyera",
        "education": "Təhsil",
        "photos": "Fotolar",
        "name": "Ad",
        "person-name": "Şəxsin adını daxil edin",
        "name-error": "Ad 60 simvoldan qısa olmalıdır",
        "address": "Səhifə ünvanı",
        "address-error":
            'Səhifə ünvanı yalnız latın hərfləri, nömrələri və "_" və "-" işarələrini ehtiva edə bilər və 3-dən qısa və 30-dan çox simvol olmamalıdır',
        "address-length-error": "Ünvan {min} simvoldan uzun olmamalıdır",
        "birthday": "Doğum tarixi",
        "birthday-error": "Doğum tarixini seçin",
        "marital": "Ailə vəziyyəti",
        "maritalList": ["Subay", "Evli", "Evli deyil", "Boşanmış"],
        "select": "Siyahıdan seçin",
        "children": "Uşaqlar",
        "children-error": "Tam ədədi daxil edin və ya sahəni boş buraxın",
        "height": "Hündürlük",
        "maxHeightError": "Maksimum icazə verilən dəyər 200",
        "maxWeightError": "Maksimum icazə verilən dəyər - 200",
        "heightUnit": "sm",
        "weightUnit": "kq",
        "height-error": "Boy və uzunluğunuzu daxil edin",
        "weight-error": "Çəkinizi daxil edin",
        "gender": "Cinsiyyət",
        "genderList": [
            {
                id: "M",
                name: "Kişi",
            },
            {
                id: "F",
                name: "Qadın",
            },
        ],
        "eye-color": "Göz rəngi",
        "saved": "Saxlanan",
        "delete-account": "Hesabı sil",
        "exit": "Çıxış",
        "nameTimeError": "Adı 24 saatda bir dəyişdirə bilərsiniz",
    },

    "InfoSettingsForm": {
        "web": "Vebsayt",
        "selfEsteem": "Nə özünüzdə inam hissi yaratır?",
        "address-incorrect":
            "Düzgün linki daxil edin, formatında: https://merlinface.com",
        "self-esteem-error":
            "En çox 50 simvol, siz {{ selfEsteem.length }} daxil etmisiniz",
        "social-status": "Sizin sosial statusunuz:",
        "socialList": [
            "Şagird",
            "Tələbə",
            "İşsiz",
            "Öz işində çalışan",
            "Şirkət əməkdaşı",
            "(Həm)şirkət sahibi",
        ],
        "country": "Ölkə:",
        "city": "Şəhər:",
        "selectCountry": "Ölkə seçin",
        "searchCities": "Şəhərə görə axtarış",
        "saved": "Saxlanıldı",
    },
    "settings": {},
    "SocialRating": {
        "start": "Necə{suffix}",
        "end": "mən",
    },
    "UserRecommendationsMobile": {
        "analyze":
            "Tövsiye edilən insanlar analiz edildi. Bu 24 saata qədər davam edə bilər.",
        "category_1": "peşə&nbsp;məqsəd",
        "category_2": "İlişkiler&nbsp;aile",
        "category_3": "Motivasiya&nbsp;effektivlik",
        "category_4": "inam hissi&nbsp;özünə etimad.",
    },

    Person: {
        "you": "Siz",
        "user": "User",
        compare: {
            "fromMe": "Dostlarınızla müqayisə edin",
            "fromUnnamedUser":
                "<span>Adsız bir şəxsə</span> özünüzü müqayisə edin",
            "fromNamedUser": "<span>{user}</span> ilə özünüzü müqayisə edin",
        },
        indicator: {
            "fromMe": "Özünüz haqqında daha çox öyrənin",
            "fromUnnamedUser": "Daha çox öyrənin: <span>Adsızİstifadəçi</span>",
            "fromNamedUser": "Daha çox öyrənin: <span>{user}</span>",
        },
        aggression: {
            "fromMe": "Öz açılış səviyyənizi öyrənin",
            "fromUnnamedUser":
                "Açılış səviyyəsi: <span>Adsız İstifadəçi</span>",
            "fromNamedUser": "Açılış səviyyəsi: <span>{user}</span>",
        },
    },

    "PersonList": {
        "celebrities": "Məşhurlar",
        "fans": "Azərbaycanlılar",
        "friends": "Bütün dostlar",
        "friends-requests": "Dostlug sorguları",
        "select-all": "Bütün dostlarınızı seçin",
        "no-with-params":
            "Göstərilən parametrlərə görə heç bir məşhur insana rast gəlinmədi",
        "no-friends": "Hələ ki, dostunuz yoxdur.",
        "no-requests": "Hələ ki, dostluq təklifi almadınız.",
        "no-celebrities":
            "Göstərilən parametrlərə görə heç bir məşhur insana rast gəlinmədi",
        search: {
            "celebrity": "Məşhur insanları axtarış",
            "friends": "Dostlara görə axtarış",
            "requests": "Dostluq təkliflərini axtarmaq",
            "users": "Şəxslərə görə axtarış",
        },
    },
    "PersonShortCard": {
        typeLocked: "Şəxsiyyət növü hələ unlock olunmayıb",
        sendMessage: "Mesaj göndər",
        compare: "Müqayisə et",
        hide: "gizlət",
        removeFromFriends: "Dostlardan çıxar",
        acceptRequest: "Əlavə et",
        declineRequest: "Rədd et",
        confirmDeleteFriendTitle: "Hərəkəti təsdiqləyin",
        confirmDeleteFriendText:
            '"{user}" identifikatorunu dostlarınızdan silmək istədiyinizə əminsinizmi?',
        confirmDeleteFriendBtn: "Silmək",
    },
    "Promocode": {
        "btn": "Məndə promo kodu var",
        "enter": "Promokodu daxil edin",
    },
    "RecommendedPeople": {
        "no-persons": "Merlin hələ sizə insanları təqdim etməyib",
        tabs: {
            partner: "İş münasibətləri",
            love: "Münasibətlər",
            coming: "Tezliklə",
        },
        "type-undefined": "Anlaşılmaz",
        toProfile: "PROFİLƏ",
        "age_1": "yaş",
        "age_2": "yaş",
        "age_3": "yaş",
        "status": "Sosial status",
        "no-status": "Status yoxdur",
        "no-goal": "Göstərilməyib",
        "city": "Şəhər",
        "no-city": "Göstərilməyib",
        "noAge": "Yaş göstərilməyib",
        "money-target": "Mənim qazanc hədəfim",
        "spheres": "Fəaliyyət sahələri",
        "no-spheres": "Göstərilməyib",
        "next": "Növbəti",
        "loading": "Yüklənir...",
        category_1: "Peyşə ve &nbsp;məqsəd",
        category_2: "Əlaqələr ve&nbsp;aile",
        category_3: "Motivasiya ve&nbsp;effektivlik",
        category_4: "Özünə etimad ve&nbsp;əminlik",
        "next-recommendation": "Növbəti şəxs",
    },
    "SubscriptionsCategoriesItem": {
        "active": "abunəlik aktivdir",
    },
    "SubscriptionsContent": {
        "active": "Abunəlik {date} tarixinədək aktivdir",
        category_1: "Peşə və Məqsəd, FREE",
        category_2: "Münasibətlər & Ailə, FREE",
        category_3: "Motivasiya və İfadə, FREE",
        category_4: "Özünü İşlədilmə və Özünə İnanma, FREE",
        cancelTitle: "abunəliyi ləğv et.",
        cancelText:
            '{period} abunəliyinizi "{category}" ləğv etmək istədiyinizi təsdiqləyirsinizmi?',
        cancelBtn: "abunəliyi ləğv et",
        mounthly: "aylıq",
        annual: "illik",
    },
    SubscriptionsTableForm: {
        payBtn: "Ödənişə keç",
        withBalance: "Ödəniş veb saytın balansından çıxacaq",
    },
    SubscriptionsTableInfo: {
        discount: "Sizin endirim: {discount}&nbsp;₽",
        chooseAnnual:
            "İllik abunəliyi seçin<br/>və {discount}&nbsp;₽ qənaət edin",
        active: "Aktiv abunəlik",
    },
    Subscriptions: {
        title: "Maragınızdakı abunəlik kateqoriyasını seçin",
        canceled: "Abunəlik uğurla ləğv edildi!",
        succeeded: "Uğurla abunə oldunuz!",
        choosePlan: "Bir plan seçin:",
    },
    "about": {
        "title": "merlinface.com layihəsinə xoş gəlmisiniz!",
        "text-1": "Ümumi təminat",
        "text-2":
            "Komandamız bir Neural Şəbəkə yaratdı, vasitəsilə siz diqqət, yaddaş, hissi-iradi sahə, hərəkətarınızın mətn və qrafik diaqnozunu, həmçinin bu göstəricilərə əsaslanan bir çox individualizə edilmiş tövsiyələr əldə edə bilərsiniz.",
        "text-3":
            "Istifadəçilərin diaqnostikasında peşəkar mütəxəssislər də işləyir: neyropsixoloqlar, kognitiv-davranışçı terapevtlər və digər sahələrin mütəxəssisləri.",
        "text-4": "Faydalı iç görüler!",
        "vk": "VKontakte qrupumuz:",
    },
    "cookie": {
        "title": "Cookie siyasəti",
        "text-1":
            "Cookies istifadəçinin kompüterində saxlanılan və server tərəfindən göndərilən kiçik mətn parçalarıdır. Cookies bir çox funksiyaları yerinə yetirir, məsələn, İstifadəçinin tərəfindən yaradılan parametrləri saxlayır, avtorizasiyadan sonra səhifələr arasında hərəkət etməyə imkan verir və gənəlliklə saytdan istifadəni asanlaşdırır.",
        "text-2": "Biz cookies-dan aşağıdakıları üçün istifadə edirik:",
        "text-3":
            "1. İdentifikasiya — cookies sizin cihazınızı və Hesabınızı istifadəçi adınızı və parolunuzu daxil etdiyiniz təqdirdə tanımağa və hər səhifəyə keçid etdikdə onları tələb etməməyə imkan verir.",
        "text-4":
            "2. Statistika — cookies müəyyən səhifələrə göz atmağa dair məlumatları toplamağa imkan verir.",
        "text-5":
            "Siz öz brauzerinizi cookies-i qəbul etməməyə təşviq edə bilərsiniz, lakin bu Platformadan istifadə etmək bacarığınızı çox məhdudlaşdıracaq.",
    },
    "payments": {
        "title": "Ödəniş və sifarişin qəbulu",
        "text-1": "Bank kartı ilə ödəniş",
        "text-2":
            "Robokassa emalat mərkəzi — VİSA və MasterCard-ın rəsmi xidmət təminatçısı — saytda ödənişləri qəbul edir.",
        "text-3":
            "Onlayn ödəniş üçün siz MIR, Visa, Visa Electron, MasterCard və Maestro bank kartlarını istifadə edə bilərsiniz. Əgər sizin kartınız 3D-Secure abunəsi isə, ödənişiniz bir dəfəlik parol vasitəsilə təsdiq olunacaq.",
        "text-4":
            "Həssas ödəniş məlumatlarının daxil edilməsi və emalı emalat mərkəzinin tərəfindən aparılır. Ödəniş məlumatları məxfi kanallar vasitəsilə şifrelenmiş formada banka ötürülür. Heç kəs, hətta satıcı belə, müştərinin daxil etdiyi bank kartı məlumatlarını ala bilməz, bu da onun pul və şəxsi məlumatlarının tam təhlükəsizliyini zəmanət edir.",
        "text-5":
            "Uğurlu ödənişdən sonra, ödənişi təsdiq edən və onun bənzərsiz tanımlayıcısını içərən bir elektron çek ödəyənə elektron poçtla göndərilir.",
        "text-6":
            "Məbləğin qaytarılması halında pul eyni karta geri qaytarılır ki, ödəniş onunla edilmişdi.",
        "text-7":
            "Əlaqəli ödəniş prosesi ilə bağlı bütün suallarınızı Robokassa 24/7 dəstək mütəxəssislərinə +7 499 110 57 25 telefon nömrəsi ilə yada support@robokassa.ru elektron poçtuna yazaraq verə bilərsiniz",
        "text-8": "Yandex.Money, Webmoney, Qiwi ilə ödəniş",
        "text-9":
            "Sifarişinizin ödənişini merlinface.com veb saytında Yandex.Money, WebMoney, QIWI elektron pulqabını kullanaraq edə bilərsiniz. Ödəniş təhlükəsizliyi Robokassa emal merkezi tərəfindən təmin edilir.",
        "text-10":
            "Geri ödəniş halında pul ödənişin həyata keçirildiyi eyni elektron pulqabına qaytarılır.",
        "text-11":
            "Elektron pul vasitəsilə ödənişlə bağlı hər hansı bir sualınız olarsa, telefon nömrəsi +7 499 110 57 25 olan Robokassa-nın round-də saata dəstək xidməti ilə əlaqə saxlaya bilərsiniz",
        "text-12": "Almaq üçün məlumat xidmətləri siyahısı:",
        "text-13":
            '1. "Professiya və Özünü Reallaşdırma" xidməti. İstifadəçinin özünü reallaşdırma mövzusunda təsviri və uyğun professiyalar üçün tövsiyələrinin verilməsi. Qiymət 150 ilə 500 rubl arasındadır.',
        "text-14":
            '2. "Özünü Qiymətləndirmə" xidməti. İstifadəçinin təsviri və özünü qiymətləndirmə mövzusunda tövsiyələrinin verilməsi. Dəyəri 150 ilə 200 rubl arasındadır.',
        "text-15":
            '3. "Motivasiya və Enerji" xidməti. İstifadəçinin təsviri və özünü qiymətləndirmə mövzusunda tövsiyələrinin verilməsi. Dəyəri 150 ilə 500 rubl arasındadır.',
        "text-16":
            '4. "Münasibətlər və Ailə" xidməti. İstifadəçinin digər insanlarla münasibətlər mövzusunda təsviri və tövsiyələrinin verilməsi. Qiymət 100 ilə 400 rubl arasındadır.',
        "text-17":
            '5. "Münasibətlərdə uyğunluğu" göstərən xidmət faizlə göstərilir. Qiymət 50 rubl.',
        "text-18":
            '6. "Xarakterlərin oxşarlığını" göstərən xidmət faizlə göstərilir. Qiymət 25 rubl.',
        "text-19":
            '7. "Biznesdə uyğunluğu" göstərən xidmət faizlə göstərilir. Qiymət 100 rubl.',
        "text-20":
            '8. "İşdə necə davranır" xidməti faizlə göstərilir. Qiymət 50 rubl.',
        "text-21":
            '9. "İnsanlarla necə davranır" xidməti faizlə göstərilir. Qiymət 50 rubl.',
        "text-22":
            '10. "Döyüş sənətkarlığında potensialı" göstərən xidmət faizlə göstərilir. Qiymət 50 rubl.',
        "text-23":
            '11. "Çempion potensiali" xidməti faizlə göstərilir. Qiymət 50 rubl.',
        "text-24":
            "Platformanın hər bir İstifadəçisi ilə müqayisə edərkən və ya hər bir İstifadəçinin maraqlandığı təsvir blokunu açarkən bir dəfəlik alış-verişlər üçün məbləğ sistemada balansdan çıxarılır.",
        "text-25":
            "Uğurlu ödənişdən sonra xidmət avtomatik olaraq merlinface.com-daki istifadəçinin səhifəsində açılır. Xidmət daimi olarak verilir.",
        "text-26":
            'Məbləğlərin qaytarılması 07.02.1992-ci il tarixli 2300-I №-li "İstehlakçı Hüquqlarının Müdafiəsi Haqqında" Rusiya Federasiyası Qanunu ilə tənzimlənir',
    },
    "privacy-policy": {
        "title": "",
        "text-1": "Ümumi tənzimləmələr",
        "text-2":
            'Bu Məxfilik Siyasəti 27.07.2006 tarixli Federal Qanunun tələblərinə əsasən tərtib edilmişdir. № 152-FZ "Şəxsi məlumatlar haqqında" və "Merlin AI" MMC-nin (bundan sonra - Administrasiya) şəxsi məlumatların emal prosedurunu və şəxsi məlumatların təhlükəsizliyini təmin edən tədbirləri müəyyən edir.',
        "text-3":
            "1.1. Administrasiya öz fəaliyyətinin ən vacib məqsəd və şərti kimi şəxsi məlumatların emalı zamanı insan və vətəndaş hüquq və azadlıqlarının təmin olunmasını qarşıya qoyur, o cümlədən, məxfiliyin, şəxsi və ailə sirrlarının qorunmasına hüquq.",
        "text-4":
            '1.2. Bu Administrasiyanın şəxsi məlumatların emalına dair siyasəti ("Siyasət") Administrasiyanın https://merlinface.com veb-saytını ziyarət edən və Merlin Dating mobil tətbiqini istifadə edən bütün məlumatları əhatə edir',
        "text-5": "2. Siyasətdə istifadə olunan əsas anlayışlar",
        "text-6":
            "Şəxsi məlumatların bloklandırılması - şəxsi məlumatların emalının müvəqqəti dayandırılması (anlaşılmaz şəxsi məlumatlara dəqiqlik sağlamaq məqsədilə emal etmək istisna olunur);",
        "text-7":
            "Veb-sayt - https://merlinface.com internet ünvanında internetdə yerləşdirilən və Merlin Dating mobil tətbiqatında grafik və məlumat materiallarından, kompüter proqramlarından və verilənlər bazasından ibarət dəstə;",
        "text-8":
            "Şəxsi verilənlər sistemi - şəxsi verilənlər məlumatlarını emalçıya təqdim edən şəxsi məlumatlar verilənlər bazasında olan ve onların emalı üçün informasiya texnologiyasi və texniki vasitələri təmin edən şəxsi verilənlər dəstəsi;",
        "text-9":
            "Şəxsi məlumatların depersonallaşdırılması - şəxsi məlumatların hansı istifadəçiyə və ya başqa şəxsi məlumat subyektinə aid olduğunu müəyyən etmək üçün əlavə məlumatlar istifadə olunmadan mümkün olmayan əməllər;",
        "text-10":
            "Şəxsi məlumatların emalı - avtomatlaşdırılmış və ya avtomatlaşdırılmamış vasitələrlə şəxsi məlumatlarla edilən hər hansı bir əməliyyat (əməliyyat) və ya bir dəstə əməliyyatlar (əməliyyatlar), o cümlədən, toplama, qeydiyyat, sistemləşdirilmə, birikmə, saxlama, düzəltmə (yeniləmə, dəyişiklik), çıxartma, istifadə, köçürmə (paylaşma, təqdim etmə, giriş), anonimləşdirilmə, bloklandırılma, silinmə, məhv etmə;",
        "text-11":
            "Administrasiya - hüquqi şəxs və ya fiziki şəxs, müstəqil və ya başqaları ilə birlikdə şəxsi məlumatların emalını təşkil edən və (və ya) onu həyata keçirən, şəxsi məlumatların emalını müəyyən edən, emal ediləcək şəxsi məlumatların tərkibini, şəxsi məlumatlarla edilən əməlləri (əməliyyatları) müəyyən edən;",
        "text-12":
            "Şəxsi Məlumatlar - birbaşa və ya dolayı https://merlinface.com veb-saytının tanınmış və ya tanınabilir istifadəçisinə və ya Merlin Dating mobil tətbiqinə aid olan hər hansı bir məlumat",
        "text-13":
            "İstifadəçi - https://merlinface.com veb-saytı və ya Merlin Dating mobil tətbiqatını ziyarət edən hər bir şəxs",
        "text-14":
            "Şəxsi verilənlərin təqdim edilməsi – şəxsi verilənlərin konkret bir şəxsə və ya müəyyən bir dairədəki şəxslərə açıqlanması üzrə hərəkətlər;",
        "text-15":
            "Şəxsi məlumatların yayılması - şəxsi məlumatların kimsəyə məlum olmamaq şərti ilə şəxslərə açıqlanması (şəxsi məlumatların köçürülməsi) və ya şəxsi məlumatlarla tanış olmağa imkan veren hər hansı bir hərəkət, daxil olmaqla şəxsi məlumatların KİV-də nəşrini, informasiya və telekommunikasiya şəbəkələrində yerləşdirilməsini və ya şəxsi məlumatlara başqa hər hansı bir yolla giriş imkanı yaratmağını; ",
        "text-16":
            "Şəxsi məlumatların ölkədən kənara köçürülməsi - şəxsi məlumatların xarici bir ölkəyə, xarici bir quruma, xarici bir şəxsə və ya xarici bir hüquqi şəxsə köçürülməsi; ",
        "text-17":
            "Şəxsi məlumatların məhv edilməsi – şəxsi məlumatların məzmununu şəxsi məlumatların informasiya sisteminə və ya (vəya) məlumat daşıyıcılarının məhv edilməsine səbəb olan hər hansı bir hərəkət. ",
        "text-18":
            "Ödəniş – İdarədən haqqın ödənilməsi, İstifadəçi tərəfindən İdarəyə qeyri-münhasir lisenziyanın verilməsi üçün ödənilən pul. ",
        "text-19":
            "3. İdarə İstifadəçinin aşağıdakı şəxsi məlumatlarını emal edə bilər:",
        "text-20": "3.1. Soyad, ad, ata adı;",
        "text-21": "3.2. Elektron poçt adresi;",
        "text-22": "3.3. Telefon nömrələri;",
        "text-23": "3.4. Doğulduğu il, ay, tarix və yeri;",
        "text-24": "3.5. Fotoşəkillər;",
        "text-25":
            "3.6. Təhsil, peşə, ixtisas və kvalifikasiya haqqında məlumatlar, təhsil sənədlərinin detalları;",
        "text-26":
            "3.7. Sayt həmçinin ziyarətçilərin şəxsiyətsiz məlumatlarını yığır və emal edir (cookies daxil olmaqla) İnternet statistika xidmətlərini istifadə edərək (Yandex Metrika və Google Analytics, və s.).",
        "text-27":
            "3.8. Sayt avtomatik və ya İstifadəçinin iştirakı ilə əlavə məlumatları toplaya bilər, burada IP adresi, brauzer versiyası, ölkə, dil, tərəfdaş etiketləri, İstifadəçinin Sayta daxil olduğu səhifənin ünvanı və digər texniki məlumatlar var ki, bunlar İstifadəçini əlavə tədbirlər olmadan müəyyənləşdirə bilər.",
        "text-28":
            "3.9. Saytın müxtəlif xidmətlərini istifadə etmək üçün İstifadəçinin üçüncü tərəf xidmət hesablarına girişə icazə verməsi xahiş oluna bilər, həmçinin ictimai fayl anbarları, dərhal mesajlaşma, sosial şəbəkələr və həmin cür digərləri. Bu halda Sayt üçüncü tərəflərdən əlavə şəxsi məlumatlar ala bilər, burada cins, mövqe, istifadəçi avatarı (userpic) və s. var. Üçüncü tərəfin xidmət təminatçısı vasitəsilə əldə edilən bütün məlumatlar User Agreement və Privacy Policy uyğun olaraq emal edilir və saxlanılır.",
        "text-29": "4. Şəxsi Məlumatların Emalının Məqsədləri",
        "text-30":
            "4.1. İstifadəçinin Şəxsi Məlumatlarını Proses ləşdirmənin məqsədi, İstifadəçinin Hesabını və Şəxsi rəhbərini İnternet Səhifələri və mobil tətbiq işlədəcəyi üçün; istifadəçini poçt və ya ismarıc göndərərək məlumatlandıran; müştəri xidməti və texniki dəstək təmin etmək; İstifadəçiyə İnternet Səhifəsində və tətbiqdə yer alan xidmətlərə, məlumatlara və/veya materiallara giriş ver.",
        "text-31":
            "4.2. Eyni zamanda inzibatiyya İstifadəçiyə yeni məhsullar və xidmətlər, xüsusi təkliflər və müxtəlif tədbirlər hakkında bildiriş göndərmə hüququna malikdir. İstifadəçi hər zaman məlumat mesajları almağıdan imtina edə bilər, ona info@merlinface.com-da inzibatiyyaya müraciət edərək «Yeni məhsul və xidmətlər və xüsusi təkliflər hakkında bildirişlərdən imtina et» qeyd edə bilər.",
        "text-32":
            "4.3. İstifadəçinin Saytla necə əlaqələndiyinə asılı olaraq, İstifadəçiyə məhsul və xidmətlər haqqında individual marketinq mesajları, eləcə də Saytla əlaqədə məlumatlar göndərilə bilər ki, bu, reklam kampaniyalarını düzəltməyə, məsələn, sosial şəbəkələrdə reklamları artıq İstifadəçi olanlara göstərməməyə imkan verir.",
        "text-33":
            "4.4. İnternet statistik xidmətləri vasitəsilə yiginilmış anonim İstifadəçi məlumatları, İstifadəçilərin saytda olan hərəkətləri barədə məlumat toplamaq, saytın keyfiyyətini və məzmununu yaxşılaşdırmaq üçün istifadə olunur.",
        "text-34":
            "4.5. İstifadəçi myAlpari-nda hərəkətlər yerinə yetirərkən təhlükəsizlik məqsədilə və saxtakar hərəkətlərin qarşısını almaq üçün aşağıdakı hərəkətlər qeyd olunur: qeyd olunduğu tarix və saat, avtorizatsiya, abunə olunduğu tarix və saat və digər tür işlər, İsmarıc göndərildiği tarix və saat, Profil səhifəsi silindi.",
        "text-35": "5. Şəxsi Məlumatların Proses ləşdirməsinin Hüquqi əsası",
        "text-36":
            "5.1. İnzibatiyya Yalnız İstifadəçinin öz Məlumatlarını doldurub və/veya İnternet saytındakı və mobil platformasındakı xüsusi formalar vasitəsilə özbaşına göndərdikdə İstifadəçinin Şəxsi Məlumatlarını Proses ləşdirir. Münasibetli formları dolduraraq və/veya şəxsi məlumatlarını İnzibatiyyaya göndərərək, İstifadəçi bu Siyasətə razılığını ifadə edir.",
        "text-37":
            "5.2. İnzibatiyya, İstifadəçinin brauzer parametrlərində icazə verilmişsə (cookie-ə dəyişdirmə və JavaScript texnologiyasının istifadəsi aktivdir), İstifadəçinin anonim məlumatlarına işləyir.",
        "text-38":
            "5.3. İstifadəçi Saytla inteqrasiya olunmuş üçüncü tərəf xidmətlərinə qoşulmaqda və istifadə etməkdə öz şəxsi məlumatlarını öz ixtiyarı ilə təmin edə bilər.",
        "text-39":
            "6. Şəxsi Məlumatların toplanması, saxlanması, köçürülməsi və digər Proses ləşdirmə əməliyyatları",
        "text-40":
            "6.1. Administrasiya tərəfindən emal olunan şəxsi məlumatların təhlükəsizliyi, şəxsi məlumatların qorunması sahəsindəki tətbiq edilən qanunlarının bütün tələblərinə tam uyğun olmaqla hüquqi, təşkilati və texniki tədbirlərin icrası ilə təmin edilir.",
        "text-41":
            "6.2. Administrasiya istifadəçinin şəxsi məlumatlarını qanunsuz və ya təsadüfi olaraq giriş, silmə və ya dəyişikliklə, bloklanma, köçürmə, ifşa və ya üçüncü tərəflərin digər qanunsuz əməllərindən qorumaq üçün bütün lazımi texniki və təşkilati ön tədbirləri götürür. İstifadəçinin şəxsi məlumatlarının itkisi və ya açıqlanması halında, Administrasiya istifadəçini onun şəxsi məlumatlarının itkisi və ya açıqlanması barədə xəbərdar edir.",
        "text-42":
            "6.3. İstifadəçidən üçüncü tərəflər tərəfindən, məsələn, ödəmə etmək, və ya sayt ilə inteqrasiya olunmuş üçüncü tərəf xidmətləri ilə əlavə xüsusiyyətlər əlavə etmək üçün Şəxsi Məlumatlar və digər məlumatlar tələb edilə bilər. İstifadəçi özünün şəxsi məlumatlarını və digər məlumatlarını öz xoşun məmnuniyyətilə verir. Üçüncü tərəflər tərəfindən tələb olunan bütün şəxsi məlumatlar bu üçüncü tərəflərin İstifadəçi Müqaviləsi və Gizlilik Siyasətinə əsasən emal və saxlanılmalıdır. ",
        "text-43":
            "6.4. Administrasiya Şəxsi Məlumatları Administrasiyanın sifarişi əsasında xidmətlər göstərən təchizatçılara ötürə bilər. Məsələn, Administrasiya müştəri dəstəyi göstərmək, üçüncü tərəf resurslarına reklamlar idarə etmək, Administrasiyanın adından pazarlama və digər mesajları göndərmək, və ya məlumatların saxlanılmasında kömək edən üçüncü tərəfləri cəlb edə bilər. Belə üçüncü tərəflərin istifadəçilərin Şəxsi Məlumatlarını reklam məqsədləri üçün istifadə etməsi qadağandır.",
        "text-44":
            "6.5. Əgər Şəxsi Məlumatlarda hər hansı səhvlər varsa, İstifadəçi onları özü yeniləyə bilər, bu məqsədlə law@merlinface.com elektron poçtuna «Şəxsi Məlumatların Yenilənməsi» mövzulu bir bildiriş göndərməklə.",
        "text-45":
            "6.6. Şəxsi Məlumatların emal edilməsi dövrü məhdudiyyəti yoxdur. İstifadəçilər şəxsi məlumatların emal edilməsi uyğunluğunu hər zaman law@merlinface.com elektron poçtuna «Şəxsi məlumatların emal edilməsi razılaşmasının geri çəkiləməsi» yeri işarə olunmuş bir bildiriş göndərməklə geri çəkə bilərlər. Texniki səbəblərdən dolayı, məlumatları dərhal silə bilməyib, yalnız gecikmə ilə silinə bilər. Bundan başqa, hüquqi öhdəliklərin icrası, mübahisələrin həlli, dolandırıcılıq önələməsi və Administrasiyanın qanuni mənafelərinin qorunması ilə əlaqədar olaraq bəzi məlumatların saxlanılması mümkündür.",
        "text-46":
            "6.7. İstifadəçi tərəfindən yüklənmiş üçüncü tərəflərin şəkilləri saxlanılmır və məlumatların fərdiləşdirilməsi və ya Şəxsi Məlumatların mövzusu müəyyənləşdirilməsi üçün xidmət tərəfindən istifadə edilmir.",
        "text-47":
            "6.8. Sayt, Faydalananın Sayta birdən-birdə məlumat göndərdiyi şəxsi məlumat operatorudur və axtarış məqsədləri üçün yalnız İstifadəçi tərəfindən yüklənmiş məlumatı istifadə edir.",
        "text-48":
            "6.9. İstifadəçi Məlumatları və yüklənmiş şəkillərin istifadəsi, şəkli Serverə köçürməklə baş verir. Merlin alqoritmi üzü nöqtələyərək xüsusiyyətlərin parametrlərini və ifadəsini müəyyən edir. İstifadəçi şəkilləri Serverdə saxlanmır və Xidmət və/veya üçüncü tərəflər tərəfindən istifadə edilmir. Xüsusiyyətlərin ölçüsü və ifadə parametrləri Serverdə, üçüncü tərəflərə köçürmədən saxlanılır.",
        "text-49":
            "6.10. Xidmət, xüsusiyyətlərin ölçüsü və ifadəsi haqqında məlumatları biriktirir və istifadə edir ki:",
        "text-50":
            "iki insanın xüsusiyyətlərinin parametrlərini Merlin alqoritmini müəllif metodologiyası əsasında uyğunluq, oxşarlıq və digər göstəriciləri təyin etmək üçün müqayisə edək;",
        "text-51":
            "İstifadəçi üçün Merlin alqoritmine daxil olunmuş müəllif metodolojiya əsasında uygun şəxsiyyətləri seçək;",
        "text-52":
            "Xidmətin verilənlər bazasında xüsusiyyət parametrlərinə görə şəxsi axtarış edək;",
        "text-53":
            "Sayt və Tətbiqdəki gələcək İstifadəçilər üçün uyğunluq nəticələrini saxlayaq.",
        "text-54": "7. Ödəniş siyasəti",
        "text-55":
            "7.1. İstifadəçinin Saytın xidmətlərindən istifadə etdiyi Abunə ödənişi, biri amma məhdud olmayan, ödəniş sistemlərindən birini istifadə etməklə həyata keçirilir: Visa, MasterCard, Maestro, MIR, Yandex.Money, Webmoney, Qiwi, ApplePay, GooglePay, PayPal. Bu ödəniş sistemləri, istifadəçi müqaviləsi və gizlilik siyasəti doğrultusunda maliyyə məlumatlarını toplayır və saxlayır.",
        "text-56":
            "7.2. Administrasiya kartın tam məlumatlarını saxlamır və ödənişlərimizi emal etmir, yalnız ödəniş sistemindən uğurlu ödəniş barədə bildiriş alır.",
        "text-57":
            "7.3. Administrasiya, İstifadəçi tərəfindən həyata keçirilmiş ödənişlə bağlı əlavə məlumat toplaya bilər, əlavə olaraq, amma yalnız: əməliyyat nömrəsi, əməliyyat vaxtı, ödəniş üçün istifadə edilən kartın növü və son tarixi və kart nömrəsinin son dörd rəqəmi, kart sahibinin adı, ödənişin həyata keçirildiyi ölkə və şəhər.",
        "text-58": "8. Şəxsi məlumatın xarici ölkələrə köçürülməsi",
        "text-59":
            "8.1. Şəxsi Məlumatların sərhəd ötürülüyündən əvvəl İdarəetmə, Şəxsi Məlumatların köçürüləcəyi xarici ölkənin, Şəxsi Məlumatların subyektinə etibarlı hüquqların müdafiəsini təmin etdiyini təmin etməlidir .",
        "text-60":
            "8.2. Yukarıdakı tələblərə uyğun olmayan xarici ölkələrə Şəxsi Məlumatların ötürülüyündən əvvəl Şəxsi Məlumatlarınızın ötürülüyəcəyi ölkənin hüquqlarının müdafiəsi təmin edilməli və / və ya Şəxsi Məlumatlar subyekti olan müqavilənin icrası yalnız Şəxsi Məlumatlar subyektinin yazılı rızasını əldə etdikdən sonra həyata keçirilə bilər.",
        "text-62": "9. Tərəflərin məsuliyyəti.",
        "text-63":
            '9.1. İstifadəçi üçüncü tərəflərin şəxsi məlumatlarını işləyərsə, istifadəçi şəxsi məlumatların müvafiq qorunması üçün təyin edilmiş tədbirlərə uyğunluqdan tamamilə məsuliyyət daşıyır(General Data Protection Regulation, GDPR), № 152-FZ Federal Qanunu, 27.07.2006 tarixli "Şəxsi məlumatlar haqqında" ve digər qanunlarle ve quyidlərlə, daxil olmaqla uygun icazələr almaq, Istifadəçinin Site-da lazımi sənədlər və məlumatlar yerləşdirilməsi.',
        "text-64":
            "9.2. Öhdəliklərin yerinə yetirilməməsi halında, İdarəetmə istifadəçinin şəxsi məlumatlarının qeyri-qanuni istifadəsi ilə əlaqəli istifadəçinin mənfi itki görməsi üçün məsuliyyət daşıyır, lakin hazırkı tarif əsasında Abunəlik üçün istifadəçinin ödədiyi haqqın miqdarına qədər, Rus qanunlarına uyğun olaraq, şəxsi məlumatların:",
        "text-65": "a) nəzərdə tutulan yetki dairənə açıqlanmışdır;",
        "text-66":
            "b) İdarəetmənin İstifadəçinin razılığı ilə onlara keçirildikdən sonra üçüncü tərəf tərəfindən açıqlanmışdırlar;",
        "text-67":
            "c) onlar itkisi və ya ifşası əvvəlində ictimai sicildə olmuşdular;",
        "text-68":
            "d) üçüncü tərəf tərəfindən İdarəetməyə təqdim edilmədən əvvəl alınmışdır;",
        "text-69": "e) İstifadəçinin razılığı ilə açıqlanmışdır;",
        "text-70": "f) qüvvə-i qanuniyyə nəticəsində açıqlanmışdır;",
        "text-71":
            "g) Üçüncü tərəflərin hüquqlarının və/veya İstifadəçi Müqaviləsinin pozulması barədə istifadəçiyə haqlı bir iddia nəticəsində açıqlandı.",
        "text-72": "10. Son Qeydlər",
        "text-73":
            "10.1. İstifadəçi, şəxsi məlumatların emalı ilə bağlı maraqlandığı suallara aydınlıq gətirə bilər, Administrasiyaya elektron poçt vasitəsilə müraciət edərək: law@merlinface.com.",
        "text-74":
            "10.2. Bu sənəd, Administrasiya'nın şəxsi məlumatlar emal siyasətindəki hər hansı dəyişiklikləri əks etdirəcək. Siyasət, yeni versiya tətbiq edilənə qədər müddətsiz qüvvədədir.",
        "text-75":
            "10.3. Siyasətin hazırkı versiyası İnternetdə azad şəkildə əldə edilə bilər: https://merlinface.com/docs/privacy-policy",
    },
    "subscriptions": {
        "choose-type": "Sizə marağınız olan abunəlik kateqoriyasını seçin",
        "cancel": "Abunəlik uğurla ləğv edildi!",
        "success": "Abunəlik uğurludur!",
        "choose-plan": "Bir plan seçin:",
        "junior":
            "Junior abunəliyi 4 kateqoriyada Gold imtiyazları daxil edir: <br/> Peşə və Öz-Realizasiya, Özünü Qiymətləndirmə və Ehtiyat, Motivasiya və Enerji, Əlaqələr və Ailə",
        "before-charges": "yüklənmədən əvvəl",
        "to-support": "dəstək əlaqə",
        "left": "qalıq",
        "status": "Abunəliyin statusu",
        "failed-to-renew": "abunəliyi yeniləmək uğursuz oldu",
        "auto-renew-active": "avto yeniləmə fəal",
        "active-to": "fəaliyyətə son tarix",
    },
    "RetakeOnboarding": {
        DEFAULT: "Psixotipimi təyin et",
        PROFESSION_ORIENTATION: "Peşə Tipləşdirilməsinə Keç",
    },
    ConnectSocial: {
        title: "Sosial şəbəkələri birləşdirin",
        enabled: "Birləşdirildi",
        enable: "Birləşdirin",
        enterEmail: "E-poçtunuzu daxil edin",
        codeSend: "Kod e-poçtunuza göndərildi. Onu bu sahəyə daxil edin.",
        youGetCode: "Təsdiqləmə kodu olan bir e-poçt alacaqsınız",
        retryEmail:
            "Əgər bir səbəbdən e-poçt gəlməsə, Siz kodu yenidən göndərə bilərsiniz <span> {timer} san... yaxın</span>",
        codeError: "Kod uyğun gəlmir",
        retrySend: "KODU YENİDƏN GÖNDƏR",
    },
    FriendActions: {
        requestSend: "Dostluq təklifi göndərildi",
        cancel: "Ləğv et",
        incomingRequest: "Gələn dostluq təklifi, qəbul edirsiniz?",
        accept: "Qəbul et",
        decline: "Rədd et",
        add: "Əlavə et",
        sendMessage: "Mesaj yaz",
        addToFriends: "Dostlar arasına əlavə et",
        partnerRequestSend: "Tərəfdaşlıq təklifi göndərildi!",
        makePartner: "Tərəfdaş et",
    },
    RecommendedPeopleCtrl: {
        title: "İnsanlara məsləhətlər",
    },
    AppLoading: {
        title: "İnternet bağlantınızı yoxlayın<br/>və səhifəni yenidən yükləyin",
    },
    ChooseFriendPhotos: {
        "analyze": "Təhlil et",
    },
    "ServiceCollapse": {
        "openNow": "İndi aç",
        confirmTitle: "Məsləhətin açılması",
        confirmText:
            "Bir məsləhəti indi açmaq üçün sizin PLATINUM tarifə abunə olmaq lazımdır.",
        confirmBtn: "Abunə ol",
    },
    LandingHeader: {
        advantages: "Nə əldə edirsiniz",
        steps: "Bu necə işləyir",
        mission: "Misyonumuz",
        enter: "Daxil olun",
    },
    LandingBanner: {
        title: "<span>Karyeraya sizəviyyəli test</span><br/> Nöron şəbəkə sizin üçün nəzərdə tutulan 3 peşəni seçir",
        "description":
            "Süni intellekt dünyasına daxil olun və Merlin AI ilə bacarıqlarınızı tətbiq edin",
        list: [
            "Öz güclü tərəflərinizi müəyyənləşdirin və şəxsi inkişafınız üçün tövsiyələr verin",
            "Müəllifin xüsusi metodologiyası",
            "Tərbiyyə Nazirliyi tərəfindən təsdiq olunmuş",
        ],
        btn: "Peşənizi öyrənin",
    },
    "LandingAbout": {
        "title":
            "<span>Merlinface —</span> bu, süni intellekt sahəsindəki peşəkarlar tərəfindən yaradılan bənzəri olmayan alqoritmdir",
        "list": [
            {
                "1": "Şəkilini yüklə və bizim neyron şəbəkəmiz sənə uyğun peşələri təklif edəcək",
                "2": "AI ilə söhbətlərdə iş təcrübəsi",
            },
            {
                "3": "Real vəzifələr və layihələr",
                "4": "Bizim neyron şəbəkəmizlə ünsiyyət qur, kursdan tapşırıqları yerinə yetir və neyron şəbəkə operatoru kimi bacarıqlarını inkişaf etdir",
            },
            {
                "5": "Əldə etdiyin bilikləri söhbətdə real layihələrdə tətbiq et",
                "6": "İT-də uğurlu başlanğıç üçün hazırlaş: bizim AI sənin şəxsi inkişaf tövsiyələrini hazırlayacaq",
                "7": "IT sahəsində uğurlu başlamaq üçün tövsiyələr",
            },
        ],
    },
    LandingAdvantages: {
        title: "Nə əldə edirsiniz",
        showVideo: "Videoya baxın",
        list: [
            {
                id: 1,
                active: true,
                code: "professional",
                title: "Peşəvi Yazma",
                description:
                    "Verilənlər bazamızda 1000-dən çox fərqli peşə var. Merlin AI sizə uğurlu olacağınız 3 peşə seçməyə kömək edəcək.",
            },
            {
                id: 2,
                active: false,
                code: "psychotype",
                title: "Psixoloji Yazma",
                description:
                    "Hər güclü və zəif tərəfini və onları necə inkişaf etdirmək lazım olduğunu öyrənəcəksiniz.",
            },
            {
                id: 3,
                active: false,
                code: "person-description",
                title: "Şəxsiyyətinizin və davranışınızın təsviri",
                description:
                    "Saytımızın hər üçüncü istifadəçisi sevdiyi işi tapır.",
            },
            {
                id: 5,
                active: false,
                code: "recommendations",
                title: "Şəxsi tövsiyələr",
                description:
                    "Merlin AI sizin üçün təxminən 150 fərdi tövsiyə hazırladı ki, bu sizə uğuru tezliklə nail olmağa imkan verəcək",
            },
            {
                id: 6,
                active: false,
                code: "person-recommendations",
                title: "Digər platforma üzvlərindən tövsiyələr",
                description:
                    "Merlin AI düzenlə sizin üçün motivatorları və yüksək iş uyğunluğuna malik insanları seçəcək, onların arasında iş tərəfdaşı, dost və ya münasibət üçün tərəfdaş tapa bilərsiniz",
            },
            {
                id: 7,
                active: false,
                code: "messages",
                title: "Tövsiye olunan peşələr üçün təlim linkləri",
                description:
                    "İş, münasibət və ya motivasiya üçün tərəfdaşı axtaran digər istifadəçilərlə söhbət edə bilərsiniz",
            },
            {
                id: 8,
                active: false,
                code: "celebrity",
                title: "Məşhurlar verilənlər bazasına giriş",
                description:
                    "Onları şəxsi olaraq tanımaq və əməkdaşlığa başlamaq, həmçinin uygunluğu müqayisə etmək imkanını ala bilərsiniz.",
            },
        ],
    },
    LandingMission: {
        title: "Misyonumuz",
        description:
            "Potensiallarını anlamaq və işlərini bacarıqları asasında tapmaqdan kökələrində düzəltmək üçün aləti yaratmışıqdır, qeyri-müəyyən olmaqla birləşmişdir.",
        memberCount: "Kimlik artıq xidmətdə",
        btn: "Qoşulun",
    },
    "LandingSteps": {
        "title": "Necə işləyir?",
        "steps": [
            {
                "number": 1,
                "title": "Aşağıdakı \"Başlat\" düyməsini klikləyin",
            },
            {
                "number": 2,
                "title": "Telefon nömrənizi yoxlayın",
            },
            {
                "number": 3,
                "title": "Söhbətə daxil olun və neyron şəbəkə ilə əlaqə yaratmağa başlayın",
            },
            {
                "number": 4,
                "title": "Nəticələri əldə edin"
            },
            {
                "number": 5,
                "title": "Süni intellektdən sizə uyğun olan peşələrin siyahısını əldə edin",
            },
        ],
        "result": {
            "title": "Nəticəni alırsınız",
            "list": [
                "Bir yerdən məşhur neyron şəbəkələrinə giriş",
                "Merlin AI-dən istənilən suala cavab almaq imkanı",
                "İstənilən mətn əsasında şəkillər yaratmaq bacarığı"
            ]
        },
        "btn": "Başlat",
    },
    LandingReviews: {
        title: "Mərlin üzündən nə deyirlər:",
    },
    MerlinLimit: {
        title: "Bugünkü sual limiti doldu - 5/5",
        desc: "Mərlinin AI ilə danışmağa davam etmək üçün hər hansı bir ödənişli tariflə keçin",
        btn: "Tariflərə baxın",
    },

    SubscriptionsData: {
        PROFESSION: {
            TITLES: [
                {
                    id: "PROF_OPTION_1",
                    name: "Öz-gerçekleşmə üçün şəxsi təsvirləriniz",
                },
                {
                    id: "PROF_OPTION_2",
                    name: "Özünü gerçekleştirmek üçün şəxsi tövsiyələr",
                },
                {
                    id: "PROF_OPTION_3",
                    name: [
                        "Mövcud göstəricilər:",
                        "Biznes uyğunluğu",
                        "Biznes münasibətləri",
                        "Fiziki təcavüz",
                        "Emosiyanal təcavüz",
                    ],
                },
                {
                    id: "PROF_OPTION_4",
                    name: "Şöhret Biznes Üstünlük Testi",
                },
                {
                    id: "PROF_OPTION_5",
                    name: "Merlinface ilə iş ortaqlarının işə götürülməsi",
                },
            ],
            FREE: [
                {
                    id: "PROF_OPTION_1",
                    value: "30% açıq",
                },
                {
                    id: "PROF_OPTION_2",
                    value: "həftədə 1",
                },
                {
                    id: "PROF_OPTION_3",
                    value: "gündə bir dəfə göstərilən",
                },
                {
                    id: "PROF_OPTION_4",
                    value: "—",
                },
                {
                    id: "PROF_OPTION_5",
                    value: ["həftədə 1", "1 mənim üçün seçilir", "1 alırsınız"],
                },
            ],
            GOLD: [
                {
                    id: "PROF_OPTION_1",
                    value: "tam açıq",
                },
                {
                    id: "PROF_OPTION_2",
                    value: "üç gündə 1",
                },
                {
                    id: "PROF_OPTION_3",
                    value: "saatda bir dəfə göstərilən",
                },
                {
                    id: "PROF_OPTION_4",
                    value: "aıda bir dəfə",
                },
                {
                    id: "PROF_OPTION_5",
                    value: [
                        "üç gündə 1",
                        "1 mənim üçün seçilir",
                        "3 alırsınız",
                    ],
                },
                {
                    id: "PROF_OPTION_6",
                    value: "həftədə 1",
                },
            ],
            PLATINUM: [
                {
                    id: "PROF_OPTION_1",
                    value: "tam açıq",
                },
                {
                    id: "PROF_OPTION_2",
                    value: ["gündə bir dəfə"],
                },
                {
                    id: "PROF_OPTION_3",
                    value: "məhdud olmayan",
                },
                {
                    id: "PROF_OPTION_4",
                    value: "həftədə bir dəfə",
                },
                {
                    id: "PROF_OPTION_5",
                    value: ["gündə bir dəfə", "1 seçilir", "5 hits you"],
                },
                {
                    id: "PROF_OPTION_6",
                    value: "həftədə bir dəfə",
                },
            ],
        },

        SELF_ESTEEM: {
            TITLES: [
                {
                    id: "ESTEEM_OPTION_1",
                    name: "Özünü inkişaf etdirmək üçün fərdi təsvirlər",
                },
                {
                    id: "ESTEEM_OPTION_2",
                    name: "Özünə inamı artırmaq üçün şəxsi tövsiyələr",
                },
                {
                    id: "ESTEEM_OPTION_3",
                    name: [
                        "Mövcud göstəricilər",
                        "Xarakterlərin oxşarlığı",
                        "Biznes münasibətləri",
                        "İnsanlarla münasibətlər",
                    ],
                },
                {
                    id: "ESTEEM_OPTION_5",
                    name: "Təcrübə mübadiləsi üçün insanların seçimi",
                },
            ],
            FREE: [
                {
                    id: "ESTEEM_OPTION_1",
                    value: "30% açıq",
                },
                {
                    id: "ESTEEM_OPTION_2",
                    value: "həftədə 1",
                },
                {
                    id: "ESTEEM_OPTION_3",
                    value: "gündə 1 dəfə göstərilən",
                },
                {
                    id: "ESTEEM_OPTION_4",
                    value: "—",
                },
                {
                    id: "ESTEEM_OPTION_5",
                    value: [
                        "həftədə 1 dəfə",
                        "mən üçün 1 seçilib",
                        "siz də 1 alacaqsınız",
                    ],
                },
            ],
            GOLD: [
                {
                    id: "ESTEEM_OPTION_1",
                    value: "tamamilə açıq",
                },
                {
                    id: "ESTEEM_OPTION_2",
                    value: "3 gündə 1 dəfə",
                },
                {
                    id: "ESTEEM_OPTION_3",
                    value: "saatda 1 dəfə göstərilən",
                },
                {
                    id: "ESTEEM_OPTION_4",
                    value: "ayda bir dəfə",
                },
                {
                    id: "ESTEEM_OPTION_5",
                    value: [
                        "3 gündə 1 dəfə",
                        "mən üçün 1 seçilib",
                        "siz 3 dəfə alacaqsınız",
                    ],
                },
                {
                    id: "ESTEEM_OPTION_6",
                    value: "həftədə 1 dəfə",
                },
            ],
            PLATINUM: [
                {
                    id: "ESTEEM_OPTION_1",
                    value: "tamamilə açıq",
                },
                {
                    id: "ESTEEM_OPTION_2",
                    value: ["gündə 1 dəfə"],
                },
                {
                    id: "ESTEEM_OPTION_3",
                    value: "hədsiz",
                },
                {
                    id: "ESTEEM_OPTION_4",
                    value: "həftədə 1 dəfə",
                },
                {
                    id: "ESTEEM_OPTION_5",
                    value: [
                        "gündə 1 dəfə",
                        "mən üçün 1 seçilib",
                        "siz 5 dəfə alacaqsınız",
                    ],
                },
                {
                    id: "ESTEEM_OPTION_6",
                    value: "həftədə 1 dəfə",
                },
            ],
        },

        MOTIVATION: {
            TITLES: [
                {
                    id: "MOTIVATION_OPTION_1",
                    name: "Motivasiyanı artırmaq üçün şəxsi təsvirləriniz",
                },
                {
                    id: "MOTIVATION_OPTION_2",
                    name: "Motivasiyanı artırmaq üçün şəxsi tövsiyələr",
                },
                {
                    id: "MOTIVATION_OPTION_3",
                    name: "İnsan-motivatorlar seçimi",
                },
            ],
            FREE: [
                {
                    id: "MOTIVATION_OPTION_1",
                    value: "30% açıq",
                },
                {
                    id: "MOTIVATION_OPTION_2",
                    value: "həftədə 1 dəfə",
                },
                {
                    id: "MOTIVATION_OPTION_3",
                    value: [
                        "ayda 1 dəfə",
                        "1 mənim üçün seçilir",
                        "1 daha çox motivasiya verir",
                    ],
                },
            ],
            GOLD: [
                {
                    id: "MOTIVATION_OPTION_1",
                    value: "tam açıq",
                },
                {
                    id: "MOTIVATION_OPTION_2",
                    value: "3 gündə 1 dəfə",
                },
                {
                    id: "MOTIVATION_OPTIONI_3",
                    value: [
                        "3 gündə 1 dəfə",
                        "1 mənim üçün seçilir",
                        "1 daha çox motivasiya verir",
                    ],
                },
            ],
            PLATINUM: [
                {
                    id: "MOTIVATION_OPTION_1",
                    value: "tam açıq",
                },
                {
                    id: "MOTIVATION_OPTION_2",
                    value: ["gündəlik 1 dəfə"],
                },
                {
                    id: "MOTIVATION_OPTION_3",
                    value: [
                        "gündəlik 1 dəfə",
                        "1 mənim üçün seçilir",
                        "1 daha çox motivasiya verir",
                    ],
                },
            ],
        },

        RELATIONS: {
            TITLES: [
                {
                    id: "RELATIONS_OPTIO_1",
                    name: "Sizin Münasibət Xüsusiyyətləriniz",
                },
                {
                    id: "RELATIONS_OPTIO_2",
                    name: "Fərdi tövsiyələr daha yaxşı münasibət üçün",
                },
                {
                    id: "RELATIONS_OPTIO_3",
                    name: [
                        "Mövcud göstəricilər:",
                        "Münasibət Uyğunluğu",
                        "İnsanlarla münasibətlər",
                        "Ümumi Agressiya",
                        "Fiziki Agressiya",
                        "Emosional Agressiya",
                        "Sosial Agressiya",
                    ],
                },
                {
                    id: "RELATIONS_OPTIO_4",
                    name: "Məşhurların Uyğunluq Müqayisəsi",
                },
                {
                    id: "RELATIONS_OPTIO_5",
                    name: "Münasibət üçün tərəfdaşların seçimi",
                },
            ],
            FREE: [
                {
                    id: "RELATIONS_OPTIOT_1",
                    value: "30% açıq",
                },
                {
                    id: "RELATIONS_OPTIO_2",
                    value: "həftədə 1",
                },
                {
                    id: "RELATIONS_OPTIO_3",
                    value: "gündə 1 dəfə təyin olunanlardan",
                },
                {
                    id: "RELATIONS_OPTIO_4",
                    value: "—",
                },
                {
                    id: "RELATIONS_OPTIO_5",
                    value: [
                        "həftədə 1",
                        "1 mənim üçün seçilib",
                        "siz alırsınız 1",
                    ],
                },
            ],
            GOLD: [
                {
                    id: "RELATIONS_OPTIO_1",
                    value: "tam açıq",
                },
                {
                    id: "RELATIONS_OPTIO_2",
                    value: "üç gündə bir",
                },
                {
                    id: "RELATIONS_OPTIO_3",
                    value: "saatda 1 dəfə təyin olunanlardan",
                },
                {
                    id: "RELATIONS_OPTIO_4",
                    value: "ayda 1",
                },
                {
                    id: "RELATIONS_OPTIO_5",
                    value: [
                        "üç gündə bir",
                        "1 mənim üçün seçilib",
                        "siz alırsınız 3",
                    ],
                },
                {
                    id: "RELATIONS_OPTIO_6",
                    value: "həftədə 1",
                },
            ],
            PLATINUM: [
                {
                    id: "RELATIONS_OPTIO_1",
                    value: "tam açıq",
                },
                {
                    id: "RELATIONS_OPTIO_2",
                    value: "gündə 1",
                },
                {
                    id: "RELATIONS_OPTIO_3",
                    value: "məhdudiyətsiz",
                },
                {
                    id: "RELATIONS_OPTIO_4",
                    value: "həftədə 1",
                },
                {
                    id: "RELATIONS_OPTIO_5",
                    value: [
                        "gündə 1",
                        "1 mənim üçün seçilib",
                        "siz alırsınız 5",
                    ],
                },
                {
                    id: "RELATIONS_OPTIO_6",
                    value: "həftədə 1",
                },
            ],
        },
    },

    TariffJunior: {
        "title-1": "Junior aktivdir",
        "title-2": "Junior",
        desc: "Junior abunəliyi 4 kategoriyada Gold planın avantajlarını əhatə edir: <br/>Peşə və öz-gerçekleşme, özünə inam və üstünlük, motivasiya və enerji, münasibətlər və ailə",
        cancelSubscription: "Abunəliyi Ləğv Et",
        confirmation: {
            title: "Abunəliyi ləğv et",
            desc: "JUNIOR abunəliyinizi ləğv etmək istədiyinizi təsdiqləyirsinizmi? Bütün avantajlar ödənilmiş müddətə qədər əlçatan olacaq",
            btn: "Abunəliyi Ləğv Et",
        },
    },
    SubscriptionPeriodLabel: {
        MONTH: "hər ay",
        ANNUAL: "hər il",
    },
    SubscriptionPeriodOptions: {
        MONTH: "aylıq",
        ANNUAL: "illik",
    },
    Tips: {
        close: "Alətlər paneilini bağla",
        prev: "Geri",
        next: "Növbəti",
        disable: "Alətlər panilini ləğv et",
        categories: {
            PROFESSIONAL: "Peşə və məqsəd",
            RELATIONS: "Münasibətlər və ailə",
            MOTIVATION: "Motivasiya və səmərəlilik",
            SELFASSESSMENT: "Özünə inam və üstünlük",
        },
        list: [
            {
                name: "auth",
                element: ".auth-form",
                title: "Telefon nömrəsi ilə qeydiyyatdan keçin",
                subtitle:
                    "Bu, yalnız sizin məlumatlara girişiniz olsun və parolu uydurmağınıza ehtiyac olmasın deyə lazımdır.",
                timeout: 2000,
                next: false,
                prev: false,
                showOn: ["desktop", "mobil"],
                hideAfterShow: true,
            },
            {
                name: "avatar",
                element: "[data-tip='avatar']",
                title: "Avatarınızı dəyişin",
                subtitle:
                    "Avatar olmadan, alqoritm sizi digər sayt istifadəçilərinə məsləhət verməyəcək.",
                timeout: 2000,
                next: "recommendations",
                prev: false,
                showOn: ["desktop", "mobil"],
                hideAfterShow: true,
            },

            {
                name: "recommendations",
                element: "[data-tip='recommendations']",
                title: "",
                subtitle:
                    "Burada sizin müxtəlif məqsədlərə kömək edəcək tövsiyələri tapacaqsınız.",
                timeout: 2000,
                next: "people-recommendations",
                prev: "avatar",
                showOn: ["desktop", "mobile"],
                hideAfterShow: true,
            },
            {
                name: "people-recommendations",
                element: "[data-tip='people-recommendations']",
                title: "",
                subtitle:
                    "Burada müxtəlif kateqoriyalarda sizə tövsiyə olunan insanları tapacaqsınız",
                timeout: 2000,
                next: "search",
                prev: "recommendations",
                showOn: ["desktop", "mobile"],
                hideAfterShow: true,
            },
            {
                name: "sphere",
                element: "[data-tip='sphere']",
                title: "İnsanların çox girişi olan 4 əsas sahə var",
                subtitle:
                    "Artıq şəkillərinizi analiz etdim və şəxsi təsviri yaratdım. Marağa uyğun kateqoriyaya basmaq qalır.",
                timeout: 200,
                next: false,
                prev: false,
                showOn: ["desktop", "mobile"],
                hideAfterShow: true,
            },
            {
                name: "inside-sphere",
                element: "[data-tip='sphere']",
                title: "Açıq və Bağlı Sfera Kateqoriyaları",
                subtitle:
                    "Sarı rəng artıq oxunmaq üçün açıq olanları göstərir. Marağa uyğun kateqoriyalara abunə olmaqla qaranlıqları açmaq mümkündür.",
                timeout: 200,
                next: false,
                prev: false,
                showOn: ["desktop", "mobile"],
                hideAfterShow: true,
            },
            {
                name: "search",
                element: "[data-tip='search']",
                title: "",
                subtitle:
                    "Axtarış qutusu vasitəsi ilə adı və ya soyadı ilə hər hansı bir Rusiyalı məşhurun və ya insanı tapa bilərsiniz.",
                timeout: 2000,
                next: "notifications",
                prev: "people-recommendations",
                showOn: ["desktop", "mobile"],
                hideAfterShow: true,
            },
            {
                name: "notifications",
                element: "[data-tip='notifications']",
                title: "Zəng Bildiriş Düyməsi",
                subtitle:
                    "Bu, sosial şəbəkədə aktiv addımlar üçün müxtəlif bonuslar barədə mesajları, eyni zamanda bir kəs sizinlə müqayisə etdik əgər bildirişləri göstərir.",
                timeout: 2000,
                next: "subscriptions",
                prev: "search",
                showOn: ["desktop", "mobile"],
                hideAfterShow: true,
            },
            {
                name: "subscriptions",
                element: "[data-tip='subscriptions']",
                title: "",
                subtitle:
                    "Burada mümkün abunəliklərin siyahısı və fəal abunəliklərinizi tapacaqsınız.",
                timeout: 2000,
                next: false,
                prev: "notifications",
                showOn: ["desktop", "mobile"],
                hideAfterShow: true,
            },
            {
                name: "multi-compare",
                element: "[data-tip='multi-compare']",
                title: "Kateqoriyaları seçin",
                subtitle:
                    "Dostlarınızla müqayisə etmək istədiyiniz bir neçə kateqoriyaları seçə bilərsiniz. Yalnız PLATINUM planı aktivləşdirdiyiniz üçün müqayisələr mövcuddur.",
                timeout: 2000,
                next: false,
                prev: false,
                showOn: ["desktop", "mobile"],
                hideAfterShow: false,
            },
            {
                name: "choose-avatar",
                element: "[data-tip='choose-avatar']",
                title: "Öz avatarınızı qurun",
                subtitle:
                    "Onda başqalarına Sizi tövsiyə edə biləcəyəm. Şəkil olmadan Sizi irəli çəkmək mümkün deyil.",
                timeout: 200,
                next: false,
                prev: false,
                showOn: ["desktop", "mobile"],
                hideAfterShow: false,
            },
            {
                name: "recommendations-modal",
                element: "[data-tip='recommendations-modal']",
                title: "Şəxsi tövsiyələr",
                subtitle:
                    "Taymer bitdikdən sonra Sizə bir tövsiyə açılacaq. Son əlçatan tövsiyəni oxumadan növbəti tövsiyə üçün taymer başlamayacaq.",
                timeout: 500,
                next: false,
                prev: false,
                showOn: ["desktop", "mobile"],
                hideAfterShow: true,
            },
        ],
    },

    WelcomeLayout: {
        ctrl: {
            "step-1": "Sualarla test",
            "step-2": "Foto test",
            "step-3": "Foto test",
            "step-4": "Pеsеkаr testi",
        },
        "back-to-main": "Ana səhifəyə geri dön",
        "back-to-main-confirm-title": "Hərəkəti təsdiqlə",
        "back-to-main-confirm-desc":
            "Ana səhifəyə geri qayıtmaqdan əminsən? Bütün irəliləyiş daimi itəcək.",
        "back-to-main-confirm-apply": "Ana səhifəyə geri dön",
    },

    WelcomeWant: {
        title: "Təbriklər, ilk addım atıldı.",
        description:
            "Mənim adım Merlin və mən sənə həqiqi olaraq lazım olanı əldə etməyə kömək edəcəm. <br/>Zəhmət olmasa nə əldə etmək istədiyini bildir:",
    },
    WelcomeTest: {
        "title-1": "Test vaxtı 15 dəqiqə",
        "title-2": "Biz sizə uyğun gələn 12 sahəni müəyyən etdik!",
        "title-3": "ƏLA! Yeni biliklər viagra 2 mərhələ qalmış",
        "subtitle-1": "İndi bir az psixoloji testə ",
        "subtitle-2": "Davam edək. Bir daha cavab verin",
        "subtitle-3":
            "{count} son sualınız şəxsiyyət növünüzə müəyyən etmək üçün. <br/> Sizin üçün uyğun olan sahələrə müəyyən etmək",
        "question-destination-1": "sual",
        "question-destination-2": "sual",
        "question-destination-3": "suallar",
        "choose-title": "Sizi ən yaxşı təsvir edən variantı seçin:",
        "next-question": "Növbəti sual",
        "quote-1": "Hər sualda özünüzü tapacaqsınız",
        "quote-2":
            "Səni razılaşdıran şeyi seçmaq və vazgeçməmək üçün gücün olmalıdır. Əks halda, ölüb daxil olmaq daha yaxşıdır. (c) Albert Camus",
        "quote-3":
            "Mükəmməl insan her şeyi özündə axtarır, heç bir əhəmiyyətli insan - başqalarında. (c) Kofuçiy",
    },
    WelcomePhotoTest: {
        title: "Nəticəyə 1 addım qalmış",
        subtitle: "PSİXOLOJİ TEST gücünüzü müəyyən etmək",
        "choose-title": "Hansı üzü daha çox bəyənirsən?",
        "next-btn": "Növbəti Şəkillər",
    },
    WelcomeCheckPay: {
        "title-success": "Ödəniş uğurlu oldu",
        "title-error": "Ödəniş səhvdir",
        "description-success": "Tezliklə növbəti mərhələyə yönəldəcəksəniz...",
        "description-error": "Davam etmək üçün ödəniş etməlisən",
        "btn-success": "davam et",
        "btn-error": "Təkrarla",
    },
    WelcomeAnalyze: {
        title: "Cavabları təhlil et",
    },

    WelcomePhotos: {
        title: "NƏTİCƏLƏRİNİZİ YAXŞILAŞDIRIN",
        subtitle:
            "Cavablarınız neyron şəbəkə alqoritmi tərəfindən analiz olunur.<br/> Məhsul dəqiqliyini artırmaq üçün şəkillərinizi yükləyin.",
        "upload-photos-title":
            "İş seçiminiz daha dəqiqli olsun deyə şəkillərinizi yükləyin",
        "next-btn": "Növbəti",
        "skip-btn": "Keçid",
    },
    WelcomeAnalyzeReady: {
        title: "Mən uyğunsuz olan 170 peşəni uzaqlaşdırdım.",
        subtitle: "10 dəqiqəyə sizin üçün ən yaxşı peşələri seçəcəyik.",
        desc: "Uyğun olmayan sahələrdə vaxtınızı itirməyin.<br/> Test işəgötürmə prosesini sürətləndirəcək<br/> Testi keçən insanların 90% sevimli peşələrini tapıb və özünü həyata keçirmişdir",
        quote: "Hər hədəf icrasını tələb edir. (c) Aristarkh Romashin",
        "start-btn": "Başlama",
    },
    WelcomeProfessionVote: {
        "title-1": "",
        "title-2": "Əla! 1150-dən {count} {decl} qaldı",
        "title-3": "Böyük! Biz yaxınıq",
        "title-4": "Yeni peşəyə vurun və biz davam edərik",
        "subtitle-1":
            "Bu dairədə mən sizin özünüzü maksimal şəkildə müəyyən edə biləcəyiniz fəaliyyət sahələrini müəyyən etdim.<br/> 1150-dən {count} {decl} qaldı. Mənə sizin üçün ən yaxşısını seçməkdə kömək edin.<br/> <br/>Sarı ilə qeyd edilmiş bölməyə vurun",
        "subtitle-2": "Sarı ilə qeyd edilmiş bölməyə vurun",
        "subtitle-3": "1150-dən {count} {decl} qaldı",
        "subtitle-4": "Yalnız 1150-dən {count} {decl} qaldı",
        "profession-declination-1": "peşə",
        "profession-declination-2": "peşələr",
        "profession-declination-3": "peşə",
    },

    ProfessionVotePopup: {
        "help-1":
            "Əla. Mən sizin cavabınızı yadda saxladım. İndi növbəti bir neçə peşəni doldurun və Hesabla düyməsini basın.",
        "help-2": "Peşənin nə olduğunu bilmiyorsanız, adının üstünə vurun.",
        "help-3":
            "Burada nəyə ilkinlik verə biləcəyinizi və bu işdə sizin hansı keyfiyetlerinizin sizə kömək edə biləcəyini yazılır.",
        "help-4":
            "Bu, sizin gözəl olduğunuz peşənin bir nümunəsidir. Xahiş olunur bunun sizin üçün nə qədər maraqlı olduğunu qiymətləndirin. <br/><br/>Qiymətlənməni fikirli olaraq edin, mən sizin cavablarınızı ən yaxşı peşəni seçmək üçün istifadə edəcəm <br/ ><br/>İlk peşəyə səs verin",
        error: "Zəhmət olmasa aşağıdakı peşələrə səs verin:",
        "next-btn": "Davam et",
        "calc-btn": "Hesabla",
    },
    OnboardDescriptionPopupRadio: {
        list: [
            {
                id: 1,
                text: "Heç vaxt maraqlandırmayıb",
                showSub: false,
            },
            {
                id: 2,
                text: "Heç vaxt sınamaq olmayıb, lakin maraqlıyam",
            },
            {
                id: 3,
                text: "Hazırki zamanda bu sahəni öyrənirəm",
            },
            {
                id: 4,
                text: "Sınadım, uğurlu olmadı",
            },
            {
                id: 5,
                text: "Mən bunu fəal şəkildə edirəm və mənə yaxşı gəlir",
            },
            {
                id: 6,
                text: "Mən bunun üzərində çox təcrübəsi olan ekspertəm",
            },
        ],
    },

    WelcomeProfPay: {
        "title-1": "1150 peşədən 3 dənə sizə uyğundur",
        "title-2": "Ödəniş üsulunu seçin",
        "desc-title":
            "{count} nəfər sevimli işlərini tapdılar və gəlirlərini artırdılar",
        desc: "Bu testi keçənlərin 87%-i onu sevdiklərinə tövsiyə edir <br/>Test ölkədəki 38 öncül şirkətin top meneceri tərəfindən istifadə olunur <br/><br/>Peşədə uğurlu olmaq üçün şəxsi məsləhət alın",
        "open-btn": "990 rublə açın",
        motivation: "Siz artıq 15 dəqiqə sərf etdiniz, son addım qalmışdır!",
        "recreate-text": {
            btn: "Testi yenidən keçin",
            "confirm-title": "Testi yenidən başlamaq?",
            "confirm-desc":
                "Testi düzgün keçmədiyinizi düşünürsünüzsə, yenidən başlaya bilərsiniz. Diqqət: keçilmiş testin irəliləyişi həmişəlik silinəcək.",
            "confirm-btn": "Testi yenidən keçin",
        },
        partners: {
            title: "Tərəfdaşlarımız tərəfindən təsdiq edilib:",
            list: [
                "Rusiya Federasiyasının Təhsil Nazirliyi",
                "İnnovasiya İtdiyanı Fondu",
                "B20 İndoneziya 2022",
                "Skolkovo",
                "Merlin AI - Telegramın rəsmi tərəfdaşı",
            ],
        },
        paymentMethods: {
            all: "Bütün ödəniş üsullarını göstərin",
            phone: {
                title: "1. Telefon nömrəsi ilə",
                copy: "kopiyala",
                copied: "kopiyalandı",
                "copy-number": "Nömrəni kopiyala",
            },
            link: {
                title: "2. Link ilə",
                btn: "Linkə keçin",
            },
            kassa: {
                title: "3. Robokassadan keçirərək",
                btn: "Ödəniş edin",
            },
            qr: {
                title: "4. QR kod ilə",
                btn: "Seçin",
            },
        },
        "after-pay": "Ödənişdən sonra, ödənişi yoxlamaq üçün kutunu yoxla",
        "after-pay-label": "Ödənmiş 499 rubl",
        "after-pay-btn": "Davam et",
    },

    WelcomeProfessions: {
        title: "Sizə ən çox yönələcəyiniz sahəni müəyyənləşdirdik <br/>",
        "title-1": "Əla!<br/>Sizin üçün ən uyğun peşələri müəyyənləşdirdik",
        "title-2": "Əla!<br/>İndi",
        "title-3": "Əla!<br/>İndi",
        "subtitle-1":
            "<b class='gold'>Daha çox</b> maraqlanan bir peşəyə klikləyin",
        "subtitle-2":
            "Daha çox əlaqə - daha uyğun sahədir<br><br>Bir sahəyə klikləyin və uyğun peşələri görəcəksiniz",
        "title-helper": "bu sahənin peşələri",
        these: "Bunlar",
        "merlin-help":
            "Mən sizə onları öyrədəcəyəm kömək edəcəm. Merlinface-də artıq bunu yaxşı bacaran mükəmməl adamlar var. Sizi onlarla tanış edəcəm. <br/><br/>Amma indi mənim daha bir işim var...",
        "more-btn": "Öyrən",
        "next-btn": "Davam et",
    },
    WelcomeBusinessCardInfo: {
        "info-title": "Özünəzdən bir neçə məlumatı doldurun",
        "edu-title": "Təhsil məlumatlarınızı doldurun",
        "career-title": "İş məlumatlarınızı doldurun",
        subtitle:
            "Bu məlumatlar sizin səhifənizi ziyarət edən digər istifadəçilər tərəfindən görüləcək. Bu, potensial iş tərəfdaşınızı daxil edə bilər",
        "all-fields-error": "Bütün sahələr doldurulmalıdır",
        "edu-fields-error": "Ən azı bir təhsil yeri əlavə edin",
        "career-fields-error": "Ən azı bir iş əlavə edin",
    },
    WelcomeBusinessCardPay: {
        loading: "Yüklənir...",
    },

    WelcomeStart: {
        title: "Profilinizi diqqətlə doldurun!",
        subtitle: "Məlumatlar dəqiq olduğu qədər, nəticə də o qədər dəqiqdir",
    },
    WelcomePersonInfo: {
        title: "Çox yaxşı, ",
        subtitle: "Özünüz haqqında bir az məlumat daxil edin:",
        age: "yaş",
        "age-placeholder": "Nəçə yaşınız var?",
        "age-error": "14 və 99 yaş arasında yaş daxil edin",
        "choose-country": "Ölkə seçin",
        "choose-city": "Şəhər seçin",
        "country-placeholder": "Ölkə ilə axtarış",
        "city-placeholder": "Şəhər ilə axtarış",
        "city-no-options":
            "Seçilmiş ölkədə axtarış üçün şəhərin adını daxil edin",
        "social-title": "Sizin sosial statusunuz:",
        "social-error": "Sosial statusu seçin",
        "next-btn": "Davam et",
    },
    WelcomeTestComplete: {
        title: "Mən Psixotipinizi Müəyyən Etdim",
        "your-type": "Saytımızda sizin psixotipi olan {count} {dec} var",
        "your-partners":
            "{count} {decl} sizinlə yüksək iş uyğunluğuna malikdir.",
        celebrity:
            "{count} məşhur və sizin şəxsiyyət tipinizdə  uğurlu insanlar.",
        "next-btn": "Davam et",

        typeDescriptions: {
            Vasitəçi:
                "Sizin doğal bacarıqlarınız sizi böyük bir vasitəçi edir. Fərqli insanlarla müştarək zəmin tapıb, münaqişələri həll etmək bacarığınız var. Başqalarının ehtiyaclarını dinləyərək anlamağa bacarığınız sizi hər hansı bir komandada dəyərli bir üzv edir. Etraflılığınıza və harmoniya istəyinə baxmayaraq, sizin münasibətləriniz güclü və məhsuldar olur.",
            Ünsiyyətçi:
                "Sizinlə fərqli bir kommunikasiya hədiyyəniz var. Bacarığınız sizə effektiv kommunikasiya etməyə, düşüncələrinizi və fikirlərinizi ifadə etməyə imkan verir, həmçinin digərlərinin baxışlarına hörmət göstərir. Kommunikasiyanı asan və zövqlü hala gətirə bilən bacarığınız sizi peşəkar səviyyədəki çətinliklərə qarşı qoyur.",
            Bağlayıcı:
                "Siz böyük bir birləşdirici, insanları və ideyaları birləştirə bilənisiniz. Sizin sosial tənzimləbilmə bacarığınız və başqalara diqqət etmək sizə peşəkar şəbəkələrin mərkəzində olmağa imkan verir. Təşkilatçılıq bacarığınız sizin uğurlu komandalar və layihələr qurmağa kömək edir.",
            Hedonist:
                "Peşəkar həyatdadakı sevinc və zövqü tapırsınız. Bacarığınız sizə işinizi zövq alarak etməyə və əla nəticələr əldə etməyə imkan verir. Siz rahatlıq, harmoniya qiymət verir və iş prosesinizi mümkün qədər zövqlü və effektiv etməyə yollar axtarırsınız.",
            "Fəth edən diplomat":
                "Sizin kommunikasiya və təsir etməyə haqq qazanmaqda birbaşa hədiyyəniz var. Sizin bacarığınız sizə bütün mühitlərdə müştarək zəmin tapmağa imkan verir və işləri görə bilirsiniz ki, bu sizi peşəkar sferada müəyyən dərəcədə effektiv edir. Siz nailiyyətə və uğura yönəlibsiniz və liderliyi və müsbət təsiri arzulayasınız. Eyni zamanda, siz layiqli və zərif bir şəkildə müzakirə edə bilərsiniz ki, bu sizə ən mürəkkəb sınaqlarla üzləşməyə imkan verir.",
            Lobbiçi:
                "Sizin ənənəvi təsir etmə və səsləndirmə bacarığınız var. Sizin bacarığınızız sizin ətrafınızdakıları qazanmaqda, onlarla əlaqələr qurmaqda və məqsədlərinə çatmaqda sizə yardımcı olur. Sizin prosesləri idarə etmə, strategiyaları inşa etmə və maraqlarıni qorumaq bacarığınız var. Təsir etmə bacarığınız sizi hər sektorun öz varlığı edir. Sizin təşəbbüs və təşkilatçılıq bacarığınız sizə çətin mühitlərdə uğurlu işləməyə imkan verir və maneələri dəf etməyə yardımcı olur.",
            Siyasətçi:
                "Rəhbərlik və təsir etmə bacarığınız sizi siyasət meydanında uğurlu olmağa imkan verir. Siz önəmli qərarlar qəbul edə bilir, münaqişələri həll edə bilir və fərqli baxışlara sahib insanlarla müştarək zəmin tapa bilirsiniz. Sizin həyatla aktiv yanaşmağınız, dəyişikliyə istəyin və başqalarını fikirlərinizə cəlb etmə bacarığınız sizi effektiv bir siyasətçi edir. Sizin sosiallığınız və özünüzə inamınız sizə məqsədlərinizi nail olmağa ve dəyişikliklərə mənasiz töhfələr etməyə imkan verir.",
            Materialist:
                "Siz maddi refahı dəyərləndirirsiniz və nəticələr üçün işləyə bilərsiniz. Sizin realistliyiniz və məqsədlilik hissiniz sizi həmişə ayaqda saxlayır. Siz resursları idarə etməyə, planlaşdırmağa və planlarınızı effektiv icra etməyə qadirsiniz. Sizin təşkilatçılıq, optimallaşdırma və qərar vericilik bacarıqlarınız sizi hər hansı bir komandada və ya layihədə əvəzsiz edir.",

            Mühafizəkar:
                "Bacarıqlarınız və məharətləriniz konservator kimi rolu mükəmməl bir şəkildə yerinə yetirir. Siz sabitliyi, sıralı düzeni və ənənəni qiymətləndirir və bu dəyərləri istənilən peşə sahəsinə gətirə bilərsiniz. Sizin təşkilatçılıq və sistemləşdirmə qabiliyyətiniz var, həmçinin detallara diqqət məhdudiyyətini qoruyursunuz. Məqsədlərin həyata keçirilməsində səyləriniz və davamlılığınız möhkəm və sabit strukturlar yaratır.",
            Təbəə: "Birləşmə sazişı yaratmaq kimi rola mükəmməl olaraq uyğunsunuz. Bacarıqlarınız və məharətləriniz ortaq məqsədlərin həyata keçirilməsinə sədaqət və etibarlılığınızı təmin edir. İşləyiciliyiniz, reaktivliyiniz və komanda işi bacarıqlarınız sizi hər hansı bir layihədə əvəzsiz kontribyutor edir.",
            Sosiallaşdırıcı:
                "İnsanları cəlb etmə və güclü sosial bağlar yaratma qabiliyyətiniz sizi ideal sosiallaşdırıcıya çevirir. Kommunikasiya və sosial təmas sahəsindəki bacarıqlarınız iş şəbəkələrinin formalaşdırılmasına və dəstəklənməsinə kömək edir ki, bu da işinizin effektivliyini kəskin şəkildə artırır.",
            Qoruyucu:
                "Digərlərinə baxmaq və sıralı düzen qorumaq bacarığınız sizi idealdan artıq bir mugənni edir. Bacarıqlarınız dəyərləri və resursları qorumağa imkan verir, həmçinin məsuliyyət və disiplin təminatı olaraq hər hansı bir durumda stabillik və etibarlılığı təmin edir.",
            Tədbirber:
                "Bacarıqlarınız və məharətləriniz əyləncəni təşkil etmək rolu üçün əsaslı bir şəkildə uyğundur. İnsanları qaldırmaq, təşkil etmək və maraqlı fikirlərlə angaje etmək bacarığına maliksiniz. Tez fikirləşir, yaradıcılığınız və həvəskarlığınız sizə tez yeni vəziyyətlərə uyğunlaşmağa və yüksək motivasiya səviyyəsi ilə çətinliklərlə üzləşməyə imkan verir. Enerji və empatiyanız sizi hər hansı bir komandanın sevimli üzvü edir.",
            Təşkilatçı:
                "Doğal təşkilatçılıq və planlaşdırma bacarığına maliksiniz. İletişim və resurs idarə etmə bacarıqlarınız insanları və tapşırıqları effektiv bir şəkildə koordinasiya etməyə imkan verir. İşi belə təşkil etməyi bilirsiniz ki, bu yalnız məhsuldar olmayıb, həm də hər kəs üçün zövq alınandır. Liderlik bacarıqlarınız və komanda ruhunu qorumaq qabiliyyətіniz hər hansı bir layihədə hayati bir element edir.",
            Optimist:
                "Optimizm və münfi baxış zənginliyi sizə hər hansı bir peşə sahəsində üstünlük verir. Hər hansı bir vəziyyətin açıq tərəflərini görmək və digərlərini sevindirmək bacarığınız səbəbindən tez həll tapa və çətinliklərlə üzləşə bilirsiniz. Həvəskarlığınız və cana sözünüz komandaya müsbət enerji gətirmək və ümumi performansı artırmağı mümkün edir.",
            Aktivist:
                "Enerji, əngagman və insanları təsir etmə bacarığınız sizin ideal aktivist edir. Prosesdə yalnız fəal iştirak etmək bacarığına yox, həm də digərləri daxil etmək imkanınıza maliksiniz. İletişim və təşkilatçılıq bacarıqlarınız ümidlərinizi uğurla təşviq etmək və məqsədlərinizi həyata keçirməği mümkün edir. Həvəskarlığınız və özünüzə etimad insanları təşviq edir və böyük nəticələr əldə etmənizə imkan verir.",
            "İdeya ilhamverən":
                "Dünyaya daha geniş bir kontekstdə baxmaq və problem həlli yollarına yaradıcı yanaşma bacarığınız sizi ideal bir ilham vericisi edir. Fikirləriniz içərisindəkilerin ürəklərində bir kıvılcım yandırabilir, onları yeni şeylər etməyərək səsləndirir. İntuisiyanız və fırsatları ayırt etmək bacarığınız sizin trendləri qabaqcadan proqnozlaşdırmağa və innovativ həllər yaratmağa imkan verir.",
            Romantik:
                "Dünyanı dərin hiss etmək və dərk etmək sizə həqiqi bir romantik edir. Adi şeylərdə gözəlliyi görmək və hisslərinizi işinizə çevirmək bacarığına maliksiniz. Başqalarını ilhamlandırmaq və ilham almak yeni ideyalar və baxış açıları üçün əsas ola bilər.",
            Hömərəçi:
                "Gözəlliyi və harmoniyı qavramaq və yaradıcı ideyaları dəstəkləmək və inkişaf etdirmək bacarığınıza maliksiniz. Başqalarına kömək etmək, onların yaradıcılığını dəstəkləmək və incəsənətlərə hörmətiniz sizi ideyal incəsənət patronu edir.",
            Xəyalpərəst:
                "İdeyalar yaratmaq, dərin hisslərinizdən zerbe çəkmək və geniş şəkildə düşünmək bacarığınız sizi mükəmməl bir xəyalpərəst edir. Xəyal etməkdən və ideyalarınızı uçurmaqdan qorxmursunuz. İlhamınız və təsəvvür qabiliyyətiniz sizə çabaladığınız hər yerde perspektivlər və yeni imkanlar görəcək.",
            "İdeya lideri":
                "Yeni ideyalar yaratmaq və tətbiq etmək, eləcə də başqalarını ilhamlandırmaq və motivasiya etmək bacarığınız sizi ideal fikir lideri edir. Siz adi olanın ötesine baxa bilir və özünüz və komandanız üçün yeni ufuklar aça bilərsiniz. İrəliləmək düşüncəli, yaradıcı yanaşmanız və yeniliyə ac gözlərle baxmaq sizin ardınızdan gələnlər üçün hərəkət edən enerji olur.",
            "İmaj yaradan":
                "Yaradıcı vizyonunuz və tiz stil hissi sizə insanları təsir altında saxlayan şəkillər yaratmaq və qorumaq imkanı verir. İdeyaları və dəyərləri xarici görüntü vasitəsilə ifadə etməkta olan eşsiz bacarığınıza maliksiniz, güclü və xatırlanacaq təəssüratlar yaratmağa. Gözəllik hissi, yeni trendlərə uyğunlaşma bacarığı və vizual stile yaradıcı yanaşmanız sizi hər komandanın ayrılmaz hissəsi edir.",
            "İdeya adamı":
                "Orjinal ideyalar yaratmaq və inkişaf etdirmək bacarığınız sizi mükəmməl bir ideya adamı edir. Problemləri həll etmək üçün yaradıcı yanaşmanız, kutu xaricində düşünmək və başqalarının maneələr gördüyü yerlərdə imkanları görmək sizə yenilikçi həllər yaratmaq və onları təcrübəyə çevirmək qabiliyyəti verir. İdeyalarınız başqalarına ilham verir və dəyişiklikləri sürükləyir.",
            Təşəbbüskar:
                "Enerjiniz və dəyişikliklər üçün istəyin sizə ideal başlatıcı edir. Yeni layihələri başlatmaq, fərqli ideyaları birləşdirmək və insanları ortaq məqsəd doğru aparmaq bacarığınıza maliksiniz. Maneələrə baxmayaraq yeni bir şeyləri başlatmaq və yeni ideyalara açıq olmaq bacarığınız hər hansı bir layihədə, xüsusilə yenilikçi yanaşmalar tələb edənlərdə, sizə dəyərsiz edir.",
            Vaiz: "İnancınız, digərlərinin emosiyanal vəziyyətinin incəliklərinə həssaslığınız və dünyanı daha yaxşı bir yer etmək arzunuz sizi ideyal vaiz edir. Başqalarına kömək etmək həvəsiniz və onların hissləri ilə dərin bağlantınız sizə insanlarla effektiv şəkildə əlaqə saxlamağa imkan verir və onlar üzərində önəmli təsir etmək. Siz şeylərin özünə baxır və fikirlərinizi və ideyalarınızı açıq şəkildə ifadə edirsiniz.",
            Moralist:
                "Dərin etik şüurlanmanız və doğruluq və ədalət istəyiniz sizi ideyal moralist edir. İnsan doğasını anlamaq, dəyərlərə və prinsiplərə hörmət etmək və ətraf dünyanı özünüzü inkişaf etdirmə arzunuz sizə hər komanda və təşkilatda dəyərli edir.",
            İlhamverən:
                "Başqalarını ilhamlandırma və motivasiya etmə qabiliyyətiniz, eləcə də ətraf mühitə qarşı empatik münasibətiniz sizi ideal ilham mənbəyi edir. İnsan təbiətini dərindən anlama və həssaslıq qabiliyyətiniz, insanlara təsir etməyə və onlara öz potensiallarını açmaqda kömək etməyə imkan verir. Empatiya qabiliyyətiniz və diqqətlə dinləmək özelliğiniz sizi hər hansı komandada və ya təşkilatda əvəzolunmaz edir.",
            Müəllim:
                "Öyrənmək həvəsiniz və insan doğasını dərin dərk etmək sizi ideal bir tərbiyəçi edir. Məsələlərin özünə daxil olmaq və həll yollarını təklif etmək, eləcə də başqalarına öz bacarıqlarını aşkar etmək və inkişaf etdirmək üçün kömək etmək bacarığınıza maliksiniz. Sabırlı, diqqətli və həssas olma bacarığınız şagirdlərinizin hörmət və etibarını əmin edir.",

            "Emosional lider":
                "Sizin emosional həssaslığınız, digərləri üzərində güclü təsiriniz və yaxın əlaqələr qurmağa bacarığınız sizi ideal emosional lider edir. Ətrafınızdakı insanların emosiyalarını özünüzdə əks etdirə və komandanızı dəstəkləyərək və motivasiya edərək, onların məhsuldarlığını və razılıq səviyyəsini artıra bilərsiniz.",
            Həvəsləndirici:
                "Sizin enerjiniz, açıqlığınız və optimizminiz sizə ətrafınızdakı insanları ilhamlandırmaq və motivasiya etmək imkanı verir. Siz işinizi asanlıqla həyəcanlandırır və müsbət emosiyalarla doldurursunuz. İşıqlı gələcəyi görə və onu başqaları ilə paylaşmağa bacarığınız sizi hər komandada əvəzsiz üzv edir.",
            Müşavir:
                "Sizin digərlərinin ehtiyaclarına həssas oluşunuz və insanlara yanaşma bacarığınız sizi ideal mentor edir. Səbirliyiniz, empatiyanız və öz bilik və təcrübənizi ötürmə bacarığınız digərləri məşğul etməyə və onların məqsədlərini nail olmaqda sizə kömək edir.",
            Artist: "Sizin yaradıcılığınız, emosional ifadəniz və unikal dünya baxışınız sizi ideal rəssam edir. Özünüzü ifadə etmə və yaradıcı düşünmə bacarığınız, həmçinin sizə ətrafdakı gözəlliyi görmə və onu başqalarına çatdırmağa imkan verir, etrafınızdakı unikal və təsirli işlər yaratmağa imkan verir.",
            Usta: "Sizin dəqiq yönəlməniz, praktiki düşünməniz və problem həll etmə bacarıqlarınız sizi mükəmməl ustalar edir. Siz hər bir təfərrüatı diqqətlə araşdırır, işinizdə maksimum keyfiyyətə çatmağa çalışırsınız. Əllərinizlə işləmə, təkmilləşdirmə və şeyləri təmir etmə bacarığınız bir çox peşələrdə çox dəyərlidir.",
            Optimallaşdırıcı:
                'Sizin effektivlik üçün həvəsiniz, praktiklik və rəcələnəçi həllər tapmaq bacarığınız sizi ideal optimizator edir. Siz həmişə prosesləri təkmilləşdirmək üçün yollar axtarırsınız, işi daha asan və effektiv etmək üçün. Ətrafınızda "böyük şəkli" görə və mürəkkəb sistemləri analiz edib optimallaşdıra biləcəyiniz sözünüz əsas varlığınızdır.',
            Texnik: "Sizin texniki şüurluğunuz, analitik düşünmə bacarığınız və mürəkkəb sistemləri başa düşmə bacarığınız sizi ideal texnik edir. Siz texnologiyayla işləmək, onun necə işlədiyini anlamaq və onu praktiki problemləri həll etmək üçün istifadə etmə bacarığınıza maliksiniz. Sürətli öyrənmə və yeni texnologiyalara uyğunlaşma bacarığınız böyük üstünlük təşkil edir.",
            Bacarıqlı:
                "Elinizin tərbiyəsi, praktiklik və problem həll etmək üçün yaradıcı yanaşmanız sizi ideal handyman edir. Elinizlə yaratmaq, təmir etmək və işləri təkmilləşdirmə bacarığınız var. Detallara diqqətiniz, materiallarla və alətlərle işləmə bacarığınız, və yeni və faydalı bir şey yaratmaq sevginiz sizin güclülüyünüzdür.",
            "Hücum edən fəth edən":
                'Sizin müəyyənlik, tez qərar verme və təsirli liderlik bacarıqlarınız sizi ideal "təkidli zuğaltdıcı" edir. Siz fırsətləri digərləri tərəfindən maneələr kimi görülən yerlərdə görürsünüz və həmişə çətinliklərə qarşı qoymağa hazırsınız. Siz komandanızı məqsədlərə nail olmaq üçün motivasiya edir və ilham verirsiz, hətta bu böyük səyl və risk tələb edərsə də.',
            Lider: "Sizin cəsarətiniz, öz-əminliyiniz və insanları mobilizə etmə bacarığınız sizi ideal lider edir. Siz həmişə öz qərarlarınıza əminsiniz və komandanızda etimad yaratmağa necə bacardığınıza bilirsiniz. Doğal liderliyiniz, cəsarətiniz və çətin vəziyyətlərdə məsuliyyət daşımğa bacarığınız sizin əsas keyfiyyətlərınızdır.",

            İdarəetmə:
                "Sizin zədələnməzliyiniz, komanda işi bacarıqlarınız və fiziki davamlılığınız sizi mükəmməl leqioner edir. Sizin irədəniz, pozma bacarıqlarınız və qalib gəlmək istəyi hər zaman məqsədlərinizə nail olmağınıza kömək edir. Həmişə öndə olmaq arzunuz və ən çətin şəraitdə belə öz yerinizdə möhkəm dayanmaq bacarığınız sizi digərlərindən fərqləndirir.",
            Legioner:
                "Sizin qayılmazlığınız, komandada işləmə bacarığınız və fiziki dözümlülüyünüz sizi ideal legioner edir. Hərəkətə təşəbbüsünüz, çətinliklərlə üzləşmə bacarığınız və qələbə iradəniz öz məqsədlərinizə çatmağınıza kömək edir. Həmişə ön sıralarda olmaq istəyiniz və ən çətin şərtlərdə belə öz mövqeyinizdə qalmaq bacarığınız sizi digərlərindən fərqləndirir.",
            İnspektor:
                "Dəqiq və detallı təhlil bacarıqlarınız, qayda və sistematizasiya sevginiz, davamlılıq və proqnozlaşdırılabilirlik sizi yaxın nəzarət və imtahan tələb edən rollar üçün ideal edir. Kritik düşünmə və standartlara və prosedurlara əməl etmə bacarığınız yüksək keyfiyyətli işin təminatıdır.",
            Nəzarətçi:
                "Bəsit şəraitdə sakit qalmaq bacarığınız, detallara diqqətiniz və mükəmməlliği sevginiz sizi mürəkkəb prosesləri uğurla nəzarət etmək və idarə etmək üçün ideal edir. Qaydaların və standartların izlənilməsi ilə mükəmməl başa çıxırsınız ki, bu da layihələrinizin effektivliyi və təhlükəsizliyinə töhfə verir.",
            Stabilizator:
                "Dəyişikliklərə qarşı davamlılıq, etibarlılıq və məqsədlə bağlı olmağınıza olan öhdəliyiniz hər hansı bir komanda vəya layihədə sabitlik və davamlılığı təmin edir. Siz qayda və nizamı təşkil etmək və qorumaqda, eləcə də sabitlik və proqnozlaşdırılabilirliyi arzu etməkdə bacarıqlısınız, bu da sizi hər sahədə dəyərli komanda üzvü edir.",
            Mühəndis:
                "Sistem və analitik düşünmə bacarıqlarınız, problemləri həll etmədə yaratıcı yanaşmanız və detallara olan sevginiz sizi mühendislik və texniki rol üçün ideal namizəd edir. Mərifətlə işləmək, mürəkkəb sistemləri anlama və yaxşılaşdırma və yeni texnologiyalara öyrənmə bacarığı texniki sahədəki uğurunuzun təminatıdır.",
            İdarəçi:
                "Təşkilatçılıq, planlaşdırma və menecment bacarıqlarınız, həmçinin effektiv kommunikasiya və vəzifələri yönəltmə bacarığınız sizi ideal menecer edir. Komandaları koordinasiya etmək, resursları idarə etmək və hətta qeyri-müəyyənlik şəraitində də məqsədləri uğurla başa çatdırmağa qadirdir.",
            Praktik:
                "İşə el yönümlü yanaşmanınız, real imkanları görmək və ələ almağınız, həmçinin konkret və ölçülə bilinən nəticələri sevməniz gərçək amil olarağınızı nümayiş etdirir. İdeyaları həyatda, praktiki addımları tətbiq etmək və real hərəkət etmək bacarığınızdır asıl varlıqlarınızdır.",
            "Həyata keçirən":
                "Planları effektiv şəkildə icra etmək bacarığınız, məqsədlə bağlı öhdəliyiğiniz və işə el yönətmlü yanaşmanız sizi ideal icracı edir. Fikirləri həyatda və konkret nəticələri nail etməkdə bənzərsiz bacarığınız hər hansı bir təşkilatda önəmlidir.",
            Administrator:
                "Organizasiya bacarıqlarınız, detallara diqqət və multi-taskingin koordinasiyasını etmək bacarığınız sizi mükəmməl administrator edir. Resurslarını idarə etmək, nizam və orderi təşkil etmək və İdarəetmə proseslərinin effektiv olmasını təmin etmək bacarığınız sizin peşəkar uğurunuzun əsasını qoyur.",
            "Boz kardinal":
                "Strateji düşünmək, analizə meyilli olmağınız və bütün potensial senariləri hesablamaq bacarığınız sizi 'qara qartal' roluna uyğun namizəd edir. Senin arxa fondan, qərar verme prosesini təsirləndirə bilən və görünənə kömək edə bilərsiz.",

            Proqnozlaşdırıcı:
                "Detallara diqqətin, analitik düşünüşün və gələn trendləri proqnozlaşdırma bacarığın səni mükəmməl bir proqnozlaşdırıcı edir. Böyük şəkli görmə, məlumatları analiz etmə və gələcək hadisə və trendləri gözləmə bacarığın uğurlu strateji planlaşdırmanın əsasını təşkil edir.",
            Skeptik:
                "Kritik düşünmə, analiz və skeptik olma bacarığın səni yaxşı bir skeptik edir. Sən həmişə qurulmuş baxışları və fikirləri sual altına almağa, onları fərqli açılardan analiz etməyə və zəifliklərini tanımağa hazırsan.",
            Tənqidçi:
                "Məlumatları kritik şəkildə dəyərləndirə və analiz etmə, həmçinin keyfiyyət və dəqiqlikə olan həvəşin səni ideal bir tənqidçi edir. Sən qüsurları tapmaq və təkmilləşdirmələr təklif etmək bacarırsan, bu da daimi inkişaf və mükəmməliyyət təqibində kömək edir.",
            "İnnovator həvəsləndirici":
                "Yorulmaz enerji, yeniliklərə həvəşin və kəşf etmək həvəsinin səni həvəli bir yeniləşdirici kimi ayrılmaqdadır. Sən həmişə yeni fikirlər axtarır, eksperimentlərə açıq və riskləri götürməyə hazırsan ki, yeni və böyük nələr dərhal tətbiq edəsən. Sənin qarşısını alma, ənənəvi sınırları yenidən düşünmə və ənənəvi sınırlardan ötərə getmə bacarığın sənə aşağıdakıları gətirir. Sən daimi komanda üzvüsü olduğun hər hansı bir komandaya canlılıq və novatorluq gətirir, və fikirlərin çox vaxt yenilik mənbəyidir.",
            Araşdırıcı:
                "Mənəvi düşünmə, özünü inkişaf etdirməyə maraq, və məsələləri dərindən analiz etmə bacarığın səni həqiqi bir tədqiqatçı edir. Biliklərinə olan susuzluğun və doğru sualları vermək bacarığın məsələlərin və problemlərin daha dərinləşdirə bilən bir anlama gətir. Digərləri başarısız olduqları məqamlarda əlaqələr axtarmaq bacarığına maliksin, bu da sənə yeni strategiyalar və yanaşmalar inkişaf etmək bacarığını verir. Tədqiqat bacarıqlarınız və işlərin özünə daxil olma həvəşiniz inkişaf və yeniliklər üçün əsasdır.",
            Yenilikçi:
                "Sizin daimi yenilik arzunuz, qeyri-standart düşünmə meyliniz və status-kvonu meydan oxumağa hazır olmağınız sizi əsl yenilikçi edir. Siz sadəcə olaraq şeyləri olduğu kimi qəbul etmirsiniz, həmişə yeni yanaşmalar və daha yaxşı həllər axtarırınız. Digərləri maneə görərkən, siz imkanlar görürsünüz və yenilik etmə qabiliyyətiniz komandanızı və ya təşkilatınızı uğura aparmağa kömək edir.",
            Generator:
                "Sizin yaradıcılığınız, sürətli fikir formalaşdırma qabiliyyətiniz və daimi yenilənməyə və irəliləməyə olan meyliniz sizi əla yaradıcı edir. Sizin fikirləriniz tez-tez dəyişikliklər üçün katalizator olur və yaradıcılığa olan meyliniz komandadakı yenilikçi mədəniyyəti dəstəkləməyə kömək edir. Siz digərləri tərəfindən görülməz olan imkanları fərq etmək üçün bənzərsiz bir qabiliyyətə sahibsiniz və bu qabiliyyətiniz hər hansı təşkilatın və ya layihənin inkişaf və tərəqqisi üçün bir katalizator ola bilər.",
            Analitik:
                "Şeyləri analiz etmə və parçalara bölmə bacarığın səni əvəzsiz bir analitik edir. Sən həmişə hər elementin böyük şəkli necə təsirləndirdiyini görürsən, və məlumat və məlumatları obyektiv şəkildə qiymətləndirmə bacarığın sənə məlumatlandırılmış və məlumatlandırılmış görüşlər təklif etməyə imkan verir. Analitik bacarıqların təşkilatlara məlumatlara əsaslanan qərarlar verməyə və effektiv strategiyalar inkişaf etməyə kömək edir.",
            Sistematizator:
                "Sifariş, struktur və sistematizasiya üçün olan maraq səni mükəmməl bir sistemləşdirici edir. Məlumatları sıraya qoy, əlaqələr qur və aydın və lojik sistemlər yaratma bacarığın sənə qaosu strukturə çevirmə bacarığını verir. Bu, təşkilatlara proseslərini təkmilləşdirməyə, effektivliyi artırmağa və məhsuldarlığı artırmağa kömək edir.",
            Perfeksionist:
                "Səhvliklərin, diqqətlilik və detalağına olan öhdəlik səni mükəmməl bir mükəmməllikçi edir. İş ən yaxşı şəkildə edilənə qədər dayanmırsan. Yüksək standartlarınız və keyfiyyətə doğru dözümlü təqib, bütün layihələrinizə təsir edir ki, bu da yüksək keyfiyyətli işin dəqiqləşdirilmiş nəticələri ilə sona çatır.",
            Rasionalaziyor:
                "Analiz etmə və optimallaşdırma bacarığın səni böyük bir yeniləşdirici edir. Sən həmişə işləri daha səmərəli və effektiv etmək üçün yollar tapırsan. Optimallaşdırma üçün həvəşin və qarışıqlığı sadə hale gətirmə bacarığın təşkilatlara effektivliyi artırma və xidmət vəya məhsullarının keyfiyyətini artırmağa kömək edir.",
            "Yenilikçi sahibkar":
                "Böyük şəkli görmə, başqalarının tapmadığı imkanları tapma və yeni fikirlərə açıqlığın səni girişimci bir yeniləşdirici kimi təyin edir. Riskləri götürməkdən qorxmursan, və arzuların və enerjin fikirlərini real hala çevirməyə kömək edir. Girişimci düşünmə mənsəbin hər hansı bir təşkilatda və ya layihədə inkişaf və yenilik üçün katalizator ola bilər.",
            Alqoritmcı:
                "Məlumatları emal etmə, alqoritmlar yaratma və bilikləri təşkil etmə bacarığın səni xüsusi bir alqoritmika edir. Sən hər prosesə məntiq və struktur gətirirsin, bu da təşkilatlara daha effektiv və proqnozlaşdırılan şəkildə işləməyə imkan verir. Alqoritm-mənbəli düşünüşün məhsuldarlığı və işin keyfiyyətini artırmağa gətirir.",
            "Inteqrasiya edən":
                "İnsanlar, ideyalar və resursları birləşdirmə bacarığın səni bir integrator edir. Sən fərqli elementlərin nə təsirli şəkildə bir-birinə təsir etdiyini və bir-birini təsirləndirdiyini görməyə ixtiyarın var, bu da sənə daha güclü və daha effektiv komandalar inşa etməyə imkan verir. Birləşdirməyə öhdəliyin təşkilatlar sinerji yaratma və performanslarını təkmilləşdirməyə kömək edir.",
            Eksperimentalçı:
                "Hipotez test etməkdə, faraziyyə test etməkdə və daimi inkişafda olan həvəşindən səni məhir bir eksperimentator edir. Səhv edirdiyindən qorxmursan və onları öyrənmək üçün fırsat kimi görürsən. Eksperiment etmə və daimi dəyişikliklərin üzərinə səbr etmə bacarığın təşkilatlara dəyişən müqavimət və uyğunlaşmada kömək edə bilər.",
        },
    },

    WelcomeSubscribe: {
        title: "Mən sizi təbliğ edəcəm, seçdiyiniz sahələrdə uğur əldə etməyə və pul qazanmağa kömək edəcəm",
        subtitle:
            "Profilinə keçmədən əvvəl - indi sən üçün böyük təklif var<br/><br/> Nə daxildir:",
        pay: "Hər şeyə {price} RUB/aya erişin",
        "to-profile": "Profile keç",
        list: [
            {
                id: 1,
                icon: "people-recommendations",
                text: "Hər gün sizə saytdaki 5 istifadəçi mükəmməl biznes uyğunluğu olan biri olaraq tövsiyə ediləcək.",
            },
            {
                id: 2,
                icon: "text-recommendations",
                text: "Psixotipinizin dərin analizini və deşifrirləməsini əldə edin",
            },
            {
                id: 3,
                icon: "brain",
                text: "Şəxsiyyətinizin və davranışınızın təsvirini əldə edin.",
            },
            {
                id: 4,
                icon: "environment",
                text: "Bu işdə inkişaf etmek üçün psixotipiniz üçün 10-dan çox tövsiyə",
            },
            {
                id: 5,
                icon: "stars",
                text: "Sizin şöhurətlər verilənlər bazasına girişiniz olacaq və onlarla şəxsi olaraq görüşmək, bir işbirliyinə başlamaq və ya xarakterləri barədə öyrənmək imkanınız olacaq.",
            },
            {
                id: 6,
                icon: "photo",
                text: "Saytdakı və saytda olmayan hər hansı bir adamı biznes uyğunluğu üçün yoxlamağa imkanınız olacaq.",
            },
        ],
    },
};

/* eslint-disable */

export default {
    "newbie": "Беношнос",
    "logout": "Баромадан",
    days: "рӯз",
    "onBoardingMixin": {
        "title-1": "Таърифи психотип",
        "title-2": "Фоид",
        "description-1":
            "Пас аз он ки Merlin AI психотипи шуморо муайян мекунад, шумо ва ҳамаи меҳмонони саҳифаи шумо онро хоҳед дид. Мерлин инчунин шуморо ба корбарони дигари шабакаи иҷтимоӣ тавсия медиҳад,",
        "description-2": "Шумо мутмаин ҳастед, ки сертификатсия шудан мехоҳед?",
    },
    "errors": {
        "unknown-error": "Хатои ношинос",
        error: "Хатогӣ рух дод",
        unknownError: "Хатогии номаълум",
        attention: "Таваҷҷуҳ",
        wrongPhotoTitle: "Акс муносиб нашуд",
        noAccess: "Дастрасӣ вуҷуд надорад",
        alreadyFriendsTitle: "Шумо аллакай дӯст ҳастед!",
        info: "Маълумот",
        reEnter: "Ба ҳисоби худ муҷаддадан ворид шавед ё бо мо тамос гиред.",
        notEnoughMoneyTitle: "Маблағ кофӣ нест",
        compareNotAllowedTitle: "Муқоиса имконнопазир аст",
        retry: "Хатогӣ рух дод, баъдтар такрор кӯшиш кунед.",
        noAccessText:
            "Шумо талош мекунед аз вазифае истифода баред, ки дастрасӣ ба он надоред.",
        personDataNotReceived:
            "Ҳангоми гирифтани додаҳои шахсият хато рух дод, эҳтимол лозим ояд, ки саҳифаро муҷаддадан бор кунед.",
        wrongProfilePhoto:
            "Акси профил муносиб нест, акси дигарро бор кунед. Ба намунаҳои стандартӣ таваҷҷуҳ диҳед.",
        wrongPhotoToTheRight:
            "Шумо сарро ба рости зиёд чархондед, дубора кӯшиш кунед.",
        wrongPhotoToTheLeft:
            "Акс муносиб нашуд, шумо сарро ба чапи зиёд чархондед, дубора кӯшиш кунед.",
        wrongPhotoToTheBottom:
            "Акс муносиб нашуд, шумо сарро хеле поён хам кардед, дубора кӯшиш кунед.",
        wrongPhotoFewPersons:
            "Дар акс чанде аз чеҳраҳо кашф шуд, кӯшиш кунед аксро канда кунед, танҳо чеҳраи лозимро гузоред",
        wrongPhotoToTheUp:
            "Акс муносиб нашуд, шумо сарро хеле боло бардоштед, дубора кӯшиш кунед.",
        wrongPhotoSame:
            "Шумо аксҳои якхела дар намои рӯ ба рӯ ва профил боргузор кардаед, акси дигарро боргузор кунед",
        wrongPhotoFeatures:
            "Хусусиятҳои рӯй аз аксҳои шумо гирифта нашуд. Лутфан, ҳар ду аксро иваз кунед.",
        wrongPhotoYouAreNot:
            "Дар акси нав рӯи шумо дарёфт нашуд. Боргузорӣ кунед аксро бо рӯи худ",
        wrongAnfasPhoto:
            "Акс дар анфас мос наомадааст, акси дигарро боргузорӣ кунед. Таваҷҷӯҳ кунед ба намунаҳои эталонӣ.",
        wrongCelebrityPhoto:
            "Ҳангоми таълили аксҳои шахсияти интихобшуда хатогӣ рух дод. Ҳамчун ки истифодабаранда аксҳои дурустро боргузорӣ менамояд - муқоиса ва тасвир дастрас хоҳанд шуд",
        photoNotUploaded:
            "Боргузории акс муваффақ нашуд, лутфан боз кӯшиш намоед...",
        myPhotoNotUploaded:
            "Аксҳои шумо боргузорӣ нашуд. Лутфан, саҳифаро нав кунед.",
        paymentsListNotReceived:
            "Рӯйхати пардохтҳоро гирифтан мумкин нашуд, боз кӯшиш кунед.",
        incorrectPaymentData:
            "Додаҳои пардохт нодуруст аст. Лутфан, каме баъд талош кунед.",
        paymentGateWayNotRespond:
            "Дарвозаи пардохт ҷавоб намедиҳад. Лутфан, каме баъд боз кӯшиш кунед.",
        friendStatusNotReceived: "Ҳолати дӯст гирифта нашуд, боз кӯшиш кунед.",
        pageError:
            "Бо ин саҳифа мушкили номаълум пеш омад, саҳифаро нав кунед ва амали муродро такрор кунед.",
        friendRequestError: "Дархости дӯсти мумкин нашуд, боз кӯшиш кунед.",
        alreadyFriends:
            'Агар дугмеи "Илова ба дӯстон"-ро мебинед, лутфан саҳифаро нав кунед.',
        alreadySendFriendRequest:
            "Шумо аллакай пештар дархости дӯстӣ фиристодаед. Лутфан, интизор шавед, ки дӯсти шумо дархостро қабул кунад.",
        friendRequestDecline:
            "Мутаассифона, дархости шумо барои дӯсти рад карда шуд. Шумо метавонед дархости дигарро баъд аз 24 соат аз охирин талош фиристед.",
        notificationsNotReceived:
            "Наметавон рӯйхати хабарҳои шумора гирифтан, боз кӯшиш кунед.",
        servicePriceNotReceived:
            "Нархи хидматро гирифтан мумкин нашуд. Саҳифаро нав кунед ва кӯшишро такрор кунед",
        needAuth: "Барои анҷом додани ин амал муқаддимаи сабти ном зарур аст",
        notEnoughMoney:
            "Лутфан, ҳисобро бо ягон роҳи муносиб барои шумо пур кунед.",
        personListNotReceived:
            "Ҳангоми гирифтани рӯйхати шахсон хатогӣ содир шуд, боз кӯшиш кунед.",
        friendListNotReceived:
            "Ҳангоми гирифтани рӯйхати дӯстон хатогӣ рух дод, боз кӯшиш кунед.",
        badName:
            "Номи интихобшуда мувофиқ наомад, санҷед номҳои дигарро ё баъд кӯшиш кунед.",
        nameLimit: "Тағйири ном танҳо як маротиба дар 24 соат дастрас аст.",
        friendsCount:
            "Хатогӣ ҳангоми гирифтани шумораи дӯстон ба вуқӯъ пайваст. Талабро баъдтар такрор кунед.",
        friendRequestsCount:
            "Хатогӣ ҳангоми гирифтани шумораи дархостҳо барои дӯстӣ рух дод. Талабро боз баъдтар такрор кунед.",
        compareNotAllowed:
            "Барои муқоиса кардани худ бо шахсони дигар, зарур аст, ки шумо аксҳои худро боргузорӣ кунед",
        photoUploadPersonIsNotFound:
            "Саҳифае, ки шумо аксро барои насб кардан интихоб кардед, ёфт нашудааст",
        photoUploadPersonIsNotYours:
            "Саҳифае, ки шумо кӯшиши насби аксро доред, мутааллиқи шумо нест.",
        yourFaceIsNotFound:
            "Рӯи шумо дар акси нав пайдо нашуд, лутфан аксе бо рӯи худатон боргузорӣ кунед.",
        partnerStatusNotReceived:
            "Имкони дарёфти мақоми шарикият вуҷуд надорад.",
        uuidError:
            "ID-и истифодабарандаи нодурусти интиқол дода шудааст, ба маъмур муроҷиат кунед.",
        alreadyPartner: "Истифодабаранда аллакай шарики шумост.",
        waitingResponse:
            "Дархости шумо қаблан ирсол шудааст, интизори қарори шарик бошед.",
        partnerRequestDeclined:
            "Дархости шумо рад шудааст, шумо метавонед талабро баъди 24 соат аз охирин дархост такрор кунед.",
        partnerResponseAlreadySend:
            "Дархост ёфт нашуд. Эҳтимол аст, ки шумо аллакай онро қабул кардаед.",
        partnerResponseNotSend:
            "Маълумоти нодурусти ирсол шудааст. Ба кӯмаки техникӣ муроҷиат кунед",
        createDescriptionError:
            "Натавонистам тавсифи шахсияти шуморо эҷод кунам. Ба кӯмаки техникӣ муроҷиат кунед",
        firstUploadAnfas:
            "Аввал зарур аст, ки шумо аксро аз рӯ ба рӯ боргузорӣ кунед",
        descriptionNotReady:
            "Наметавон аксро дар профил боргузорӣ кард, то вақте ки Merlin аломатҳои рӯи шуморо аз акси рӯ ба рӯ ҳисоб накунад. Лутфан пас аз як дақиқа кӯшиш кунед.",
        REACH_THE_GOAL_MESSAGE_DAILY_LIMIT_REACHED:
            "Пешниҳод барои бастани мақсад бештар аз як маротиба дар рӯз ирсол карда намешавад",
        BUSINESS_PROPOSAL_MESSAGE_DAILY_LIMIT_REACHED:
            "Пешниҳодҳои тиҷоратӣ метавонанд танҳо як маротиба дар рӯз ирсол шаванд",
        AVATAR_LIMIT_REACHED:
            "Шумо ҳадди боргузории аксҳоро дар як рӯз расондаед. Лутфан фардо кӯшиш кунед.",
    },
    "cookies": {
        "prefix-text": "Мо ",
        "text": "Cookies",
        "btn-text": "OK",
    },

    "DescriptionPopup": {
        "learn-about": "Шумо дар бораи одам мехонед:",
        "light-background": "Замини рӯшан",
        "dark-background": "Замини торик",
        "start-learn-profession": "Имруз мулоимоти дурустиро бозӣ кунед:",
        "start-learn-profession-btn-text": "Оғоз",
    },
    MerlinDating: {
        "title":
            "Аё барои шиносоии қадам ба қадам ба шахсияти {name} мехоҳед алгоритм?",
        "subtitle": "Барномаи моро зеркашӣ кунед.",
    },
    "FooterLinks": {
        about: "Дар бораи лоиҳа",
        contacts: "Тамос",
    },
    "FriendsFilter": {
        "gender": {
            all: "Ҳама",
            male: "Мардон",
            female: "Занон",
        },
        age: {
            any: "Ҳар гуна",
            interval_1: "18 то 24 сола",
            interval_2: "25 то 34 сола",
            interval_3: "35 то 44 сола",
            interval_4: "45 ва болош",
        },

        typeList: [
            {
                id: "ALL",
                name: "Ҳар як",
            },
            {
                id: "ADMINISTRATOR",
                name: "Администратор",
            },
            {
                id: "ACTIVIST",
                name: "Активист",
            },
            {
                id: "ALGORITHMIST",
                name: "Алгоритмист",
            },
            {
                id: "ARTIST",
                name: "Санъаткор",
            },
            {
                id: "MASTERMIND",
                name: "Тафаккуркунанда",
            },
            {
                id: "HEDONIST",
                name: "Ҳедонист",
            },
            {
                id: "GENERATOR",
                name: "Генератор",
            },
            {
                id: "DIPLOMAT_INVADER",
                name: "Дипломат Порлиҳёти",
            },
            {
                id: "IDEOLOGIST",
                name: "Идеан",
            },
            {
                id: "IDEOLOGICAL_MASTERMIND",
                name: "Тафаккургар",
            },
            {
                id: "IDEOLOGICAL_LEADER",
                name: "Рахнамои фикр",
            },
            {
                id: "IMAGE_MAKER",
                name: "Созандаи сурат",
            },
            {
                id: "ENGINEER",
                name: "Инженер",
            },
            {
                id: "INITIATOR",
                name: "Ибтидоӣ",
            },
            {
                id: "INSPECTOR_PERFECTIONIST",
                name: "Инспектори такмилеталаб",
            },
            {
                id: "INTEGRATOR",
                name: "Интегратор",
            },
            {
                id: "RESEARCHER",
                name: "Таҳқиқотгар",
            },
            {
                id: "RESEARCHER_ANALYST",
                name: "Таҳлилгари таҳқиқот",
            },
            {
                id: "COMMUNICATOR",
                name: "Мукобилотпарвар",
            },
            {
                id: "CONNECTOR",
                name: "Пайвандбардор",
            },
            {
                id: "CONTROLLER",
                name: "Идоракунанда",
            },
            {
                id: "CRITIC",
                name: "Танқидкунанда",
            },
            {
                id: "LEGIONARY",
                name: "Легионер",
            },
            {
                id: "LEADER",
                name: "Рахбар",
            },
            {
                id: "LOBBYIST",
                name: "Лоббист",
            },
            {
                id: "MASS_MEDIA_ENTERTAINER",
                name: "Массавик Сарфароз",
            },
            {
                id: "MASTER_IMPLEMENTOR",
            },
            {
                id: "MATERIALIST",
                name: "Материалист",
            },
            {
                id: "MAECENAS",
                name: "Патрон",
            },
            {
                id: "DREAMER",
                name: "Хоббадор",
            },
            {
                id: "MORALIST",
                name: "Ахлақдор",
            },
            {
                id: "MENTOR",
                name: "Ментор",
            },
            {
                id: "ASSERTIVE_INVADER",
                name: "Порлиҳёткунандаи зоравон",
            },
            {
                id: "INFORMAL_LEADER_MANIPULATOR",
                name: "Манипулятори рахнамои информалӣ",
            },
            {
                id: "INNOVATOR",
                name: "Новакунанда",
            },
            {
                id: "INNOVATOR_ENTHUSIAST",
                name: "Новакунандаи машғул",
            },
            {
                id: "OPTIMIZER",
                name: "Оптимизатор",
            },
            {
                id: "OPTIMIST",
                name: "Оптимист",
            },
            {
                id: "ORGANIZER",
                name: "Ташкилотбардор",
            },
            {
                id: "EDUCATOR",
                name: "Муаллим",
            },
            {
                iid: "PERFECTIONIST",
                name: "Такмилеталаб",
            },
            {
                id: "POLITICIAN",
                name: "Сиясатмадор",
            },
            {
                id: "MEDIATOR_DIPLOMAT",
                name: "Дипломати МосирАкунанда",
            },
            {
                id: "PRACTITIONER",
                name: "Амалигар",
            },
            {
                id: "PRACTITIONER_CONSERVATIVE",
                name: "Амалигари муҳофиз",
            },
            {
                id: "PRACTITIONER_MANAGER",
                name: "Амалигари идоракуниш",
            },
            {
                id: "ENTREPRENEUR_INNOVATOR",
                name: "Корхонаи инновации",
            },
            {
                id: "ADHERENT",
                name: "Пояндор",
            },
            {
                id: "FORECASTER",
                name: "Пешгоҳигар",
            },
            {
                id: "PREACHER_MENTOR",
                name: "Ментори ваеъ",
            },
            {
                id: "RATIONALIZATOR",
                name: "Иноватор",
            },
            {
                id: "IMPLEMENTATOR",
                name: "Реализатор",
            },
            {
                id: "ROMANTIC",
                name: "Романтик",
            },
            {
                id: "EMINENCE_GRISE",
                name: "Кардинали Сурх",
            },
            {
                id: "SYSTEMATIZER",
                name: "Систематизатор",
            },
            {
                id: "SKEPTIC",
                name: "Скептик",
            },
            {
                id: "SOCIALIZER",
                name: "Социализатор",
            },
            {
                id: "STABILIZER",
                name: "Сабиткунанда",
            },
            {
                id: "TECHIE",
                name: "Техник",
            },
            {
                id: "HANDYMAN",
                name: "Тарашгар",
            },
            {
                id: "MANAGER",
                name: "Менежер",
            },
            {
                id: "KEEPER",
                name: "Нигоҳбон",
            },
            {
                id: "EXPERIMENTER",
                name: "Таҳқиқотгар",
            },
            {
                id: "ENTHUSIAST",
                name: "Машғул",
            },
        ],

        searchSortList: [
            {
                id: "DATE",
                name: "Тарихи илова шуда",
            },
            {
                id: "NAME",
                name: "Ном",
            },
        ],

        searchList: [
            {
                id: "DATE",
                name: "Тарихи илова шуда",
            },
            {
                id: "NAME",
                name: "Ном",
            },
            {
                id: "COMPATIBILITY",
                name: "Созгароӣ",
            },
            {
                id: "SIMILARITY",
                name: "Оҳдамийат",
            },
            {
                id: "BUSINESS",
                name: "Созгароӣи Тиҷорат",
            },
            {
                id: "INTERACTION_BUSINESS",
                name: "Интераксияи Тиҷорат",
            },
            {
                id: "INTERACTION_INTERPERSONAL",
                name: "Интераксияи Шахсӣ",
            },
        ],
        "any": "Ҳар яке",
        "all": "Тамом",
        "by-age-title": "Бо синн:",
        "by-person-type-title": "Навъи шахс:",
        "by-person-category-title": "Категорияи шахс:",
        "sort-by-title": "Дар барабари:",
        "sort-by-default": "Санаи илова шудааст",
        "sort-by-gender-title": "Бо ҷинс:",
        "reset-text": "Филтрро бекор кунед",
    },
    "InviteFriends": {
        "btn-text": "Дўстеро илова кунед",
    },
    "LeftAside": {
        "user-not-uploaded-photo": "Истифодабаранда рақамро бор нанамояд!",
        "feedback": "Ба пуштибониёт навиштан",
        "referral-program": "Барномаи тавсия",
        "bonus-program": "Барномаи бонус",
        "legal-info-title": "Иттилооти ҳуқуқӣ",
        "contacts-and-requisites": "Алоқа ва маьлумоти зарур",
        "about": "Дар бораи ширкат",
        "service-pay": "Пардохт барои хизматрасонӣ",
        "term-of-use": "Шартномаи истифодабаранда",
        "privacy-policy": "Сиёсати махфигӣ",
    },

    "RightAsideSocial": {
        "title": "Хисса кунед",
        "vk": "VK.com",
        "ok": "ok.ru",
    },
    "loading": {
        "network-error":
            "Интернетро тафтиш кунед <br/> ва саҳифаро бори дигар бор кунед",
    },
    "MainHeader": {
        "back-to-profile": "Ба профил баргардед",
        "createAccount": "Ҳисоби истифодабаранда",
        "support": "Хизматрасонии муштари",
        "logout": "Ба берун шудан",
    },
    "MobileMenu": {
        support: "ҲИМОЯ",
    },
    "NotificationsModule": {
        "all": "Ҳамаи огоҳиҳо",
        "partner": "Шуморо дархост карданд, ки шерик шавед",
        "deposit": "Депозит",
        "bonus": "Шумо бонус мегирид",
        "reward": "Нагард ва дар барномаи истиҳсоли ишораҳо",
        "referral": "{name} шерик шудааст<br/>дар Merlin Dating",
        "free-period":
            "Шерики шумо {name} гузашти давраи сабт абонементи {message} дар Merlin Dating",
        "cancel-subscribe":
            "Шерики шумо {name} лағв кард сабт абонементи {message} дар Merlin Dating",
        "pay-subscribe":
            "Шумо нагард мегирид. Шерики шумо {name} пардохт кард абонементи {message} дар Merlin Dating",
        "subscribe-error":
            "{0} сабт абонементро дар Merlin Dating натавонист боз кунад",
        "mapp_celebrity_compare": "Нагард аз хариди суруъгарии Merlin Dating",
        "bonus_coach_partner": "Бонус барои амали шерикатон",
        "person_compare": "Касе бо шуморо тафовут мекунад",
        "compatibility": "мувофиқат",
        "similarity": "тамошои",
        "business": "мувофиқат барои шерикати бизнес",
        "person_indicator": "Касе шунавда шуд",
        "interaction_business": "навъи ҳамкории бизнес",
        "interaction_interpersonal": "навъи ҳамкории муҳоити",
        "mma_champion": "сӯзандаи чемпионат",
        "mma_potential": "потенсиали ҳунарҳои рӯзмӣ",
        "aggression_common": "агрессияи шумо",
        "aggression_emotional": "агрессияи эҳсоии шумо",
        "aggression_social": "агрессияи иҷтимоӣи шумо",
        "aggression_intellectual": "агрессияи интеллектуали шумо",
        "aggression_physical": "агрессияи физикӣ шумо",
        "analyse_item_unlock": "Касе барчаспи тавсифии шумо ро дар гурӯҳ хонд",
        "professionaltype": "Профотип",
        "neurotype": "невротип",
        "leadertype": "пешвои",
        "character": "шахсият",
        "relations": "алоқаҳо ва сӯдоро",
        "motivation": "мушағғат ва энергия",
        "selfAssessmen": "ифтихори шумо ва иқтидор",
        "professional": "касб ва худрошиносӣ",
        "person_review_created": "Баррасии шахсият омодашуд",
        "new_in_top": "Болои ҳамоҳангшудан барои шумо янги шуд : ",
        "phone_confirm": "Шумо бонус гирифтед {count} $",
        "upload_photos_request":
            "Касе аз шумо хоҳиш мекунад, ки аксро бор кунед",
        "upload_photos_completed": "{user} акси худро бор кард",
        "coach_partner_request":
            "{name} шуморо дават мекунад, ки шерикат кунед",
        "coach_partner_request_accept": "Қабул кардан",
        "coach_partner_request_decline": "Рад кардан",
        "analyse_item_unlock_reward":
            "Шумо бонус гирифтед {{ notification.message }} $",
        "new_in_top_show": "Барои дидан клик кунед",
        "phone_confirm_reward": "Барои тасдиқ кардани шумораи телефон",
        "loading": "Дар ҳоли боркунии...",
        "no-more": "Огоҳиҳои дигар дарёфт нашуданд",
        "no-notifications": "Шумо ягон огоҳии навӣ надоред.",
        "show-all": "Нишон додани ҳама",
    },

    "OfflineIndicator": {
        title: "Интернет-соединение нет.",
        retry: "Лутфан, дертар саёхат кунед...",
    },
    "OnboardingDescriptionPopup": {
        text: "Ин метавонад шумо чӣ гуна дар ин кор маънавият мекашад ва чӣ сифатҳое, ки шумо марбут ба он доред.",
        error: "Шумо барои ҳамаи пешкашҳо садо накардед:",
    },
    "Search": {
        "findFriends": "Дусти худро ёб дихед",
        "found": "Истифодабарандагоне, ки ёфт шуданд:",
        "limit": "Ҳадди ақал 3 харф ворид кунед",
        "not-found": "Чизе ёфт нашуд",
        "show-all": "ҲАМАРО НИШОН ДИҲЕД",
    },
    "SearchPeople": {
        "full-name": "Номи аввал ё охирро ворид кунед",
        "all": "Ҳама",
        "friends": "Дӯстон",
        "celebrity": "Шахсиятҳои машҳур",
        "fans": "Парванчагон",
        "type-is-not-available": "Навъи шахсият ҳанӯз кашф нашудааст",
        "limit": "Ҳадди ақал 3 харф ворид кунед",
        "empty-filter": "Ба оси параметрҳои мушаххасшуда, шахсияти ёфт нашуд",
    },
    "Select": {
        nothing: "Нопартофт ба дархост",
        choose: "Интихоб кунед",
    },
    "ServiceMenu": {
        "with-friends": "Ба корбарии дӯстон муқоисат кунед",
        "about-me": "Дар бораи худ бисёр ёд гиред",
        "about-another": "Дар бораи дигарон бисёр ёд гиред:",
        "aggression-another": "Сатҳи агрессия дар:",
        "aggression": "Сатҳи агрессияро бифаҳмед",
        "person-aggression": "Сатҳи агрессия дар:",
    },
    "UserNav": {
        "my-page": "Саҳифаи ман",
        "friends": "Дӯстон",
        "compare-with-me": "Ба мон муқоиса кунед",
        "celebrities": "Шахсиятҳои машҳур",
        "messages": "Паёмҳо",
        "analyze": "Таҳлили Merlin AI",
        "recommendations": "Тавсияҳои одамон",
        "settings": "Танзимот",
        "manager": "Идораи шахсиятҳо",
    },

    "Auth": {
        "back": "Боз",
        "another-phone": "Нишонии дигари телефонро ворид кунед",
        "prev-step": "Ба қадами гузашта баргардед",
        "auth": "Иҷоза",
        "confirm-phone":
            "Мо бояд мутмаин буда бошем, ки шумо одами ҳақиқӣ ҳастед. Лутфан нишонии телефони худро ворид кунед:",
        "retry-after":
            "Шумо бақолият длятро дархост кардаед, барои ҳамин метавонед дар {{smsTimerCountdown}} сонияҳо баракшии навро дархост кунед.",
        "not-found": "Ҳеҷ чиз ёфт нашуд",
        "country-code": "Коди кишвар",
        "phone": "Нишонии телефон",
        "agree_1":
            "Бо ворид кардани нишонии телефони худ, шумо тасдиқ мекунед, ки бо ",
        "agree_2": "шартномаи истифодаи",
        "agree_3": "сиёсати ҳуқуқи шахсӣ",
        "telegram":
            "Барои пур кардани сабтномаи худ, лутфан тамоси худро баҳам диҳед - он барои шумо истифода мешавад, агар дастрасӣ ба Telegram-ро аз даст диҳед.",
        "call": "Занги воридшаванда",
        "message": "Паёми воридшаванда",
        "blocked":
            "Нишонии телефони шумо бақолият дар муддати охир баста шудааст, шумо дар {{smsTimerCountdown}} сонияҳо метавонед боз оғоз кунед.",
        "retry-error":
            "Агар ба як сабаб шуморо неста рухсат шавад ё паёми матни шумо дариёфта нашавад, шумо метавонед баъди {{smsTimerCountdown}} сония қурбо садо барои фаъолсозии кодро таъқиб кунед.",
        "telegramTitle":
            "Дархост аз ботро гирифта шуд. Авторизатсия дар ҳоли иҷро будан аст.",
        "telegramTitle2":
            "Merlin дар як дақиқа дар Telegram барои шумо паём мефиристад.",
        "enter-call-code":
            "Дар зер рақамҳои 4&nbsp; охирини нишонии воридшаванда ворид кунед.",
        "enter-message-code": "Кодро аз паёми матн ворид кунед",
        "code": "коди тасдиқ:",
        "confirm": "тасдиқ",
        "request": "дархост...",
        "enter": "ворид кунед",
        "loading": "боргирӣ шуда истодааст...",
        "requestError":
            "Хатогӣ дар фиристодани занги дархостї, баъди якчанд сония саёҳат кунед.",
        "phoneError":
            "Нишонии телефони саҳеҳро дар формати байналмилали ворид кунед. Масалан барои Русия: +79174246633",
        "codeExpired":
            "Шумо вақти дохил кардани чорақаами охиринро дар даромади 60 сония навиштаед. Лутфан, рошнаии рос процедураи тасдиқро аз нав оғоз кунед.",
        "codeError":
            "Ададҳои нодуруст, ҳеҷ кӯшишҳои боқӣ нест: {attempts} аз 3",
    },

    "PhoneForm": {
        "back": "Бозгашт",
        "another-phone": "Рақами дигар телефонро ворид кунед",
        "prev-step": "Ба қадами гузашта баргардед",
        "auth": "Тасдиқот",
        "confirm-phone":
            "Мо бояд мутмаин шавем, ки шумо одами росте ҳастед. Лутфан рақам телефони худро ворид кунед:",
        "retry-after":
            "Шумо вақтонаки телефонро хоҳиш кардед, барои хоҳиши нав тавонед дар {{smsTimerCountdown}} сония дошта бошед.",
        "not-found": "Чизе ёфт нашудааст",
        "country-code": "Рамзи кишвар",
        "phone": "Рақами телефон",
        "agree_1":
            "Бо ворид кардани рақами телефони худ, шумо тасдиқ мекунед, ки бо ",
        "agree_and": "ва",
        "agree_2": "шартномаи истифода",
        "agree_3": "сиёсати махфият",
        "telegram":
            "Барои тамом кардани сабтномаи худ, лутфан элочаги худро бо мо иштирок кунед - он дар сурати аз даст додани дастрасии ба Telegram истифода блуд.",
        "call": "Занги воридшаванда",
        "message": "Паёми воридшаванда",
        "blocked":
            "Рақами шумо недавтар ба китоби сиёҳ доде шуд, шумо метавонед дар {{smsTimerCountdown}} сония дидани навро оғоз кунед.",
        "retry-error":
            "Агар барои як дағонаи сабаб, бо шумо тамос гирифта нашуд ё паёми матни шумо дариёфта нашуд, шумо метавонед дар {{smsTimerCountdown}} сония кодеро бори дигар хоҳиш кунед.",
        "telegramTitle": "Хоҳиши бот дариёфта шуд. Дар ҳоли тасдиқи худ.",
        "telegramTitle2":
            "Мерлин дар як дақиқа поёни паёми телеграми шуморо фиристод.",
        "enter-call-code":
            "Бисту чорро барои рақами воридшавандаи поён дар зер ворид кунед.",
        "enter-message-code": "Коди аз паёми матнро ворид кунед",
        "code": "тасдиқи код:",
        "confirm": "тасдиқ",
        "request": "хоҳиш...",
        "enter": "ворид кунед",
        "loading": "боргирии...",
        "requestError":
            "Хатогӣ дар вақти фиристодани хоҳиши занг, дар якчанд сония боз кӯшиш кунед.",
        "phoneError":
            "Рақам телефонро ба формати байналмилалӣ дуруст ворид кунед. Намуна барои Русия: +79174246633",
        "codeExpired":
            "Шумо вақт надоштед, ки дар як дақиқаи охирин чорро дарёфта бошед. Лутфан рошедарои таъйидро аз нав оғоз кунед.",
        "codeError": "Ададҳо нодуруст аст, ижро нагардид: {attempts} аз 3",
    },

    "choose-want": {
        "choose-1-1": "Пул",
        "choose-1-2": "касб",
        "choose-1-3": "худшиносӣ",
        "choose-2-1": "Энергия",
        "choose-2-2": "мотиватсия",
        "choose-2-3": "маъно",
        "choose-3-1": "Муносибат",
        "choose-3-2": "хона",
        "choose-3-3": "ҳамкарон",
        "choose-4-1": "Иқтисод",
        "choose-4-2": "худроҳмайгӣ",
        "choose-4-3": "ақл",
    },
    "CelebrityProgram": {
        "compare":
            "Таъқиқоти мувофиқасти худ бо машҳуриятро истифода баред ва агар ба боло берсиҳ, метавонед то шахсан мулоқот кунед",
        "desc": "Ин барнома мардум ва машҳиротро имкон медиҳад:",
        "get-know": "Донош кардани якдигар",
        "collaborate": "Ҳамкорӣ кардан",
        "talk": "Суҳбат кардан",
        "date": "Ба рӯзи ҷустуҷӯ шодан",
        "all": "Ҳама машҳирот дар барнома",
    },    
    "MessagesHistory": {
        "no-messages": "Паёми надорад",
        "accessError": "Шумо метавонед навиштани ин корбар. Агар шумо фикр кунед, ки ин хато аст, лутфан ба хадамоти муштариен муроҷиат кунед.",
    },
    "MessageHistoryHead": {
        "new-chat": "Чати нав",
        "history": "Таърих",
        "delete-chat": "Нест кардани суҳбат",
        "disable-notifications": "Хомӯш кардани огоҳӣ",
        "enable-notifications": "Фаъол кардани огоҳи",
        "attempts-left": "Боқӣ мондааст {0} дархост",
    },
    "ChatMessage": {
        "myMessageTitle": "шумо",
        "upscale": "Баланд кардани {0}",
        "generated-by-stable-diffusion":
            "Илова паём эҷоди омад бо Стабил Диффузии",
        "generated-by-midjorney": "Расма эҷодшуда бо Midjorney",
        "optimization-midjorney": "Оптимизацияи эҷоди илова паём",
        "seconds": " сония.",
        "generated-by-midjorney-error":
            "Тавлид кардани тасвир имконпазир нашуд. Нигарон нашавед, мо аллакай дар бораи мушкилот хабар дорем ва ба зудӣ онро ҳал мекунем. Баъдтар кӯшиш кунед",
        "midjorney-parameters-error": "Параметрҳои номаълум гузаронида шуданд:",
        "midjourney-queue":
            "Интизор шавед, шумо {0} дар навбат барои тавлиди тасвир ҳастед",
        "midjorney-stress-error":
            "Фишор барои сервер хеле зиёд аст. Баъди 1 дақиқа кӯшиши дигарро анҷом диҳед",
        "midjorney-moderator-error":
            "Бубахшед, вале дархости шумо барои тасвир қоидаҳоро вайрон мекунад. \nМан тасвирҳоеро тавлид намекунам, ки:\n- Мумкин аст касеро ранҷонад ё фиреб диҳад.\n- Калимаҳои тавҳиномез ё зӯровариро дар бар гирад.\n- Тасвири касеро бе либос ё дар ҳолатҳои номуносиб нишон диҳанд.\n- Дар фарҳангҳои гуногун ҳамчун беҳурматӣ ҳисобида мешаванд.",
        "midjorney-unknown-error":
            "Оҳ, ба назар мерасад, ки чизе бо дархости шумо барои тавлиди тасвир нодуруст рафтааст. \n\nНамунаҳои хатоҳои оддӣ:\n1️⃣ Истифодаи параметри --version 5.2 танҳо бо --style: raw. \n2️⃣ Дурустии гузаронида шудани параметрҳо. Параметрҳое мисли -ar, 21:9, -style, raw, -q2, -seed, 302280510 гумонбар назар мерасанд.\n3️⃣ Саҳеҳ будани нисбати андозаҳои дода шуда. Бояд дар формати Ш:В бошад, масалан 16:9, на 'da'.\n\nИн танҳо намунаҳое ҳастанд, ки метавонанд нодуруст бошанд. Лутфан, дархости худро санҷед ва боз кӯшиш кунед.",
        "midjorney-single-image-error":
            "Тавлид кардани тасвир танҳо аз рӯи тасвири гузаронида шуда имконнопазир аст, дархости матниро илова кунед ва кӯшишро такрор кунед",
        "gpt-error-try-again": "Чизе нодуруст шуд. Дубора кӯшиш кунед",
        "download": "Зеркашӣ кунед",  
    },
    "AddMessageForm": {
        "inputPlaceholder": "Паёмро ворид кунед...",
    },
    "Chat": {
        "loading": "Боргирии суҳбат...",
        "write": "навишта мешавад...",
        "online": "Онлайн",
        "offline": "Онлайн бӯд",
        "delete-chat": "Нест кардани суҳбат",
        "disable-notifications": "Хомӯш кардани огоҳӣ",
        "enable-notifications": "Фаъол кардани огоҳи",
        "partner": "Паёме Илтимоси ширкат",
        "partner-for-you": "Паёме Илтимоси ширкатро гирифтед",
        "target": "Шумо ба хотима расондани ҳадафи паёме предлагали",
        "target-for-you": "Паёме ба хотима расондани ҳадафи шуморо доданаст",
        "AIPlaceholder": "Вориди мактубат барои эҷоди элемент",
        "edit": "Таҳрир",
        "copy": "Нусха гардонӣ",
        "delete": "Нест кардан",
        "read": "Хондани",
        "notRead": "Хонда нашудааст",
        "empty-error": "Паём наметавонад холӣ бошад",
        "length-error-min": "Дарозии паём нест камтар аз {min} баят бошад",
        "length-error-max":
            "Дарозии паём набояд бошад камтар аз истифода барои расмии паём дастраси ба {max} баят",
        "target-message":
            "Шумо паёми ба хотима расонида ҳадаф повести предлагаете",
        "partner-message": "Предложение бизнеса",
        "stable-diffusion-message":
            "Режими эҷоди илова паёмъ бо Стабил Диффузии",
        "midjorney-message": "Режими эҷоди илова паёмъ бо Midjorney",
        "editing": "Таҳрир",
        "confirmTitle": "Тасдиқи амал",
        "confirmDeleteMessageText": "Воқеан бехоҳед паёмро нест кунед?",
        "confirmDeleteAllMessagesText":
            "Воқеан шумо мехоҳед ҳамаи мубодила барои нишондодшуда ҳазф кунед?",
        "manyMessage5seconds":
            "Паёмҳои бисёр дар вақти кӯтоҳ, бояд 5 сония кам кунед.",
        "manyMessage60seconds":
            "Истифода барои расмии паёми зиёд нашавад 30 дар дақиқа, лутфан кам кунед та 60 сония чанд.",
        "userDisable": "Истифода баранда ба расмии паёми дастрасиӣ маҳрум шуд.",
        "sendError": "Хатогии фиристодани паём, аз навро кӯшиш кунед.",
        "new-chat": "Чати нав",
        "history": "Рӯйхати чатҳо",
        "gpt-message-to-long": "Паёми хеле дароз",
    },

    "Dialogs": {
        "search": "Дусти худро ҷустуҷӯ кунед",
        "open": "Чатро кушоед",
        "nothing": "Шумо чати фаъоле нестед",
        "image": "Тасвир",
        "new-chat": "Чати нав",
        "delete": {
            "title": "Тасдиқи ҳазф кардан",
            "desc": "Пас аз ҳазф кардани чат, ҳамаи паёмҳо дар он ҳазф карда мешаванд",
            "btn": "Ҳазфи чат",
        },
        "attempts-left": "Боқӣ мондааст {0} дархост",
    },
    "BlockedUI": {
        "title": "Merlin AI лаёқатҳои Шуморо муайян мекунад",
        "description":
            "Барои дастрасӣ ба тамоми имконоти сайт аксҳои худро боргузорӣ кунед",
        "upload": "Боргузории аксҳо",
    },
    Messages: {
        loading: "Боркунии чат...",
        "chooseAI": "Шабакаи нейронеро, ки мехоҳед истифода баред, интихоб кунед",
        chooseChat: "Чатеро интихоб кунед, тои намоишу порсунги поёнхо",
    },
    ChatMenu: {
        title: "Меню",
        stableDiffusionTitle: "Низоми пахш",
        stableDiffusionDescription:
            "Асоси насри ҳар текстеро аксҳои фотореалистеро ипедо варедонед",
        midjorneyTitle: "Миҷорни",
        midjorneyDescription:
            "Асоси насри ҳар текстеро аксҳои фотореалистеро ипедо варедонед",
        enable: "Фаъол кунед",
        disable: "Ғайрифаъол кунед",
    },
    PayoutModal: {
        balance: "Баланси Шумо ин аст:",
        title: "Нагд кардани восита:",
        sum: "Маблағи баровардгирӣ:",
        sumRequiredError: "Маблағи баровардгирӣро мушаххас кунед",
        sumMinError:
            "Уфтони, мо натавонем ба дархостатон ҷавоб диҳем. Маблағи минимум барои баровардгирӣ {min} сомонӣ мебошад. Идомаи фаъолӣ ва зудтар шумо касби пулро барои баровардгирӣ тавонед.",
        sumMaxError: "Маблағи максимум барои баровардгирӣ {max}",
        success:
            "Дархости нагд солдеро қабул карда шуд! Мудири зудсоҳ бо шумо тамос мегирад",
        newFormat:
            "Мо ба формати обуна шифта кардем, шумо метавонанд баланси ҷории худро барои муқоиса бо дусти ё машхурҳоро харг кунед. Ва барои фоъидаи тамоми чипҳоро обуна кунед",
        deposits: "Пур кардани пул",
        payments: "Харидҳо",
        bonuses: "Бонусҳо",
        table: {
            "date": "Сана",
            "sum": "Маблағ",
            "description": "Тавсифи",
            "remainder": "Бобча",
            loading: "Боркунӣ...",
            noMore: "Нусхаҳои бештар нест",
            no: "Муомалаҳои ёфт нашуд",
        },
    },

    purchaseDescriptions: {
        category: {
            deposit: "Пурзуниши ҳисоб",
            analyse_item_unlock: "Хариди тавсифот дар категория",
            bonus_analyse_item_unlock:
                "Касе аз тавсифи шумо дар категория истифода кард",
            referral_reward: "Мукофот аз барномаи марофӣ",
            merlinapp_referral_created:
                "Мукофот барои мароф дар барномаи мобилӣ",
            person_compare: "Шумо тафтиши кардед",
            bonus_person_compare: "Касе шуморо барраси кард",
            person_compare_photos: "Таҳлили бо аксҳо ба",
            person_indicator: "Ишораи кушод:",
            bonus_person_indicator: "Касе ишораи шуморо кушод:",
            phone_confirm: "Мукофоти Тасдиқи Телефон",
            bonus_coach_partner: "Мукофоти Барномаи Шарикии Муроббӣ",
            payout: "Бардоштан",
            refund: "Бозгашт додан",
        },
        subcategory: {
            professionaltype: "навъи касби",
            neurotype: "навъи нервӣ",
            leadertype: "раҳбарӣ",
            character: "характер",
            relations: "робитаҳо ва хона ва оила",
            motivation: "тарғибот ва энергия",
            selfAssessmen: "баҳои худ ва иштирокӣ",
            professional: "пеша ва худроишӣ",
            compatibility: "мувофиқат",
            similarity: "шабоҳат",
            business: "мувофиқат барои шарикати тижоратӣ",
            interaction_business: "навъи ҳамкории тижоратӣ",
            interaction_interpersonal: "навъи ҳамкории муносибати афрод",
            mma_champion: "потенсиали чемпионат",
            mma_potential: "потенсиали ҳунаремонда",
            aggression_common: "ташаддуди умумӣ",
            aggression_physical: "ташаддуди ҷисмӣ",
            aggression_emotional: "ташаддуди эҳсосӣ",
            aggression_social: "ташаддуди социалӣ",
            aggression_intellectual: "ташаддуди интеллектуали",
        },
    },

    serviceTypes: {
        COMPATIBILITY: "Созавият",
        COMPATIBILITY_PLAN: "Муносибот ва oilа",
        SIMILARITY: "Ҳамонияи шахсиятҳо",
        SIMILARITY_PLAN: "Оила ва ишқарабоидон",
        BUSINESS: "Созавият дар иш",
        BUSINESS_PLAN: "Профессия ва мақсад",
        INTERACTION_BUSINESS: "Муносиботи корӣ",
        INTERACTION_BUSINESS_MY: "Ман чӣ тавр кор мекунам",
        INTERACTION_BUSINESS_OTHER: "У чӣ тавр дар кор расид",
        INTERACTION_BUSINESS_PLAN: "Профессия ва мақсад",
        INTERACTION_INTERPERSONAL: "Муносибот бо одамон",
        INTERACTION_INTERPERSONAL_MY: "Ман чӣ тавр бо одамон муносибат мекунам",
        INTERACTION_INTERPERSONAL_OTHER: "У бо одамон чӣ тавр расид",
        INTERACTION_INTERPERSONAL_PLAN: "Муносибот ва Oilа",
        AGGRESSION_COMMON: "Ҳамлаи умумӣ",
        AGGRESSION_COMMON_PLAN: "Муносибот ва Oilа",
        AGGRESSION_PHYSICAL: "Ҳамлаи ҷисманӣ",
        AGGRESSION_PHYSICAL_PLAN: "Профессия ва мақсад",
        AGGRESSION_EMOTIONAL: "Ҳамлаи ҳиссий",
        AGGRESSION_EMOTIONAL_PLAN: "Профессия ва мақсад",
        AGGRESSION_SOCIAL: "Хамлаи иҷтимоӣ",
        AGGRESSION_SOCIAL_PLAN: "Профессия ва мақсад",
        AGGRESSION_INTELLECTUAL: "Ҳамлаи интеллектуалӣ",
        AGGRESSION_INTELLECTUAL_PLAN: "Муносибот ва oilа",
        INTELLIGENCE_LEVEL: "Дараҷаи интеллект",
    },
    buyActions: {
        "compareUnlimited": "Шумораи муқоисаҳо бе ҳудуд аст",
        "compareLimit": "Муқоисаҳои боқимонда: {limit}",
        "indicatorUnlimited": "Шумораи боздидҳо бе ҳудуд аст",
        "indicatorLimit": "Талошҳои боқимонда: {limit}",
        compareCelebrity: "маъшухи давра",
        compareUser: "бо истифодабаранда",
        indicatorCelebrity: "маъшуҳот",
        indicatorUser: "истифодабаранда",
        compareConfirmTitle: "Тасдиқи муқоиса",
        compareConfirmText: `Оё шумо мутмаин ҳастед, ки худро ба {userType} {name} дар категорияи "{activeType}" муқоиса кунед?`,
        compareConfirmBtn: "Тасдиқи харид",
        indicatorConfirmTitle: "Тасдиқи харид",
        indicatorConfirmText:
            'Оё шумо воқеан мехоҳед, то категорияи "{activeType}" дар {userType} {name} кушод шавад?',
        indicatorConfirmBtn: " Тасдиқи харид",
    },
    "compare-result-dialog": {
        more: "Бо дигар адам боз илова заниш кун",
    },
    "ConfirmDialog": {
        price: "Арзиш:",
        timer: "Пеш аз тафтиши ба хисоби обуна:",
        cancel: "Бекор кун",
        apply: "Тасдиқ кун",
    },

    "LegalLinksMobile": {
        "referral": "Барномаи ишоратдихӣ",
        "legal-title": "Иттилооти ҳуқуқӣ",
        "requisites": "Тамос ва маълумот",
        "about": "Дар бораи ширкат",
        "payments": "Пардохти ҳизматрасонӣ",
        "term-of-use": "Шартномаи корбарӣ",
        "privacy-policy": "Сиёсати махфият",
        "payout": "Барномаи бонус",
        "support": "Нависед ба дастгирӣ",
        "logout": "Хориҷ кардан",
    },
    "AnotherPerson": {
        "rating": "Дараҷаҳои шумо",
        "person-rate": "Эътибори одам",
        "compare-with-me": "Муқоиса кунед бо худ",
        "more": "Бисёр бояд донист",
        "aggression": "Сатҳи харобӣ",
    },
    "AnotherPersonActions": {
        "sendMessage": "ПАЁМ РА ВАСЛ КУНЕД",
        "sendTarget": "ТАКЛИФ КАРДАН БАРОИ НИШОНЪТ",
        "sendBusiness": "ТАКЛИФИ ТИҶОРАТӢ",
        "targetConfirmTitle": "тавзеҳ",
        "targetConfirmText":
            "Дар ҳолати иҷрои ин амал, аввалин кор, шумо бояд онро ба дўстонозиат илова кунед.",
        "businessConfirmText":
            'Барои ирсоли таклифи тиҷоратӣ ба одам, шумо бояд обуна кунед ба "Профессия ва Мақсад" категорияси',
        "add": "Дар оҳдонозиҳо илова кунед",
        "subscribe": "обуна шавед",
    },

    "AnotherPersonInfo": {
        "getType": "Мени маънои психологиро идентифи будед",
        "typeHidden": "Типи шазосяи холи пайдо нашуд",
        "web": "Сайт",
        "webUndefined": "Мошаххас нест",
        "selfEsteem": "Чист маншайрии худ сарбуланд мекунад",
        "selfEsteemUndefined": "Мошаххас нест",
        "occupation": "Статус",
        "occupationUndefined": "Мошаххас нест",
        "personalInfo": "Маълумоти шахсӣ",
        "age_1": "сол",
        "age_2": "сол",
        "age_3": "сол",
        "height": "см",
        "weight": "кг",
        "noChildren": "Фарзандони нест",
        "children_1": "фарзанд",
        "children_2": "фарзандон",
        "children_3": "фарзандон",
    },
    "AnotherPersonBusinessCard": {
        "merlin": "Аз Мерлин",
        "aboutMe": "{name} дар бораи худ",
        "blocked": "Баста шудааст",
        "unlock": "Рафтан ба мулоқот",
        "more": "Боштар бихонед",
        "mrln_strengths": "Қувватҳо",
        "mrln_talents": "Истеъдод",
        "mrln_self_esteem": "Чист маншайрии худ сарбуланд мекунад",
        "mrln_motivation": "Чис ба амол дизани хушбу",
        "mrln_tasks": "Зеркашӣ ки огох месазад",
        "mrln_activities": "Соҳаи фаъолият ба он меёбад",
        "self_esteem": "Чис маншайрии худ сарбуланд мекунад",
        "user_like": "Чи дуст дорад",
        "user_good": "Чи хуб аст",
        "user_usually": "Чи маъмули",
        "user_work": "Ки мехохад бо он кор кунад",
        "user_goal": "Мехохад пул бигирад",
        "user_skill": "Мехоханд истеъдод пардозӣ кунанд",
        "career": "ТАЖРИБА",
        "education": "ТАЪЛИМ",
        self_esteemUndefined: "Нечиз",
        user_likeUndefined: "Нечиз",
        user_goodUndefined: "Нечиз",
        user_usuallyUndefined: "Нечиз",
        user_workUndefined: "Хеҷ кас",
        user_goalUndefined: "5$ дар соат",
        user_skillUndefined: "самти чапи тахта",
        fromNow: "аз имруз",
        categoryProfession: "Касб ва мақсад",
        categorySelfEsteem: "уқувват ва ужбат",
        categoryMotivation: "Мотивация ва амалиёт",
        needSubscribe: "Ҷоишӣ ба падароз маҳсус шудааст",
        needSubscribeText:
            'Барои гушиёдани ин музд, ба падароз " {category} " ниёз дорад.',
        subscribe: "Падаро шавед",
    },
    "AvailableSocial": {
        "notSelected": "МУАЙЯН НАШУДААСТ",
    },
    "AvatarGallery": {
        retry: "Такрор кунид",
        new: "Боргузори аватари нав",
    },

    "ChangeName": {
        "placeholder": "Худро бо ном ва насаб ворид кунед",
        "error": "Ном ва насаб бояд бисёр аз 2 ҳарф дошта бошад",
        "saving": "Дар ҳоли захирашудан...",
        "save": "Сабт кунед",
    },
    "ChangePhotos": {
        "another": "Дигарро бор кунед",
        "anotherAnfasDrag": "Ёкҳое <br/> дигарро дар инҷо бекашид",
        "anotherProfileDrag": "Ёкҳои <br/> дигарро ба профайли худ бекашид",
        "didntFit": "Акс барои захирашудан мувофиқат намекунад",
        "limit": "Ҳадди бои <br/> сабтсозии эмрўз тамдд кардааст",
        "example": "Намунаи акси муроҷияати",
        anfas: {
            title: "Фақат алгоритми шабакаи неронӣ аксхои шуморо бубинад. Аксхо барои шахсони сеому наишдоштамъак мавруд нест.",
            desktopText: "Акси худро дар ин ҷо бекашид.",
            mobileText: "Акси худро дар ин ҷо бекашид.",
            dragText: "Акси худро дар ин ҷо бекашид",
        },
        profile: {
            desktopText: "Акси профайли худро бекашид",
            mobileText: "Акси профайли худро бекашид",
            dragText: "Акси профайли худро бекашид",
        },
        avatar: {
            title: "Аватари худро бор кунед, он барои ҳамаи касони, ки саҳифаи шуморо мебинанд, дидани мебошад.",
            text: "Аватари худро бор кунед",
            dragText: "Аватари худро бекашид.",
            help: "Ман тавсия мекунам шумо аксе ки беҳтар меорадам шуморо бинанд, бор кунед. Ин акс мебошад, ки корбарони дигар диханд. Ман наметавонам шуморо бо аксҳои касони дигар реклама кунам.",
        },
    },
    "CompareWithPhoto": {
        "back": "Бозгашт ба тавсифоти шахсӣ",
        "another-type": "Навъи муқоисаи дигарро интихоб кунед",
        "choose-in-list": "Аз феҳристи дусти худ интихоб кунед",
        "searchFriends": "ҷустуҷӯи дусти",
        "noFriends":
            " ҳанӯз дусти надори. Шумо метавонед дустони худро даъват кунед ва бонус овари мегиред, агар онхо қабул кунанд",
        "invite":
            "Мубориза, ин шахс ҳанӯз дар феҳристи дусти худ надорад. Шумо метавонед онро даъват кунед ва бонус овари мегиред, агар он қабул кунад",
        "loading": "Дар ҳоли боркунӣ...",
        "no": "Дигар касе пайдо нашудааст :)",
        "noFriendsShort": " Шумо дусти надоред",
        "upload-friend-photo": "акси дусти худро бор кунед",
    },
    "MobileAvatarGallery": {
        "photo-uploaded": "Акс бор шуд",
        "choose-main": "То барои асосӣ гузориш кунед",
        "confirmDeleteTitle": "Тасдиқи ҳазф",
        "confirmDeleteText": "Ҳақиқатан шумо мехоҳед аксро ҳазф кунед?",
        "confirmDeleteBtn": "Ҳазф кунед",
    },

    "PersonCompareMobile": {
        compare: {
            myProfile: "Муқоиса бо дўстонатон кунед",
            withUser: "Худро бо <span>{user}</span> муқоиса кунед",
        },
        indicator: {
            myProfile: "Бисёр дар бораи худро бидонед",
            withUser: "Бисёр дар бораи:<br/> {user} бидонед",
        },
    },
    "PersonDescription": {
        "back": "Ба пеш",
        "choose-type": "Истиқомати маҳз ва дилхоҳи худро интихоб кунед",
        "need-subscribe": "Обуна дорои лозимат ҳаст",
        "need-subscribe-text":
            "Барои хондани шарҳи бахши интихобшуда, лозим аст ки ба гурӯҳ обуна кунед",
        "btn": "Обунашавӣ",
    },
    "PersonInfo": {
        "age": "Синну сол",
        "age_1": "сол",
        "age_2": "сол",
        "age_3": "сол",
        "no": "Мушаххас нашудааст",
        "nothing": "Ҳеҷ чиз",
        "children": "Фарзандон",
        "children_1": "фарзанд",
        "children_2": "фарзандон",
        "children_3": "фарзандон",
        "height": "Коронаш",
        "weight": "Вазн",
        "subscriptions": "Обунаҳо",
        "photo-error":
            "Агар шумо дар якчанд аксҳои шахсии худ дороед - карточкаи иқтисодӣ бахшидиҳи ҷалбтар ба назар мерасад",
    },
    "PersonMobileBox": {
        history: "Таърих",
    },
    "PersonMobileBusinessCard": {
        merlin: "Аз Мерлин",
        user: "Аз {user}",
        career: "Кариера",
        education: "Таълим",
    },
    "PersonMobileBusinessCardPreview": {
        "title":
            "Чӣ ғуна ба назар мерасад карточкаи худро <br/>барои шахсе дигар",
        "send": "САХТАНИ ИФТИТОҲИ САҲИФААМ",
    },
    "PersonMobileHeader": {
        "noName": "Ном мушаххас нашудааст",
        "changeName": "Номро танзим кунед",
        "my-type": "Намуди рухонии манро муайян кунед",
        "type-not-available": "Типи шахсииҳо ҳанӯз кашф нашудааст",
    },
    "PersonalRecommendations": {
        title: "Пешниҳодҳои шахсӣ",
    },
    "PersonalRecommendationsDialog": {
        title: "Барои шумо пешниҳодҳои шахсии монандагӣ",
        canOpen: "Шумо метавонед кушодан",
        number: "Пешниҳодҳои №",
        openAfter: "Шумо метавонед пас аз ин кушед: ",
    },

    "PhotoExamples": {
        title: "Намунаҳои аксҳои истиғрокӣ",
        text: "Чандон ки акс ба миён ба наздиктар буда бошад, шабакаи асабӣ бо дақиқатар оид ба шумо хабар мекунад. <br/> Аксҳо танҳо ба шумо дастрас хоҳанд буд.",
        anfas: "Акс дар анфас",
        profile: "Акси профилӣ",
        btn: "Аксҳои худро боргузорӣ кунед",
    },
    "AboutSettingsForm": {
        "user_like": "Шумо чӣ кор мекунед?",
        "user_good": "Шумо дар чист хуб ҳастед?",
        "user_usually": "Шумо одатан чӣ карданӣ мекунед?",
        "user_work": "Шумо мехоҳед ки дар чист меҳнат кунед?",
        "user_goal": "Чанд мехоҳед пул бегиред?",
        "user_skill": "Шумо мехоҳед  чӣ ҳоварданӣ кунед?",
        "saved": "Нигоҳ дошта шуд",
    },
    "CareerSettingsForm": {
        "company-name": "Номи ширкат",
        "search-title": "Шояд муроди шумо ин буд:",
        "company-name-error": "Номи ширкатро ворид кунед",
        "company-position": "Позиция",
        "company-position-error": "Позицияро ворид кунед",
        "company-start": "Оғози кор",
        "company-start-error": "Таърихи оғозро ворид кунед",
        "company-end": "Пояи кор",
        "company-end-now":
            "Агар шумо ҳанӯз дар ин ширкат меҳнат кунед, ин ҷо ро холӣ гузаронед",
        "saved": "Нигоҳ дошта шуд",
        "save": "Сабт",
        "andContinue": " ва идома диҳед",
        "delete": "Хориҷи кор",
        "add": "Иловаи кор",
    },
    "EducationSettingsForm": {
        "country": "Кишвар:",
        "selectCountry": "Кишварро интихоб кунед",
        "alreadySelectCountry": "Аввал кишварро интихоб кунед",
        "alreadySelectCity": "Аввал шаҳрро интихоб кунед",
        "city": "Шаҳр: ",
        "searchCity": "Ҷустуҷӯ ба робитаи шаҳр",
        "no-cities": "Ҳеҷ шаҳрӣ барои хоҳиши шумо ёфт нашуд",
        "education-place": "Номи мактаб",
        "education-place-nothing": "Ҳеҷ мактабӣ барои хоҳиши шумо ёфт нашуд",
        "education-palce-error": "Номи мактабро ворид кунед",
        "education-position": "Ихтисоос",
        "education-position-error": "Майдони худи мутахассисшударо ворид кунед",
        "education-start": "Соли оғози таҳсил",
        "education-start-error": "Соли оғози таҳсилро ворид кунед",
        "education-end": "Соли фароғатшудан",
        "education-end-now":
            "Агар шумо ҳанӯз дар ин ширкат меҳнат кунед, ин ҷо ро холӣ гузаронед",
        "saved": "Нигоҳ дошта шуд",
        "save": "Сабт",
        "andContinue": " ва идома диҳед",
        "delete": "Хориҷи макони таҳсил",
        "add": "Иловаи макони таҳсил",
    },

    "Settings": {
        "successPhotoTitle": "Суратҳо боргузорӣ шуданд",
        "successPhotoDescription":
            "Акнун шумо метавонед аз ҳамаи имконоти сайт истифода баред",
        "myMessageTitle": "Шумо",
        lang: "Забони сайт",
        "information": "Асосӣ",
        "added-info": "Маълумот",
        "about": "Ҳавоӣ",
        "career": "Кариера",
        "education": "Таълим",
        "photos": "Суратҳо",
        "name": "Ном",
        "person-name": "Номи шаҳсро ворид кунед",
        "name-error": "Ном бояд аз 60 ҳарф камтар бошад",
        "address": "Адреси саҳифа",
        "address-error":
            'Адреси саҳифа фақат метавонад ҳарфҳои лотинӣ, рақамҳо ва нишонҳои "_" ва "-" ва на метавонад аз 3 ё бештар аз 30 ҳарф камтар ё зиёд бошад',
        "address-length-error": "Адрес бояд аз {min} ҳарф зиёд нашавад",
        "birthday": "Санаи таволлуд",
        "birthday-error": "Санаи таволлудро интихоб кунед",
        "marital": "Ҳолати оилавӣ",
        "maritalList": [
            "Танҳо",
            "Оиладор",
            "Намуруват",
            "Оиладор",
            "Мутақаббил",
        ],
        "select": "Аз рӯйхат интихоб кунед",
        "children": "Кӯдакон",
        "children-error": "Адади саҳеҳро ворид кунед ё кӯдакони холӣ бошад",
        "height": "қад",
        "maxHeightError": "Ҳадди ақси барое қабул кардан 200 аст",
        "maxWeightError": "Ҳадди ақси барое вазн - 200",
        "heightUnit": "см",
        "weight": "Вазн",
        "weightUnit": "кг",
        "height-error": "Қади худро ворид кунед",
        "weight-error": "Вазни худро ворид кунед",
        "gender": "Ҷинс",

        "genderList": [
            {
                id: "M",
                name: "Мард",
            },
            {
                id: "F",
                name: "Зан",
            },
        ],
        "eye-color": "Ранги чашм",
        "saved": "Захира шудааст ба",
        "delete-account": "Ҳисобро нест кунед",
        "exit": "Хӯруд аз система",
        "nameTimeError": "Номро дар як 24 соат танҳо як бор метавон тағйир дод",
    },
    "InfoSettingsForm": {
        "web": "Сомона",
        "selfEsteem": "Чи зиёд меояд самарағӯйии шумо?",
        "address-incorrect":
            "Ба фармоиши дуруст пайваст шавед, дар формат: https://merlinface.com",
        "self-esteem-error":
            "Бештар аз 50 символҳо, шумо ворид кардаед {count}",
        "social-status": "Мақоми шумо дар ҷамъият:",
        "socialList": [
            "Шогирд",
            "Толиб",
            "Бекор",
            "Худкор",
            "Корманди ширкат",
            "Молик(шерик)и ширкат",
        ],
        "country": "Кишвар:",
        "city": "Шаҳр:",
        "selectCountry": "Кишварро интихоб кунед",
        "searchCities": "Дар шаҳрҳо ҷустуҷӯ кунед",
        "saved": "Захира шудааст",
    },

    "SocialRating": {
        "start": "Чӣ хел {suffix}",
        "end": "И",
    },
    "UserRecommendationsMobile": {
        "analyze":
            "Таҳлили адамони пешниҳодшуда андодашудааст. Ин то 24 соат замон мегирад.",
        "category_1": "мақсади&nbsp;касб",
        "category_2": "Алоқаҳои&nbsp;хонои",
        "category_3": "Омадгирӣ&nbsp;асарпазирӣ",
        "category_4": "аввалияти-худро&nbsp;ишонамоӣ.",
    },

    Person: {
        "you": "шумо",
        "user": "User",
        compare: {
            "fromMe": "Муқояса бо дусти",
            "fromUnnamedUser": "Муқоясаи шумо бо <span>шихси ношинос</span>",
            "fromNamedUser": "Муқоясаи шумо бо <span>{user}</span>",
        },
        indicator: {
            "fromMe": "Бисёр дар бораи худ илм гиред",
            "fromUnnamedUser": "Бисёр дар бораи: <span>Ношинос</span>",
            "fromNamedUser": "Бисёр дар бораи: <span>{user}</span>",
        },
        aggression: {
            "fromMe": "Сатҳи хамозиши худро пайдо кунед",
            "fromUnnamedUser": "Сатҳи хамозиши: <span>Шихси Ношинос</span>",
            "fromNamedUser": "Сатҳи хамозиши: <span>{user}</span>",
        },
    },
    "PersonList": {
        "celebrities": "Шахсият",
        "fans": "Ҳамдидандагон",
        "friends": "Тамоми дустон",
        "friends-requests": "Зарури дусти",
        "select-all": "Барои умуми дости худ интихоб кунед",
        "no-with-params": "Мутабиқ бо параметрҳои мушаххасшуда, намуде шудааст",
        "no-friends": "Шумо доир дусти нест",
        "no-requests": "Шумо доир зарури дусти нест.",
        "no-celebrities": "Натижаи дар хусуси шахсиятҳо ёфта нашуд",
        search: {
            "celebrity": "Ҷустуҷӯи шахсият",
            "friends": "Ҷустуҷӯи дусти",
            "requests": "Ҷустуҷӯи зарури дусти",
            "users": "Ҷустуҷӯи шахсият",
        },
    },

    "PersonShortCard": {
        typeLocked: "Шахсият фақат ҳангоми нобуда муайян шудааст",
        sendMessage: "Паём файз кунед",
        compare: "Муқойаса кардан",
        hide: "Пинҳон кардан",
        removeFromFriends: "Дустиро пок кунед",
        acceptRequest: "Илова кардан",
        declineRequest: "Рафи мекунад",
        confirmDeleteFriendTitle: "Амалиётро тасдиқ кунед",
        confirmDeleteFriendText: `Оё шумо мутмаин ҳастед, ки мехоҳед руҳсатномаи "{user}" -ро аз дусти худ нест кунед?`,
        confirmDeleteFriendBtn: "Нест кардан",
    },
    "Promocode": {
        "btn": "Ман коди ташвиқотӣ дорам",
        "enter": "Коди ташвиқотӣро ворид кунед",
    },

    "RecommendedPeople": {
        "no-persons": "Merlin ҳанӯз касонаро барои шумо интихоб накардааст",
        tabs: {
            partner: "Вохӯрии тиҷоратӣ",
            love: "МуносибатМуносибат",
            coming: "Ба наздикӣ",
        },
        "type-undefined": "Таъйин нашудааст",
        toProfile: "БА ПРОФИЛ",
        "age_1": "сол",
        "age_2": "сол",
        "age_3": "сол",
        "status": "Ҳолати иҷтимоии",
        "no-status": "Бе ҳолат",
        "no-goal": "Муайян нашудааст",
        "city": "Шаҳр",
        "no-city": "Муайян нашудааст",
        "noAge": "Синн муайян нашудааст",
        "money-target": "Ҳадафи даромади ман",
        "spheres": "Майдонҳои фаъолият",
        "no-spheres": "Муайян нашудааст",
        "next": "Баъдӣ",
        "loading": "Боргирӣ мешавад...",
        category_1: "Касб ва мақсад",
        category_2: "Равобит ва оила",
        category_3: "Мотиватсия ва самаранокӣ",
        category_4: "Иқтидор дорӣ ва ишонч",
        "next-recommendation": "Шахси баъдӣ",
    },
    "SubscriptionsCategoriesItem": {
        "active": "обуна фаъол аст",
    },
    "SubscriptionsContent": {
        "active": "Обуна фаъол аст то {date}",
        category_1: "Касб ва Мақсад, FREE",
        category_2: "Равобит ва Оила, FREE",
        category_3: "Мотиватсия ва Самаранокӣ, FREE",
        category_4: "Иқтидор дорӣ ва Ишонч, FREE",
        cancelTitle: "афтаро",
        cancelText:
            'Шумо муайян мекунед, ки мехоҳед {period} обуна дар "{category}" ро бекор кунед?',
        cancelBtn: "обунаро бекор кунед",
        mounthly: "маҳона",
        annual: "солона",
    },
    SubscriptionsTableForm: {
        payBtn: "Ба пул пардозӣ биравед",
        withBalance: "Пардози пул аз баланси сайт намуда мешавад",
    },
    SubscriptionsTableInfo: {
        discount: "Тахфифи шумо: {discount}&nbsp;Рубл",
        chooseAnnual:
            "Обунаи солонаро интихоб кунед<br/>ва {discount}&nbsp;Рубл иҷбор кунед",
        active: "Обунаи фаъол",
    },

    Subscriptions: {
        title: "Категорияи обунаро, ки шумо дар он муайян кардаед, интихоб кунед",
        canceled: "Обуна бо муваффакият бекор шуд!",
        succeeded: "Бо муваффакият обуна шудед!",
        choosePlan: "Тарифро интихоб кунед:",
    },
    "about": {
        "title": "Хуш омадед ба лоиҳа merlinface.com!",
        "text-1": "Муфассалоти умумӣ",
        "text-2":
            "Гуруҳимон Шабакаи Асабиро, тавассути он шумо метавонед нақша ва матни диагнози таваҷҳӣ, хотира, соҳаи эҳсосӣ-иродаванда, рафтор, чӯнин ҳамчун бисёр пешниҳодҳои инфродӣ ки ба ин шоха менасиб аст, дарёфт кунед.",
        "text-3":
            "Дар диагностикаи Корбарон мутахассисони ихтисосӣ ҳам кар мекунанд: невропсихологон, терапевтҳои рафтории когнитивӣ ва муҳандисон дар соҳаҳои дигар.",
        "text-4": "Назаротҳои пайдованд!",
        "vk": "Гуруҳимон дар VKontakte:",
    },
    "cookie": {
        "title": "Сиёсати Cookie",
        "text-1":
            "Cookies донишоҳои хурди матнанд ки аз сервер фиристода ва дар компютери Корбар захира мешаванд. Cookies бисёр карҳои мехорад, масалан, танзимоти эҷодшударо захира мекунанд, баҳамдиҳи инки пас аз идома метавон ба мабайни саҳифаҳо гузаронид ва бо умуман бо сомона кор мерасонанд.",
        "text-2": "Мо Cookies истифода мебарем барои:",
        "text-3":
            "1. Шинохтани ҳувият — cookies ба шумо имкон медиҳанд дастгоҳ ва Ҳисоби шуморо, агар истифодабарандаи шумо ва гузарвожаи худро ворид кунед, шинохт кунанд ва аз онҳо дар ҳар бор, ки ба саҳифаи дигар меравед, дахл накунад.",
        "text-4":
            "2. Статистика — cookies ба шумо имкон медиҳанд, барои ҷамъ кардани додаҳо дар бораи тамоши ба саҳифаҳои хусусӣ.",
        "text-5":
            "Шумо метавонед браузери худро аз қабул кардани cookies маня кунед, аммо ин роҳи кори шуморо ба шахси касабат маҳдуд мекунад.",
    },
    "payments": {
        "title": "Пардохт ва гирифтани фармонд",

        "text-1": "Пардохти то бо каартои бонкї",
        "text-2":
            "Маркази навиди Робокасса — хизматрасони расмїи VISA ва MasterCard — пардохтҳоро дар сомона қабул мекунад.",
        "text-3":
            "Барои пардохт дар интернет шумо метавонед аз каартои бонкїи Мир, Visa, Visa Electron, MasterCard ва Maestro истифода барад. Агар каартои шумо ба 3D-Secure яфт шуда бошад, пардохти шумо бо пароли яквахтавї иҷозат медиҳад.",
        "text-4":
            "Ворид кардан ва пардозиши маълумотҳои ҳассоси пардохт дар тарафи маркази пардозиш андомада мекунад. Маълумоти пардохт ба бонк дар шакле рамзгузорӣ шуда бо канолҳои бехатар тавассути мекунад. Не касе, дар ҷумла фурӯшанда, имконияти гирифтани саволоти каартои бонкїи воридшудаи муштарӣ ро надорад, ки ба иборат аз гаронии полни дарояд ва маълумоти шахсӣ.",
        "text-5":
            "Баъд аз пардохти муваффақ, квитанцияэлектронӣ ба имейл эълончии пардохт фиристода мешавад, ки барои тасдиқи пардохт ва дороии идентификатори ягонаи он.",
        "text-6":
            "Дар сурати баргашти пардохт, пул ба ҳамон каарто баргардонида мешавад, аз кадом пардохт амал шуд.",
        "text-7":
            "Ҳар гуна саволот дар бораи раванди пардохт тавассути мухосибони дастгирӣи Robokassa 24/7 те раҳи телефон +7 499 110 57 25 ё навиштан ба support@robokassa.ru пурсида мешавад",
        "text-8": "Пардохт бо Яндекс.Пул, Webmoney, Qiwi",
        "text-9":
            "Шумо метавонед барои фармоиштани худ дар ҳамин сомона merlinface.com тавассути кишварӣ Яндекс.Пул, WebMoney, QIWI пардохт кунед. Амнияти пардохт тавассути маркази пардозиши Робокасса пешбинӣ мекунад.",
        "text-10":
            "Дар сурати баргашти пардохт, пул ба ҳамон киси пардохт баргардонида мешавад, аз кадом пардохт амал шуд.",
        "text-11":
            "Агар шумо дар бораи пардохт тавассути e-кисе факат саволон доред, шумо метавонед бо хидматрасонии дастгирии имодаги барои Robokassa ба шумораи телефон +7 499 110 57 25 сар мекунед.",
        "text-12": "Феҳрист программҳо ва маълумотҳо барои харид:",
        "text-13":
            '1. Хидмати "Профeссия ва Баландши кардани худ". Таъмини тавсифи Корбар дар бораи баландшии себа ва тавсияҳо дар бораи пешаҳои монанд. Нарх аз 150 то 500 сом.',
        "text-14":
            '2. Хизмати "Қадрдонии худ". Таъмини тавсифи корбар ва тавсияҳо дар ба сабаб қадрдонии худ. Нарх аз 150 то 200 сомон.',
        "text-15":
            '3. Хидмати "Мотивация ва Энергия". Таъмини тавсифи Корбар ва тавсияҳо дар ба қадрдонии себа. Нарх аз 150 то 500 сом.',
        "text-16":
            '4. Хидмати "Алоқа ва Фамилия". Таъмини тавсифи Корбар ва тавсияҳо дар сабаби алоқаҳо бо одамони дигар. Нарх аз 100 то 400 сом.',
        "text-17":
            '5. Хидмати "Сохтории Алоқа" нишон медиҳад, шартнома дар фоиз. Нархи 50 сом.',
        "text-18":
            '6. Хизмати "Умумияти замима" нишон медиҳад дар фоиз. Нархи он 25 рубл аст.',
        "text-19":
            '7. Хидмати "Сохтории кор" нишон медиҳад дар фоиз. Нарх 100 сом.',
        "text-20":
            '8. Хидмати "Чӣ тавр дар кор равед" дар фоизи сабоқ нишон медиҳад. Нархи он 50 сом аст.',
        "text-21":
            '9. Хидмати "Чӣ тавр бо одамон муошират мекунад" дар фоизи сайт нишон медиҳад. Нархи он 50 сом аст.',

        "text-22":
            '10. Хизмати "Имконияти дар санъати ҷангғузасӣ" дарасали ро дар фоиз нишон медиҳад. Нархи он 50 рубл.',
        "text-23":
            '11. Хизмати "Имконияти чемпион" дарасали ро дар фоиз нишон медиҳад. Нархи он 50 рубл.',
        "text-24":
            "Маблағ барои харидҳои ҷалби дар ҳолати тақоббу истефода баропчиҳои истифодабаранда, ёшунаве ифода додани блоки тавсифи мундарид барои ҳар корбар аз баланс дар дохили система кастр мешавад.",
        "text-25":
            "Баъди пардохт муваффақ, хизмати дар sahifaи istifodabaronдае дар merlinface.com баста мешавад. Хизмат бе анҷом дода мешавад.",
        "text-26":
            'Баргашти пул таҳти қонунии Ҷумҳурии Русия аз рӯзи 07.02.1992 ҳолати № 2300-I "Об охране прав потребителей" танзим шудааст.',
    },
    "privacy-policy": {
        "title": "",
        "text-1": "Муфассили ҳукми асосӣ",
        "text-2":
            'Сиёсати ҳифзи асрории нишондор аз талаботи қонуни Федерасияи Русия аз 27.07.2006 оянда № 152-ФЗ "Дар бораи иттилооти шахсӣ" мавриди ишкол мегирад ва қарор медиҳад рои кирдори ишлови иттилооти шахсӣ ва чораҳо барои истефодабарандаии иттилооти шахсӣ Merlin AI LLC (то боз баъд – Administration).',
        "text-3":
            "1.1. Administration нишон додан ва истеъмоли иттилооти шахсӣ, аз ҷумла ҳоқуқ барои ҳифзи асрор, иттилооти афотини шахсӣ ва хонадонӣ сабаби асосии дар фаъолияти он мегирад.",
        "text-4":
            '1.2. Сиёсати Идора дар бораи пардозиши иттилооти шахсии ("Сиёсат") ба ангумъи иттилоот муносиб мебошад, ки Идора метавонад дар бораи зоиронхои сомона https://merlinface.com ва барномаи мобилӣ Merlin Dating дарёфт кунад',
        "text-5": "2. Тафсироти асосии истифода шуда дар Сиёсат",
        "text-6":
            "Бастани иттилооти шахсӣ – зудии иттилооти шахсии равна мекунад (ба гузориш мекунад, агар ишлов барои ниҳон кардани иттилооти шахсӣ наздик бошад);",
        "text-7":
            "Вебсайт – гурӯҳи материоли тасвирӣ ва иттилооти, ҳамчунин барномаги компютерии ва базаи маълумотӣ, ки онҳоро ба Интернет-доро мегирад https://merlinface.com ва барномаги дурушӣ Merlin Dating.",
        "text-8":
            "Системаи иттилооти шахсӣ – гурӯҳи иттилооти шахси дар базаи маълумоти шахсӣ, ва огоҳӣ додани корkонани онҳоро бо истифодаи фановари иттилоот ва асбоби техникӣ;",
        "text-9":
            "Деперсонификацияи иттилооти шахсӣ – амалҳо, аз натиҷаи он, намекунад, ки бидуни истифода аз иттилооти иловагӣ дигар, чӣ маълумотҳо ба истифодабарандаи мушаххаси ё дигар сабҷектҳои иттилооти шахсӣ таалуқ дорад;",
        "text-10":
            "Ишлови иттилооти шахс – ҳар фикр (амалият) ё гирдоруви фикрҳо (амалиётҳо) чун он, ки бо истифода кардани ё бе истифода кардани иттилооти шахсӣ амалиоти автоматизироӣ иҷро мекунад , аз ҷумла ҷамъ, сабт, систематизироӣ, анборкории, захира, рошнасоӣ (навсозии, тағйировт), бурдуриқо, истифода, даргирийот (тақсим, таъминот, дастрас), анонимизатсия, блокировкаи, бардоштани, ва тармояи иттилооти шахс;",
        "text-11":
            "Maмuriят – юридикӣ ё инсони физикӣ, ки мустақили ё бо дигарон, ташкили ва (ё) ишлови иттилооти шахсӣ ро иҷро мекунанд, ҳамчунин муайида мекунанд ишлови иттилооти шахсӣ, тахлили иттилооти шахсӣ барои ишлов, амалиёт (амалиётҳо) бо иттилооти шахсӣ иҷро шудааст;",
        "text-12":
            "Иттилооти шахсӣ – ҳар дохили иттилоот ба тавассути Мутозаъд ба мутаайыншудаи ё мутаайынида шавандагӣ Истифодабарандаи veb-sayti https://merlinface.com ва барномаги дурушӣ Merlin Dating",
        "text-13":
            "Истифодабарандагӣ - ҳар зиёркунандаи https://merlinface.com ва барномаги дурушӣ Merlin Dating",

        "text-14":
            "Расм ба назар кашидани иттилооти шахсии - фаъолиятҳои мурочиат ба меҳрандиши иттилооти шахсиро ба шахси хоси ё дурои шахсӣ истода;",
        "text-15":
            "Гузаронидани иттилооти шахсӣ - ҳар гуна фаъолият, ки муради он расм кашидани иттилооти шахсии ба дурои маҳна намудан (интиқоли иттилооти шахсӣ) ё бо иттилооти шахсӣ ба маҳна номеъюни ашна шудани, аз ҷумла интишори иттилооти шахсии дар русумот, гузоштани он дар шабакаҳои иттилоотӣ ва телекоммуникатсионӣ ё таъмини дастрасӣ ба иттилооти шахсии ба ҳар тарзе дигар;",
        "text-16":
            "Интиқоли байналмилалӣ иттилооти шахсӣ - интиқоли иттилооти шахси ба ҳуқуқи хоричӣ, органи хоричӣ, афроди фарди хоричӣ ё органи ҳуқуқи хоричӣ;",
        "text-17":
            "Таҳкиккати иттилооти шахсӣ - ҳар гуна фаъолияти бо барои таҳкиккати иттилооти шахсии натоҷа додани барои қобилияти барқарор кардани мазмуни иттилооти шахсӣ дар системаи иттилооти шахсии ва (ё) таҳкиккати воситаҳои материалии иттилооти шахсӣ.",
        "text-18":
            "Пардохт - мукофоти Ма'mūrīyat, пули, ки истифодабаранда ба қарордоди Ма'mūrīyat бо иродаи омӯзиш пухта шудааст.",
        "text-19":
            "3. Ма'mūrīyat метавонад иттилооти зерини Шахсии корбарро пайваст кунад:",
        "text-20": "3.1. Насаб, ном, номи падар;",
        "text-21": "3.2. Суроғаи электронӣ;",
        "text-22": "3.3. Шумораҳои телефон;",
        "text-23": "3.4. Сол, моҳ, сана ва ҷойи таваллуд;",
        "text-24": "3.5. Суроғаҳо;",
        "text-25":
            "3.6. Иттилоот дар бораи таҳсил, ремоқ, ихтисос ва саводкамиҳо, маълумотҳои барои ҳуҷҷатҳои таҳсилӣ;",
        "text-26":
            "3.7. Вебсайт низ иттилооти номеъюни боздиҳандаҳоро ҷамъ ва париваш мекунад (эҷодияҳо, кукиҳо) бо истифодаи хидматҳои омории Интернет (Метрикаи Yandex ва Google Analytics, ва ғайра.).",
        "text-27":
            "3.8. Вебсайт метавонад бо иштироки Корбар ё ба шакли худкор иттилооти такдими, аз ҷумла нишонии IP, нусхаи браузер, кишвар, забон, тегҳои аффилиат, суроғаи саҳифа, ки корбар ба Вебсайт дорои он шудааст, ва маълумотҳои техникии дигарии, ки метавонанд барои ташхиси корбар бе қадами иловагии истифода шаванд.",
        "text-28":
            "3.9. Метавонанд аз Корбар иҷозатхоҳӣ намояд ба дастрасии ба ҳисобҳои хидматҳои трафикдохтарон, аз ҷумла, вале не омехта, ба анбори файлҳои умумӣ, паёми фаври, шабакаҳои иҷтимоӣ, ва ғайра, то барои истифода аз хидматҳои мукталифи Вебсайт. Дар ин маврид, Интернет-сайт метавонад иттилооти шахсии иловагии аз трафикдохтарони трафикдохтар ро бигирад, вале не омехта, ба ҷинс, макон, аватари корбар (userpic) ва ғайра. Ҳамаи иттилоот, ки аз тарафи оромгари хизматрасонӣ трафикдохтарӣ дастрас аст, пайваст ва захираки шуда ба мавриди Шартномаи Корбар ва Сиёсати махфият.",
        "text-29": "4. Мақсадҳои Равонкунӣ иттилооти шахсӣ",

        "text-30":
            "4.1. Мақсади коркарди Иттилооти Шахсии Корбар ба яқини ҳисоботи коркарди Корбар ва кабинети шахсии барои кории бо Вебсайти ва Иловаи Мобилии; иттилоот додани корбар шугунан бо фиристодани пиғомҳои электронӣ ё хабарҳо; пешкиш кардани хизмати муштарӣ ва пуштибонии техникӣ; додани дастрасии ба хизматрасонихо, маълумот ва/ё материалҳои мавҷуд дар Маҳсулот ва ба Вебсайт ва дар Илова.",
        "text-31":
            "4.2. Интизомия низ ҳақдор аст ба Корбар дар бораи маҳсулот ва хизматрасониҳои нав, таклифоти махсус ва моҳимоти гуногун паёми мубарка гузоранд. Корбар ҳамеша аз қабул кардани паёмҳоро рафтени метавонад, бо фиристодани паёми электронӣ ба Интизомия дар info@merlinface.com бо яддоштаи «Рафтан аз паёмҳои дар мораи маҳсулот ва хизматрасониҳои нав ва таклифоти махсус».",
        "text-32":
            "4.3. Ба асоси таврӣ ки Корбар бо Сомона вобастагӣ кор мекунад, барои Корбар паёмҳои фардии бозорӣ дар мораи маҳсулот ва хизматрасониҳои махсуси ма, ҳамчун маълумоти дар бораи коркарди бо Сомона, ки ба танзим кардани ҳамлаҳои таблӣготӣ компаания мерасад, барои мисол, намоиш додан реклама дар шабакаҳои иҷтимоии ҳама ҳангомӣ айде затонӣ таҳияшуда ки корбари ма ҳаст.",
        "text-33":
            "4.4. Маълумоти Корбари беонвон коркарди бо хизматҳои омории Интернет истифода шуда ба саволи ҷамъ аз маълумоти амалҳои корбар дар сомона, ба саволи таъмини кайфияти сомона ва мазмуни он.",
        "text-34":
            "4.5. Дар вақти Корбар корҳо дар myAlpari вақте, бо мақсади амният ва пешгирии аз корҳои макрӯҳ, фаъолиятҳои зерин рӯй дода мешаванд: рӯзувақти сабт ва иҷозатнома, рӯз ва вақти абонемент ва хариди дигар намудҳои хизматрасониҳои таҳияшуда, рӯзувақти фиристодани паёмҳо, рӯз ва вақти нест кардани саҳифаи профил.",
        "text-35": "5. Асоси хоқуқии коркарди Иттилооти Шахсӣ",
        "text-36":
            "5.1. Интизомия иттилооти шахсии корбарро танҳо агар корбар онро худбахуд пурра мекунад ва/ё навиштаи онро ба Интизомия марбут ба формаҳои махсуси қарор додашуда дар сомона ва платформаи мобил мебаёнад. Бо пурра навистани формабардахои лозим ва/ё фиристодани иттилооти шахсии худ ба Интизомия, Корбар розии худ барои ин-сиёсат иборат мешавад.",
        "text-37":
            "5.2. Агар ба Интизомия мақсад ба коркарди маълумоти беонвони корбар дар сазгориҳои браузери корбар мебошад (анчом коркарди cookie ва истифодаи технологияи JavaScript ҷой), Администратор Иттилооти Беонвони Корбарро мекорад.",
        "text-38":
            "5.3. Корбар метавонад дар вақти вобастаги ба ва истифодаи хизматҳои шахси сохта шуда дар сомона икко-ана иттилооти шахсиро пешниҳод кунад.",
        "text-39":
            "6. Тартиботи ҷамъ, захира, интиқол ва коркарди дигар дар Иттилооти Шахсӣ",
        "text-40":
            "6.1. Амнияти додохои шахсӣ, ки аз тарафи маъмурият ислоҳ мешаванд, бо амалиёти қонунӣ, ташкилотӣ ва техникӣ, ки зарурат барои таъмини парвонахои мавҷуд дар соҳаи ҳифзи додохои шахсӣ, таъмин мешавад.",
        "text-41":
            "6.2. Маъмурият бояд ҳамаи ихтиёроти техникӣ ва ташкилотӣ зарурати мушаххас кунад, то додохои шахсии корбарро аз дастрасиҳои бегуна ва ғайриқонунӣ, нобуд кардан ё иваз кардан, қуфл кардан, нусхабардорӣ кардан, инкишоф додан ё амалоти дигари бегуна ҳифз кунад. Дар сурати иттилоотёбӣ ё инкишофи додохои шахсии корбар, маъмурият ба корбар дар бораи иттилоотёбӣ ё инкишофи додохои шахсии худ, иттило намояд.",
        "text-42":
            "6.3. Мумкин аст, корбар барои додохои шахсӣ ва иттилоот дигар аз тарафи солиҳои солис барои масалан, пул пардохт кардан, ё иловагии вижагиҳои иловагӣ бо хизматрасониҳои солиси бегона, пурсида шавад. Корбар аз худи худ додохои шахсии худ ва иттилоот дигар мефирузад. Бояд ҳамаи додохои шахсии дархостшуда аз тарафи солиҳои солис бо раъванд ва сиёсати расмиа дар мавред назари анҳо ислоҳ ва нигоҳ дошта шаванд.",
        "text-43":
            "6.4. Маъмурият метавонад Додохои шахсиро ба таъминкунандагони хизматрасонӣ ки ба номи маъмурият хизматрасонӣ меоянанд, интиқол диҳад. Барои намуна, маъмурият мумкин аст солиҳои солисро барои таъмини пуштибонии муштариён, идора кардани табрикунаро дар бунёдҳои бегона, аллагии маркетинг ва поёндагихои дигар дар номи маъмурият ё ёрдами нигоҳдории додохо фаромӯш кунад. Солиҳои солисро манъ мекунанд, то додохои шахсии корбаронро барои мақсадҳои табрикунӣ истифода баранда кунанд.",
        "text-44": `6.5. Агар дар Додохои шахсии вағиъотҳои бевосъита вуҷуд дошта бошанд, корбар метавонад онҳоро аз тарафи худи худ, нав кунад, то ба электроники маъмурият фиристод, ёддошти law@merlinface.com \"Барӯзгарии Додохои Шахсӣ\".`,
        "text-45": `6.6. Даврзмини ислоҳи Додохои Шахсӣ беҳадӣ аст. Корбарон метавонанд розии худро аз ислоҳи Додохои Шахсӣ дар ҳар вақте, бо фиристодани ёддоши ба маъмурият тавассути электроника саркоб кунанд дар нишонаи электроники law@merlinface.com, аз "Ради розии ислоҳи додохои шахсӣ". Барои сабабҳои техникӣ, мумкин аст, иттилоот ба фурӯ дида шавад, нафакат бо таъхир, балки немедониста ба танҳоистифода баргардонида шавад. Шумо бояд низ дар назар бигиред, ки онҳо мумкин аст, ба ҳади ки зарурат дар роҳи барки оперативтаррои иттилоот, ҳалли ихтилофот, пешгири аз фрод ва ҳифзи мақсадҳои қонунӣи маъмурият, баъзе иттилоотро зергир кунанд.`,
        "text-46":
            "6.7. Суратҳои солиҳои солис, ки тамом шуда буданд, аз тарафи корбар, захира нашаванд ва аз таври хизмат барои шарҳи додохо ва я расмоварз кардани вирустии Шахсии набояд истифада шавад.",
        "text-47":
            "6.8. Сайт фақат оператори Додохои Шахсӣ ба нисбати додхои, ки онро бепасандид, барои корбар аз тарафи Сайт ва фақат иттилоотро, ки корбари боркушода меставонад, барои ёфтани мақсадҳо, истифода менамояд.",
        "text-48":
            "6.9. Истифодабарии иттилоҳоти корбар ва суратҳои бор шуда, бо интиқоли сурат то Сервер мебошад. Алгоритми Merlin рӯи черо гулураки сурат мекошида, то параметрҳо ва вақеъатҳои хусусиятҳо ё ёддошта шаванд. Суратҳои корбар дар Сервер захира нашаванд ва аз таври Хизмат ва/ё солиҳои солис истифода нашаванд. Андоза ва вақеъати хусусиятҳо бе интиқол ба солиҳои солис дар Сервер захира мешаванд.",
        "text-49":
            "6.10 Хизмат дар олама ва истифодаи маълумот дар бораи андоза ва ифодаи хусусият мехонад:",
        "text-50":
            "муқоисаи параметрҳои двайӯи хусусиятҳои двайӯ дар бораи шинахти воқеъӣ, мушобиҳат ва индикаторҳои дигар мутобиқ рӯшноии муаллиф дар алгоритми Мерлин;",
        "text-51":
            "интихоби шахсиҳои мусобиқа дар барои Корбар мутобиқ методологии муаллиф, ки дар алгоритми Мерлин ҷамъ шудааст;",
        "text-52":
            "ҷустуҷӯи шахси дар саросари параметрҳои хусусият дар базаи маълумотии Хизмат;",
        "text-53":
            "Натиҷаҳои мусобиқати айни ра дар оянда барои Корбарони Сайт ва Илова нигоҳ доред.",
        "text-54": "7. Сиёсати пардохт",

        "text-55":
            "7.1. Пулл аз рӯи обунаи, ки корбар аз хизматрасонии сомона фоидабурӣ мекунад, бо истифодаи як аз системаҳои пардохт шавад, ки дар онхо, вале намеҳдуд ба: Visa, MasterCard, Maestro, MIR, Яндекс.Пул, Вебмани, Киви, ЭпплПэй, ГуглПэй, ПейПал. Ин системаҳои пардохт маълумоти молиёвӣро сабт ва зеро гузаронед мутабиқи Шартномаи корбариашон ва Сиёсати махфият.",
        "text-56":
            "7.2. Маъмурият иттилооти пурраи картиро сабт намекунад ва пардохтҳоро пардахт намекунад, фақат огоҳии дар мавриди пардохт муваффақ аз системаи пардохт дарёфт мекунад.",
        "text-57":
            "7.3. Маъмурият метавонад иттилооти иловагиро марбут ба пардохти эътимод шуда тавассути корбарро ҷамъовар гардонад, ки дар онхо, вале намеҳдуд бар раками муомала, вақти муомала, навъ ва санаи анҷоми карти истифода шуда барои пардохт, ва чор чифрҳои охирини раками карти, номи соҳиби карт, кишвар ва шаҳр дар кадом карти вақте усули пардохт аст.",
        "text-58": "8. Интиқоли шахсии ободи маълумот",
        "text-59":
            "8.1. Қабла аз интиқоли шахсии ободи маълумот ба хориҷ, Идора бояд мавқеъ ба он кадом сарзамини хориҷӣ, ки қарор даод, ки интиқоли маълумоти шахсии ободи анҷом ябад, итминони мустаҳкамро дар бораи ҳуқуқҳои шахси маълумоти ободи мушаххас кунад.",
        "text-60":
            "8.2. Интиқоли шахсии ободи маълумот ба кишварҳои хориҷӣ, ки ба шартҳои азал даркорӣ дар оласаям мерасонанд, фақат агар шахси маълумоти обод бо интиқоли шахсии ободи додашудааш ба хориҷ ва / ё иҷрои шартнома мувофиқи киштӣ аст.",
        "text-62": "9. Масъулият ба ҷонибҳо.",
        "text-63":
            '9.1. Агар истифодабаранда маълумоти шахсии ободӣ ро пайпус кунанд, танҳои истифода баранда масъул аст ба бо даркорӣ ба маълумоти шахсии ободӣ, мутабиқ бо муоина расонидани нигоҳдородӣ ихтисосӣ (регулировкаи умумии маҳфият, GDPR), қонунни Федерали № 152-FZ дар 27.07.2006 "Дар бораи маълумоти фард", ва қонуни дигар ва муоиноти дигар, ва чунин ҳам дарёфти ҳаволадориҳо, қарордоди дар сомонаи истифодабаранда барои барои худ гузазонидани маълумот ва иттилооти лозим.',
        "text-64":
            "9.2. Дар сурати надоди иҷрои масъулият, Маъмурият масъулиятро барои ҳафтгӣ барои андешагии зарарҳо мегирад, вале не бештар аз пояд корбар дар боьи ҷории корбар таҳтии тазмин будемиг, бепасандида аз истифодаи номусовори маълумоти шахсии ободи корбар, мутабиқ бо қонунҳои Русия, мустасна дар мавориде, ки маълумоти шахсии",
        "text-65": "а) ба идораҳои шахрӣ муфассалан иваз шудааст;",

        "text-66":
            "b) ошкор шудааст аз тарафи якомуштариҳ баъд аз фиристодани онҳо аз тарафи идора бо иҷозаи истифодабаранда;",
        "text-67": "c) публик шудаанд пеш аз гум ё ошкор кардани онҳо;",
        "text-68":
            "d) дарёфт карда шудаанд аз тарафи якомуштариҳ пеш аз фиристодани онҳоро ба идора;",
        "text-69": "e) ошкор карда шудаанд бо иҷозаи истифодабаранда;",
        "text-70": "f) ошкор карда шудаанд натиҷаи воқеаҳои тавон накаш;",
        "text-71":
            "g) ошкор карда шудаанд натиҷаи даваи муздиҷ ба истифодабаранда дар бораи хилоф саркунӣ ба ҳуқуқи шахси се омӯзгор ва/ё шартномаи истифодабаранда.",
        "text-72": "10. Таинотӣ",
        "text-73":
            "10.1. Истифодабаранда метавонад ягон рошниоти муайян ба пурсишҳои интерес дар бораи пардозиши ахлиётии шахсӣ худ, бо тамос гиристани бо идора тавассути почтаи электронӣ law@merlinface.com.",
        "text-74":
            "10.2. Љорирати ин санад тағйироте дар сиёсати идораи пардозиши иттилооти шахсийи ро нишон медиҳад. Сиёсат то бе ниҳоят момиконад, то дар сурати ивазш кардан бо версияи нав.",
        "text-75":
            "10.3. Нусхаи ҳозирини Сиёсат бо роҳонд ба одамхо, барои фаромуш нест кардани онба, дар Интернети ҷоя шудааст: https://merlinface.com/docs/privacy-policy",
    },
    "subscriptions": {
        "choose-type": "Навъи обунааро интихоб кардани шумо дар интереси шумо",
        "cancel": "Обунаро муваффақона бекор кардед!",
        "success": "Обуна муваффақ!",
        "choose-plan": "Барномаро интихоб кунед:",
        "junior":
            "Обунаи Junior шомил ба имкониятҳои Gold дар ҳамаи 4 сафҳаҳои зерин мебошад: <br/> Касб ва худро ба анҷом расонидан, Иштирок ва Ишқ ва Далери, Мотивация ва Энергия, Муносибатҳо ва Оила",
        "before-charges": "пеш аз инки ёб кунед",
        "to-support": "ба дастгирию тамос гиристани",
        "left": "боқӣ",
        "status": "Ҳолати обуна",
        "failed-to-renew": "обунаро нав кардан нашуд",
        "auto-renew-active": "таҷдиди автоматик фаъол аст",
        "active-to": "фаъол то",
    },

    "RetakeOnboarding": {
        DEFAULT: "Навъи психоимро муайян кунам",
        PROFESSION_ORIENTATION: "Типхои профессионалиро оянда бошед",
    },
    ConnectSocial: {
        title: "Социалӣ шабакаҳоро вобаста кунед",
        enabled: "Вобаста шуд",
        enable: "Вобаста кун",
        enterEmail: "Имейл худро ворид кунед",
        codeSend:
            "Барои имейл шумо код фиристода шуд. Онро дар ин ҷо ворид кунед.",
        youGetCode: "Шумо ба имейл коди тасдиқро хоҳед гирифт",
        retryEmail:
            "Агар барои баъзеи сабабҳо имейл наомадаст, шумо метавонед бори дигар кодро фиристед <span> дар {timer} сония...</span>",
        codeError: "Код мувофиқат намекунад",
        retrySend: "КОДРО БОРИ ДИГАР ФИРИСТЕД",
    },
    FriendActions: {
        requestSend: "Ба дӯст дархост фиристода шуд",
        cancel: "Бекор кунед",
        incomingRequest: "Дархости дӯстии воридшуда, кабул мекунед?",
        accept: "Кабул кун",
        decline: "Рад кун",
        add: "Илова кун",
        sendMessage: "Паёме нависед",
        addToFriends: "Чун дӯстро илова кунед",
        partnerRequestSend: "Дархости шариксозиро фиристода шуд!",
        makePartner: "Шариксоз кун",
    },
    RecommendedPeopleCtrl: {
        title: "Тавсияҳои одамон",
    },
    AppLoading: {
        title: "Пайвастшавии Интернетро барраси кунед<br/>ва саҳифаро бори дигар боргузорӣ кунед",
    },
    ChooseFriendPhotos: {
        "analyze": "Таҳлил кун",
    },
    "ServiceCollapse": {
        "openNow": "Ҳозир кушодан",
        confirmTitle: "Боз кардани тавсияҳо",
        confirmText: `Барои кушодани тавсияҳо дар ҳолати ҷорӣ, ба шумо лозим аст, ки барои нирхи "PLATINUM" подпиша шавед.`,
        confirmBtn: "Подпиша",
    },
    LandingHeader: {
        advantages: "Чи мегиред",
        steps: "Чӣ гуна кор мекунад",
        mission: "Мақсади мо",
        enter: "Ворид кунед",
    },

    LandingBanner: {
        title: "<span>Санҷиши роҳнамои касбӣ</span><br/> Шабакаи нейронӣ 3 касбии муносибро интихоб менамояд",
        description:
            "Ба ҷаҳони зеҳни сунъӣ ворид шавед ва малакаҳои худро бо Merlin AI татбиқ кунед",
        "list": [
            "Алгоритми беназири мо қувва ва заъифҳои Шуморо таҳлил мекунад",
            "Дар интихоби касб ва муносибатҳо ба Шумо маслиҳат медиҳад",
            "Тавсияҳое, ки ба шахсияти Шумо мосанд, гиред, аксҳо эҷод кунед, муошират кунед ва омӯзед",
        ],
        btn: "Касби худро биёед",
    },
    "LandingAbout": {
        "title":
            "<span>Merlinface —</span> алгоритм бесобиқа, ки аз тарафи мутахассисони бахши сунъӣ ақл афарида шудааст",
        "list": [
            {
                "1": "Акси худро боргузорӣ кунед ва мошини нейроии мо касбҳои мувофиқро барои шумо интихоб мекунад",
                "2": "Таҷриба дар кор бо AI дар чатҳо",
            },
            {
                "3": "Вазифаҳои воқеӣ ва лоиҳаҳо",
                "4": "Бо мошини нейроии мо муошират кунед, вазифаҳои курсро иҷро кунед ва малакаҳои нейроӣ оператории худро тақвият бахшед",
            },
            {
                "5": "Донишҳои гирифтаро дар лоиҳаҳои воқеӣ дар худи чат татбиқ кунед",
                "6": "Ҳоло ояндасозиро оғоз кунед: AI-и мо барои шумо тавсияҳои инфиродии рушд омода мекунад",
                "7": "Тавсияҳо барои оғози муваффақ дар соҳаи IT",
            },
        ],
    },
    LandingAdvantages: {
        title: "Чи мегириед?",
        showVideo: "Тамошои видео",
        list: [
            {
                id: 1,
                active: true,
                code: "professional",
                title: "Typingi Kasbi",
                description:
                    "Дар банкимони мо беш аз 1000 касби гуногун мавҷуд аст. Merlin AI ба Шумо ёрӣ мекунад, ки дар 3 та зеро Шумо муваффақ хоҳед шуд.",
            },
            {
                id: 2,
                active: false,
                code: "psychotype",
                title: "Typingi Nafsӣ",
                description:
                    "Шумо рӯйхате аз тамомии зебонҳо ва нуқсонҳо ва чӣ тавр онҳо ро ишлоқӣ кунед, дода мешавад.",
            },
            {
                id: 3,
                active: false,
                code: "person-description",
                title: "Тавсифи шахсияти ва рафтори Шумо",
                description:
                    "Ҳар се умедвори сайти мо корӣ маҳбубро пайдо мекунад.",
            },
            {
                id: 5,
                active: false,
                code: "recommendations",
                title: "Tavsiyahoi Shaxsi",
                description:
                    "Merlin AI барои Шумо тақрибан 150 тавсияи инфродӣ тайёр кардааст, ки ба Шумо имкон медиҳад ба тезӣ муваффақатро дастгирӣ кунед",
            },
            {
                id: 6,
                active: false,
                code: "person-recommendations",
                title: "Tavsiyaho az dīgar aъzoi platforma",
                description:
                    "Merlin AI мунтазаман барои Шумо моташакêкашан ё адамонро, ки созгории касбии боло доранд, интихоб мекунад, дар онҳо Шумо метавонед як ҳамкори касбӣ, дост ё шарик барои равобитро пайдо кунед",
            },
            {
                "id": 7,
                "active": false,
                "code": "messages",
                "title": "Пайвандҳо ба омӯзиш бо касбҳои пешниҳодшуда\n",
                "description":
                    "Шумо метавонед бо истифодабарандагони дигаре, ки онҳо низ дар ҷустуҷӯи ҳамшарики бизнес, муносибатҳо ё мотиватсия ҳастанд, муошират кунед",
            },
            {
                "id": 8,
                "active": false,
                "code": "celebrity",
                "title": "Дастрасӣ ба маълумотномаи машҳурон",
                "description":
                    "Шумо метавонед имконияти мулоқот бо онҳо ба шахсӣ ва оғози ҳамкориро бигиред, инчунин барои мувофиқат тақобул кунед.",
            },
        ],
    },
    LandingMission: {
        title: "Вазифаи мо",
        description:
            "Сози инструмент барои ёри расонофтани худкамӣ ва ёфтани иш вобаста ба қобилиятҳои онҳо, не резюмеси онҳо.",
        memberCount: "Идентификатори аллакай дар хидмат аст",
        btn: "Пайваст кунед",
    },
    "LandingSteps": {
        "title": "Ин чӣ гуна кор мекунад?",
        "steps": [
            {
                "number": 1,
                "title": "Тугмаи \"Оғоз\"-ро дар зер зер кунед",
            },
            {
                "number": 2,
                "title": "Рақами телефони худро тасдиқ кунед",
            },
            {
                "number": 3,
                "title": "Ба чат ворид шавед ва муоширатро бо шабакаи нейронӣ оғоз кунед",
            },
            {
                "number": 4,
                "title": "Натиҷаҳо гиред"
            },
            {
                "number": 5,
                "title": "Рӯйхати касбҳоеро, ки аз зеҳни сунъӣ ба шумо мувофиқанд, гиред",
            },
        ],
        "result": {
            "title": "Шумо натиҷаро ба даст меоред",
            "list": [
                "Дастрасӣ ба шабакаҳои нейронии маъмул дар як ҷо",
                "Қобилияти гирифтани ҷавоб ба ҳама гуна саволҳо аз Merlin AI",
                "Қобилияти эҷоди тасвирҳо дар асоси ҳама гуна матн"
            ],
        },
        "btn": "Оғоз",
    },
    LandingReviews: {
        title: "Онҳо чӣ гуфтанд дар бораи Merlinface:",
    },
    MerlinLimit: {
        title: "Саволи маҳдудиат барои имруз - 5/5",
        desc: "Барои идома додани гуфтугу бо Merlin AI, ба ҳар тарифи пулӣ гузаронед",
        btn: "Тарифҳо ро намоиш диҳед",
    },

    SubscriptionsData: {
        PROFESSION: {
            TITLES: [
                {
                    id: "PROF_OPTION_1",
                    name: "Тавсифоти шахсии шумо барои худроиш",
                },
                {
                    id: "PROF_OPTION_2",
                    name: "Тавсифоти шахсии барои худроиш",
                },
                {
                    id: "PROF_OPTION_3",
                    name: [
                        "Нишондиҳандаҳои дастрас:",
                        "Созишниёфти тиҷорати",
                        " Равобити тиҷорати",
                        "Хушуни неруӣ",
                        "Хушуни эҳсосат",
                    ],
                },
                {
                    id: "PROF_OPTION_4",
                    name: "Тести мувофиқати тиҷоратии ситораҳо",
                },
                {
                    id: "PROF_OPTION_5",
                    name: "Бозории шарикони тиҷорати ба астоди Merlinface",
                },
            ],
            FREE: [
                {
                    id: "PROF_OPTION_1",
                    value: "30% кушода",
                },
                {
                    id: "PROF_OPTION_2",
                    value: "1 дар ҳафта",
                },
                {
                    id: "PROF_OPTION_3",
                    value: "1 аз мушаххас карда шуда дар рӯз",
                },
                {
                    id: "PROF_OPTION_4",
                    value: "—",
                },
                {
                    id: "PROF_OPTION_5",
                    value: [
                        "1 дар ҳафта",
                        "1 барои менабардаштан",
                        "то 1 таъмин мешавад",
                    ],
                },
            ],
            GOLD: [
                {
                    id: "PROF_OPTION_1",
                    value: "пурра кушода",
                },
                {
                    id: "PROF_OPTION_2",
                    value: "1 дар се рӯз",
                },
                {
                    id: "PROF_OPTION_3",
                    value: "1 аз мушаххасан дар соат",
                },
                {
                    id: "PROF_OPTION_4",
                    value: "1 дар моҳ",
                },
                {
                    id: "PROF_OPTION_5",
                    value: [
                        "1 дар се рӯз",
                        "1 барои менабардаштан",
                        "то 3 пайдо мешавад",
                    ],
                },
                {
                    id: "PROF_OPTION_6",
                    value: "1 дар ҳафта",
                },
            ],
            PLATINUM: [
                {
                    id: "PROF_OPTION_1",
                    value: "пурра кушода",
                },
                {
                    id: "PROF_OPTION_2",
                    value: ["1 дар рӯз"],
                },
                {
                    id: "PROF_OPTION_3",
                    value: "биҳудуд",
                },
                {
                    id: "PROF_OPTION_4",
                    value: "1 дар ҳафта",
                },
                {
                    id: "PROF_OPTION_5",
                    value: [
                        "1 дар рӯз",
                        "1 барои менабардаштан",
                        "5 таъмин мешавад",
                    ],
                },
                {
                    id: "PROF_OPTION_6",
                    value: "1 дар ҳафта",
                },
            ],
        },

        SELF_ESTEEM: {
            TITLES: [
                {
                    id: "ESTEEM_OPTION_1",
                    name: "Тавсифоти инфиродӣ барои офаринишни ихтиёроти худ",
                },
                {
                    id: "ESTEEM_OPTION_2",
                    name: "Тавсияҳои шахсии барои афзоиши ихтиёрати худ",
                },
                {
                    id: "ESTEEM_OPTION_3",
                    name: [
                        "Ишораҳои дастрас",
                        "Хусусийатҳои мушобеҳ",
                        "Равобити иштимаї",
                        "Равобити бо одамон",
                    ],
                },
                {
                    id: "ESTEEM_OPTION_5",
                    name: "Интихоби одамон барои мубодилаи таҷриба",
                },
            ],
            FREE: [
                {
                    id: "ESTEEM_OPTION_1",
                    value: "30% кушода",
                },
                {
                    id: "ESTEEM_OPTION_2",
                    value: "1 дар ҳафта",
                },
                {
                    id: "ESTEEM_OPTION_3",
                    value: "1 аз мушаххасшуда дар рӯз",
                },
                {
                    id: "ESTEEM_OPTION_4",
                    value: "—",
                },
                {
                    id: "ESTEEM_OPTION_5",
                    value: [
                        "1 дар ҳафта",
                        "1 барои ман интихоб мешавад",
                        "ба 1 шумо мерасед",
                    ],
                },
            ],
            GOLD: [
                {
                    id: "ESTEEM_OPTION_1",
                    value: "пурра кушода",
                },
                {
                    id: "ESTEEM_OPTION_2",
                    value: "1 дар се рӯз",
                },
                {
                    id: "ESTEEM_OPTION_3",
                    value: "1 аз мушаххасшуда дар соат",
                },
                {
                    id: "ESTEEM_OPTION_4",
                    value: "1 дар моҳ",
                },
                {
                    id: "ESTEEM_OPTION_5",
                    value: [
                        "1 дар се рӯз",
                        "1 барои ман интихоб мешавад",
                        "ба 3 шумо мерасед",
                    ],
                },
                {
                    id: "ESTEEM_OPTION_6",
                    value: "1 дар ҳафта",
                },
            ],
            PLATINUM: [
                {
                    id: "ESTEEM_OPTION_1",
                    value: "пурра кушода",
                },
                {
                    id: "ESTEEM_OPTION_2",
                    value: ["1 дар рӯз"],
                },
                {
                    id: "ESTEEM_OPTION_3",
                    value: "бе маҳдуд",
                },
                {
                    id: "ESTEEM_OPTION_4",
                    value: "1 дар ҳафта",
                },
                {
                    id: "ESTEEM_OPTION_5",
                    value: [
                        "1 дар рӯз",
                        "1 барои ман интихоб мешавад",
                        "ба 5 шумо Мерасед",
                    ],
                },
                {
                    id: "ESTEEM_OPTION_6",
                    value: "1 дар ҳафта",
                },
            ],
        },

        MOTIVATION: {
            TITLES: [
                {
                    id: "MOTIVATION_OPTION_1",
                    name: "Тавсифоти шахсии Шумо барои афзоиши алодагии ма'рифишахсӣ",
                },
                {
                    id: "MOTIVATION_OPTION_2",
                    name: "Тавсияҳои шахсӣ барои афзоиши алодагии ма'рифишахсӣ",
                },
                {
                    id: "MOTIVATION_OPTION_3",
                    name: "Интихоби одамони мотавассите",
                },
            ],
            FREE: [
                {
                    id: "MOTIVATION_OPTION_1",
                    value: "30% кушода",
                },
                {
                    id: "MOTIVATION_OPTION_2",
                    value: "1 дар ҳафта",
                },
                {
                    id: "MOTIVATION_OPTION_3",
                    value: [
                        "1 дар моҳ",
                        "1 барои ман интихоб карда мешавад",
                        "1 ба шумо хит мезанад",
                    ],
                },
            ],
            GOLD: [
                {
                    id: "MOTIVATION_OPTION_1",
                    value: "ба тамомӣ кушода",
                },
                {
                    id: "MOTIVATION_OPTION_2",
                    value: "1 дар се рӯз",
                },
                {
                    id: "MOTIVATION_OPTION_3",
                    value: [
                        "1 дар се рӯз",
                        "1 барои ман интихоб мешавад",
                        "шумо 1 мегирад",
                    ],
                },
            ],
            PLATINUM: [
                {
                    id: "MOTIVATION_OPTION_1",
                    value: "ба тамомӣ кушода",
                },
                {
                    id: "MOTIVATION_OPTION_2",
                    value: ["1 дар рӯз"],
                },
                {
                    id: "MOTIVATION_OPTION_3",
                    value: [
                        "1 дар рӯз",
                        "1 барои нест интихоб мешавад",
                        "1 ба шумо хит мезанад",
                    ],
                },
            ],
        },

        RELATIONS: {
            TITLES: [
                {
                    id: "RELATIONS_OPTION_1",
                    name: "Хусусиятуни муносибати шумо",
                },
                {
                    id: "RELATIONS_OPTION_2",
                    name: "Тавсияҳои инфродӣ барои муносибати беҳтар",
                },
                {
                    id: "RELATIONS_OPTION_3",
                    name: [
                        "Ишораҳои дастрас:",
                        "Созгории муносибат",
                        "Муносибат бо одамон",
                        "Хасму хунӣ умумӣ",
                        "Хасму хунӣ ҷасадӣ",
                        "Хасму хунӣ эҳсосӣ",
                        "Хасму хунӣ эҷтимоӣ",
                    ],
                },
                {
                    id: "RELATIONS_OPTION_4",
                    name: "Муқоисаи софияи созгории муносибат",
                },
                {
                    id: "RELATIONS_OPTION_5",
                    name: "Интихоби шарикӣ барои муносибат",
                },
            ],
            FREE: [
                {
                    id: "RELATIONS_OPTION_1",
                    value: "30% ошкор",
                },
                {
                    id: "RELATIONS_OPTION_2",
                    value: "1 дар ҳафта",
                },
                {
                    id: "RELATIONS_OPTION_3",
                    value: "1 аз мушаххаси дар рӯз",
                },
                {
                    id: "RELATIONS_OPTION_4",
                    value: "—",
                },
                {
                    id: "RELATIONS_OPTION_5",
                    value: [
                        "1 дар ҳафта",
                        "1 барои ман интихоб мешавад",
                        "то 1 меояд",
                    ],
                },
            ],
            GOLD: [
                {
                    id: "RELATIONS_OPTION_1",
                    value: "боз шуда",
                },
                {
                    id: "RELATIONS_OPTION_2",
                    value: "1 дар сеш рӯз",
                },
                {
                    id: "RELATIONS_OPTION_3",
                    value: "1 аз мушаххас саат бар саат",
                },
                {
                    id: "RELATIONS_OPTION_4",
                    value: "1 дар моҳ",
                },
                {
                    id: "RELATIONS_OPTION_5",
                    value: [
                        "1 дар сеш рӯз",
                        "1 барои ман интихоб мешавад",
                        "то 3 меояд",
                    ],
                },
                {
                    id: "RELATIONS_OPTION_6",
                    value: "1 дар ҳафта",
                },
            ],
            PLATINUM: [
                {
                    id: "RELATIONS_OPTION_1",
                    value: "Боз шуда",
                },
                {
                    id: "RELATIONS_OPTION_2",
                    value: "1 дар рӯз",
                },
                {
                    id: "RELATIONS_OPTION_3",
                    value: "бисуог",
                },
                {
                    id: "RELATIONS_OPTION_4",
                    value: "1 дар ҳафта",
                },
                {
                    id: "RELATIONS_OPTION_5",
                    value: [
                        "1 дар рӯз",
                        "1 барои ман интихоб карда мешавад",
                        "то 5 меояд",
                    ],
                },
                {
                    id: "RELATIONS_OPTION_6",
                    value: "1 дар ҳафта",
                },
            ],
        },
    },

    TariffJunior: {
        "title-1": "Юнер фаол аст",
        "title-2": "Юнер",
        desc: "Абонементи Юнер дар 4 қисм барои ҳамаи манфиатҳои тартиботи Заррин муҳташам аст: <br/> Касб ва худреализация, иқтисодиат ва ишонд, ҳimhiyat ва энергия, муносибат ва хоила",
        cancelSubscription: "Лағви абонемент",
        confirmation: {
            title: "Лағви абонемент",
            desc: "Шумо сабт карда истодаед, ки хоҳед обунаи ЖУНИОРро лағв кунед? Ҳамаи фоидаҳо то охири давраи пулдор калид хоҳанд буд",
            btn: "Лағви абонемент",
        },
    },
    SubscriptionPeriodLabel: {
        MONTH: "ба моҳ",
        ANNUAL: "солона",
    },
    SubscriptionPeriodOptions: {
        MONTH: "маҳона",
        ANNUAL: "солона",
    },
    Tips: {
        close: "Пойгоҳро махкам кунед",
        prev: "Ба қафо",
        next: "Ба пеш",
        disable: "Намоишдиҳи расоишро ғайрифаол кунед",
        categories: {
            PROFESSIONAL: "Касб ва мақсад",
            RELATIONS: "Муносибат ва хоила",
            MOTIVATION: "Ҳиммат ва кардани куч",
            SELFASSESSMENT: "Ишонди худ ва ишонд",
        },

        list: [
            {
                name: "auth",
                element: ".auth-form",
                title: "Бо рақами телефон ба система вуруд кунед",
                subtitle:
                    "Ин зарур аст, то фақат шумо ба маълумотҳои худ дастрасӣ дошта бошед, ва шумо ниёзи тахлили калимаи убур нест.",
                timeout: 2000,
                next: false,
                prev: false,
                showOn: ["desktop", "mobile"],
                hideAfterShow: true,
            },
            {
                name: "avatar",
                element: "[data-tip='avatar']",
                title: "Аватари худро низом кунед",
                subtitle:
                    "Бе аватар, алгоритм шуморо ба корбарони дигари сайт пешниҳод намекунад.",
                timeout: 2000,
                next: "recommendations",
                prev: false,
                showOn: ["desktop", "mobile"],
                hideAfterShow: true,
            },
            {
                name: "recommendations",
                element: "[data-tip='recommendations']",
                title: "",
                subtitle:
                    "Дар ин ҷо, шумо пешниҳодҳои истифодаи мебаред, ки дар мақосиди гуногун ба шумо кӯмак мекунанд.",
                timeout: 2000,
                next: "people-recommendations",
                prev: "avatar",
                showOn: ["desktop", "mobile"],
                hideAfterShow: true,
            },
            {
                name: "people-recommendations",
                element: "[data-tip='people-recommendations']",
                title: "",
                subtitle:
                    "Дар ин ҷо шумо адамонро пайдо мекунед, ки ба шумо дар категорияҳои гуногун пешниҳод карда шуданд",
                timeout: 2000,
                next: "search",
                prev: "recommendations",
                showOn: ["desktop", "mobile"],
                hideAfterShow: true,
            },
            {
                name: "sphere",
                element: "[data-tip='sphere']",
                title: "Чор равияҳои асосӣ мавҷуд аст, ки одамон омма бо мушкилот ручоанда мешаванд",
                subtitle:
                    "Ман аллакай аксҳои шуморо барраси кардам ва шарҳи шахсии эҷод кардам. Боқӣ хоста наф бар категорияи пойдо шуда истифода баред.",
                timeout: 200,
                next: false,
                prev: false,
                showOn: ["desktop", "mobile"],
                hideAfterShow: true,
            },

            {
                name: "inside-sphere",
                element: "[data-tip='sphere']",
                title: "Категорияҳои шарикӣ ва пӯшида",
                subtitle:
                    "Ранги зард ониҳоро нишон медиҳад, ки аллакай барои хондан боз шудаанд. Тиреҳо бо абонамент ҳамбаста ба категорияҳои мафҳум мешаванд.",
                timeout: 200,
                next: false,
                prev: false,
                showOn: ["desktop", "mobile"],
                hideAfterShow: true,
            },
            {
                name: "search",
                element: "[data-tip='search']",
                title: "",
                subtitle:
                    "Тавассути қутии ҷустуҷӯ, шумо метавонед ҳар як намояндаи русӣ ва мардумро тавассути ном ва насаб пайдо кунед.",
                timeout: 2000,
                next: "notifications",
                prev: "people-recommendations",
                showOn: ["desktop", "mobile"],
                hideAfterShow: true,
            },
            {
                name: "notifications",
                element: "[data-tip='notifications']",
                title: "Тугмаи Оголисизҳои Занғ",
                subtitle:
                    "Он нишонҳои мухталиф дар бораи бонусҳо барои корҳои фаъол дар даруни шабакаи иҷтимоии, оголиҳоро агар ягон каси бо шумо созгорӣ карда бошад, нишон медиҳад.",
                timeout: 2000,
                next: "subscriptions",
                prev: "search",
                showOn: ["desktop", "mobile"],
                hideAfterShow: true,
            },
            {
                name: "subscriptions",
                element: "[data-tip='subscriptions']",
                title: "",
                subtitle:
                    "Дар инҷо, шумо рӯйихати абонаментҳои имконпазир ва абонаментҳои фаъоли худро пайдо мекунед.",
                timeout: 2000,
                next: false,
                prev: "notifications",
                showOn: ["desktop", "mobile"],
                hideAfterShow: true,
            },
            {
                name: "multi-compare",
                element: "[data-tip='multi-compare']",
                title: "Интихоби Категорияҳо",
                subtitle:
                    "Шумо метавонед чанд категорияро интихоб кунед, ки мехоҳед бо дустони худ муқоиса кунед. Танҳо он муқоисаҳо дар дастрис ҳастанд, ки барои онҳо шумо тӯсифи PLATINUM актив кардаед.",
                timeout: 2000,
                next: false,
                prev: false,
                showOn: ["desktop", "mobile"],
                hideAfterShow: false,
            },
            {
                name: "choose-avatar",
                element: "[data-tip='choose-avatar']",
                title: "Аватаратонро танзим кунед",
                subtitle:
                    "Пас ман метавонам туро ба дигарон тавсия кунам. Ман наметавонам туро бе акс пешбарӣ кунам.",
                timeout: 200,
                next: false,
                prev: false,
                showOn: ["desktop", "mobile"],
                hideAfterShow: false,
            },
            {
                name: "recommendations-modal",
                element: "[data-tip='recommendations-modal']",
                title: "Тавсияҳои шахсӣ",
                subtitle:
                    "Пас аз ба охир расидани мӯҳлати ҳисоб шумо як тавсияро кушода хоҳед кард. То он даме, ки шумо охирини дастрасро нахонед, ҳисобкунак барои тавсияи навбатӣ оғоз намешавад.",
                timeout: 500,
                next: false,
                prev: false,
                showOn: ["desktop", "mobile"],
                hideAfterShow: true,
            },
        ],
    },

    WelcomeLayout: {
        ctrl: {
            "step-1": "Санҷиш бо саволҳо",
            "step-2": "Санҷиш бо суратҳо",
            "step-3": "Санҷиш бо суратҳо",
            "step-4": "Санҷиш бо касбҳо",
        },
        "back-to-main": "Ба асосӣ баргардан",
        "back-to-main-confirm-title": "Амалро тасдиқ кунед",
        "back-to-main-confirm-desc":
            "Шумо мутмаин ҳастед, ки мехоҳед ба саҳифаи асосӣ баргардед? Ҳамаи тараққии давомнокӣ ба давомдагӣ набуд карда мешавад.",
        "back-to-main-confirm-apply": "Ба асосӣ баргардан",
    },
    WelcomeWant: {
        title: "Табрик, қадами аввали бардошта шуд.",
        description:
            "Номи ман Мерлин аст ва ман мехоҳам ки шуморо ба ончора кӯмак менамоям, ки шумо дар ҳақиқат ниёздорӣ. <br/>Лутфан, ангоми несткунӣ чизеро, ки мехоҳед гирифт:",
    },
    WelcomeTest: {
        "title-1": "Замони тест 15 дақиқа",
        "title-2": "Мо 12 соҳаи муносиб ба шумо идентификация кардем!",
        "title-3":
            "Шумо зур хастед! Ба худ суи дониш омадани 2 марҳалар боқӣ монд",
        "subtitle-1": "Ҳоло одаме, ки каме мањӣдор санҷиш барои шуморо дорем ",
        "subtitle-2": "Давом дихем ба санҷиш. Ёри дигарро намоед",
        "subtitle-3":
            "Ахирин {саволҳои} барои муайян кардани навъи шахсияти шумо. <br/> Барои муайян кардани соҳаҳо, ки ба шумо муносибанд",
        "question-destination-1": "савол",
        "question-destination-2": "савол",
        "question-destination-3": "саволҳо",
        "choose-title":
            "Интихоб кунед интихоби, ки беҳтарин тавсиф мекунад шуморо:",
        "next-question": "Саволи баъдӣ",
        "quote-1": "Дар ҳар савол пайдо мешавад шумо ёфтан",
        "quote-2":
            "Қувва дороед интихобро, ки дуст медоред ва иттифоқ намекунед. Дар илтимоси хеле калон умерт. (с) Алберт Камю",
        "quote-3":
            "Шахси камел ҳар чизро дар худ иштиёқ мекунад, яке хеле хуб - дар дигарон. (с) Конфуций",
    },
    WelcomePhotoTest: {
        title: "1 марҳала боқӣ то натиҷа монд",
        subtitle: "САНҶИШИ НАФСӢ барои муайян кардани пардохти шумо",
        "choose-title": "Рӯи кадом суратро шумо беҳтар дӯст медоред?",
        "next-btn": "Суратҳои Баъдӣ",
    },
    WelcomeCheckPay: {
        "title-success": "Пардохт муваффақ мешавад",
        "title-error": "Хатои пардохт",
        "description-success":
            "Шумо ба марҳалайи баъдӣ ба 5 сония ба дигар мондан меояд...",
        "description-error": "Шумо бояд пардохт намоед барои давом додан",
        "btn-success": "давом додан",
        "btn-error": "Аз нав тасдиқ кунед",
    },

    WelcomeAnalyze: {
        title: "Таҳлил кардани ҷавобҳо",
    },
    WelcomePhotos: {
        title: "НАТИҶАҲОРО ИСЛОҲ КУНЕД",
        subtitle:
            "Ҷавобҳои шуморо таҳлил мекунад алгоритми шабакаи нейронӣ.<br/> Барои дариёфт кардани аз тавсеяи метаҳ нархи беҳтар, аксҳои шуморо бор кунед.",
        "upload-photos-title":
            "Аксҳои шуморо барои натиҷаҳои точики беҳтар бор кунед",
        "next-btn": "Баъдӣ",
        "skip-btn": "Ҳолати",
    },
    WelcomeAnalyzeReady: {
        title: "Ман метавонам 170 метаҳхоро бекор кунам, ки баҳам намекхорад.",
        subtitle:
            "Дар давоми 10 дақиқа мо барои шумо метаҳҳои беҳтареро интихоб хоҳем кард.",
        desc: "Вақти худро рӯи ҳалқаҳое, ки баҳам намеоянд, назниед.<br/> Имтиҳони, ки компютер мероъски кунад, рӯи шарки кардани феълиятро суръат мебахшад<br/> 90% аз одамони, ки имтиҳонро додаанд, метаҳи дӯсти худро пайдо карданд ва анҷоми худро диданд",
        quote: "Ҳар як муқаррарият воридшавие метавонад бошад. (с) Аристарх Ромашин",
        "start-btn": "Оғоз кардан",
    },
    WelcomeProfessionVote: {
        "title-1": "",
        "title-2": "Барои шумо! {count} {decl} дар баровари 1150 омад",
        "title-3": "Барои шумо! Мо наздику истода истодаем",
        "title-4": "Рӯи метаҳи нав зер кунед ва мо давом медиҳем",
        "subtitle-1":
            "Дар ин ҳалқа, ман соҳаҳои феълиятиро муайян кардам, ки шумо дар онҷо озодӣ косӣ кунед.<br/>Ман бақӣ гузоштам {count} {decl} аз 1150. Ба ман кӯмак овар, ки барои шумо беҳтаринеро интихоб кунам.<br/> <br/>Рӯи бақси, ки бо зарди барзабонаро зед кунед",
        "subtitle-2": "Рӯи бақси, ки бо зарди барзабонаро зед кунед",
        "subtitle-3": "{count} {decl} ҷа боқӣ мондааст аз 1150",
        "subtitle-4": "Танҳо {count} {decl} боқӣ мондаанд аз 1150",
        "profession-declination-1": "метаҳ",
        "profession-declination-2": "метаҳҳо",
        "profession-declination-3": "метаҳ",
    },
    ProfessionVotePopup: {
        "help-1":
            "Барои шумо. Ман ҷавоби шуморо борёд мекунам. Ҳала метаҳҳои баъдиро пур кунед ва рӯи маҳсул зер кунед.",
        "help-2":
            "Агар шумо намедонед, ки метаҳ кадом аст, танҳо рӯи номи он зер кунед.",
        "help-3":
            "Дар инҷо навишта шудааст, ки чаро шумо бояд дар ин феълият ширкат кунед ва кайфиятҳои кадоматон бояд дар ин ҳолат ба шумо кӯмак кунад.",
        "help-4":
            "Ин намунаи метаҳ аст, ки шумо метавонед дар он ҷои худ баргиреед. Илтимос, қадари, ки барои шумо ҷалб аст, инро баҳо диҳед. <br/><br/>Ҷиддан баҳо диҳед, ман метавонам ҷавобҳои шуморо барои интихоб кардани метаҳи беҳтар истифода барем <br/ ><br/>Ҳаҷми барои метаҳи аввалӣ барои баҳо додан",
        "error": "Лутфан, метаҳҳои зерро нависед:",
        "next-btn": "Давом додан",
        "calc-btn": "Маҳсул кардан",
    },

    OnboardDescriptionPopupRadio: {
        list: [
            {
                id: 1,
                text: "Ҳеҷгаҳ манфиатнакштам",
                showSub: false,
            },
            {
                id: 2,
                text: "Ҳеҷвақт эмтиҳон накардаам, аммо қизиқам мешавад",
            },
            {
                id: 3,
                text: "Ман ҳозир дар ин соҳа мутаҳассис мешавам",
            },
            {
                id: 4,
                text: "Ман эмтиҳон кардам, аммо ба кор нарафт",
            },
            {
                id: 5,
                text: "Ман фаъолан ин корро иҷро мекунам ва хуб бар микунам",
            },
            {
                id: 6,
                text: "Ман дар ин мавзӯъ бо таҷрибаи зиёди иҷроӣ ҳастам",
            },
        ],
    },

    WelcomeProfPay: {
        "title-1": "Аз 1150 пешахоянадо, 3 та ро махлук аст",
        "title-2": "Рохи пардохтиро интихоб кунед",
        "desc-title":
            "{count} одам шугли худро пайдо карданд ва доходи худро афзонидаанд",
        "desc": "87% аз анҳои ки ин имтиҳонро мегзаранд, онро ба ҳамроҳони издивожи худ тавсия кардаанд <br/> Имтиҳон аз тарафи модириятони болоии 38 ширкатҳои пешрафтаи кишвар истифода мешавад <br/><br/> Маслиҳатҳои шахсии дар бораи муваффақият дар пеша дарёфт кунед",
        "open-btn": "Боз кардани барои 990 рубл",
        "motivation":
            "Шумо аллакай 15 дақиқа сармоя гузоридед, либоси охирин боқӣ аст!",
        "recreate-text": {
            "btn": "Бори дигар имтиҳонро дар бораи соли",
            "confirm-title": "Шумо мехоҳед имтиҳонро аз нав оғоз кунед?",
            "confirm-desc":
                "Агар Шумо фикр кунед, ки имтиҳонро дуруст раванд карден, Шумо метавонед анро бори навоз бегзаронед. Диққат кунид: пешрафти имтихони раванд шуда ба мондагӣ нест мешавад.",
            "confirm-btn": "Бори дигар имтиҳонро дар бораи соли",
        },
        partners: {
            "title": "Тасдиқ карда шудааст аз тарафи шарикони мо:",
            "list": [
                "Вазорати Маорифи Русия",
                "Фонди тавгирдиҳандаи инновация",
                "B20 Индонезия 2022",
                "Сколково",
                "Merlin AI - шарикаҳои расмии Telegram",
            ],
        },
        paymentMethods: {
            all: "Тамоми роҳҳои пардохтиро нишон диҳед",
            phone: {
                title: "1. Бо рақами телефон",
                copy: "нусхабардорӣ кунед",
                copied: "нусхабардорӣ шуд",
                "copy-number": "Рақами нусхабардорӣ",
            },
            link: {
                title: "2. Бо пайванд",
                btn: "Ба пайванд борем",
            },
            kassa: {
                title: "3. Тасрофи Robokassa",
                btn: "Пардохт кунед",
            },
            qr: {
                title: "4. Пайванди QR",
                btn: "Интихоб кунед",
            },
        },
        "after-pay":
            "Пас аз пардохтиро ҷузваро галоча гузаронед, то мы тавонем паёми пардохтиро бозбинӣ кунем",
        "after-pay-label": "Пардохт шудааст 499 рубл",
        "after-pay-btn": "Идома диҳед",
    },
    WelcomeProfessions: {
        "title": "Мо пешкаш кардем, ки шумо дар части дигаре<br/>",
        "title-1": "Ниҳоят!<br/>Мо касбҳои бахтшудаи шуморо ангом гузоштем",
        "title-2": "Ниҳоят!<br/>Ҳоло",
        "title-3": "Ниҳоят!<br/>Ҳоло",
        "subtitle-1":
            "Рӯи касбе клик кунед, ки ба шумо <b class='gold'>бештар</b> дилдорӣ мекунад",
        "subtitle-2":
            "Пайвандҳои зиёд - соҳаи бе шумо бехтар мувофиқат мекунад<br><br>Рӯи як соҳа клик кунед ва шумо касби мувофиқро мебинед",
        "title-helper": "кӯмаки касб аз мавзеъ",
        "these": "Инҳо",
        "merlin-help":
            "Ман шуморо кӯмак мекунам, ки онҳоро биязмоед. Аз навъ дар Merlinface одамони хуби дар он мавҷуданд. Ман шуморо ба онҳо мутаариф мекунам. <br/><br/>Оммаро дар ҳозир вақт ман як чиз дигар дорам...",
        "more-btn": "Омӯз",
        "next-btn": "Идома додан",
    },
    WelcomeBusinessCardInfo: {
        "info-title": "Баъзе аз маълумотҳоро дар бораи худ пур кунед",
        "edu-title": "Маълумоти таълимӣ худро пур кунед",
        "career-title": "Маълумоти ишӣ шуморо пур кунед",
        "subtitle":
            "Ин маълумот вақте ки корбарони дигар ба саҳифаи шумо сар мезананд, дид мешаванд. Ин метавонад шарики тиҷоратии шумои болоиши дорои эътибор бошад",
        "all-fields-error": "Ҳама майдонҳо зарур аст",
        "edu-fields-error": "Ақалан як ҷойи таълимро изофа кунед",
        "career-fields-error": "Ақалан як ишро изофа кунед",
    },
    WelcomeBusinessCardPay: {
        loading: "Боргирии маълумот...",
    },
    WelcomeStart: {
        "title": "Профили худро бо гурӯҳи мутлақ пур кунед!",
        "subtitle": "Маълумоти дақиқ, натиҷаи дақиқтар",
    },
    WelcomePersonInfo: {
        title: "Хубтарин, ",
        subtitle: "Маълумотҳои бози худро пур кунед:",
        age: "синн",
        "age-placeholder": "Синни шумо чанд аст?",
        "age-error": "Синни шуморо ворид кунед, ки аз 14 то 99 бошад",
        "choose-country": "Ҷойи истиқоматро интихоб кунед",
        "choose-city": "Шаҳрро интихоб кунед",
        "country-placeholder": "Ҷустуҷӯ ба оснои Ҷойи истиқомат",
        "city-placeholder": "Ҷустуҷӯ дар барномаи шаҳри истиқомат",
        "city-no-options":
            "Номи шаҳри мавриди ҷустуҷуро ворид кунед, то дар странаи интихоб шуда ҷустуҷӯ кунед",
        "social-title": "Статуси иҷтимоиӣ шумо:",
        "social-error": "Интихоби статуси иҷтимоиӣ",
        "next-btn": "Идома додан",
    },

    WelcomeTestComplete: {
        title: "Ман навъи шуморо муайян кардам",
        "your-type": "Мо дар сомонаи худ мо {count} {dec} бо навъи шумо дорем",
        "your-partners":
            "{count} {decl} бо мувофиқати тиҷоратии баланд бо шумо.",
        "celebrity": "{count} шахсиёти машҳур ва муваффақ бо навъи шахси шумо.",
        "next-btn": "Давом диҳед",

        typeDescriptions: {
            "Воситагар":
                "Сифатҳои табиии шумо шуморо ҳатман медиатор ҳубронкунанда мекунанд. Шумо таанати ҳал кардани зиддиятҳо ва пайдо кардани ягона равияти муштарок бо мухталиф адамонро доред. Тавонандагии шумо ба гушкунӣ ва даркории дигарон назар шуморо аъзоии авқотӣ аз ҳар тим мекунанд. Таваҷҷӯи шумо ба деталҳо ва хоҳиши ҳаёт ба ҳамоят муносибатҳои зебо ва пайдоистанда мебаранд.",
            "Муоширатгар":
                "Шумо саҳнаи ғарибана барои паёмрасониро доред. Маҳоратҳои шумо шуморо имкон медиҳанд тавонистанро равон кунед, афкор ва идеҳои худро ба вазъ кунед, ҳамчунин муроҷиати дигаронро эҳтиром бикунед. Тавонандагии шумо барои осон гузаронидани пардозиши паёмрасоні помогател мемонанд, ки шумо чунин муораи ҷустуҷўхои касбиро ба шукуфа барсонед.",
            "Пайвандкунак":
                "Шумо истеъмолгаре, ки имконат доред адамон ва афкорро пайваст кунед. Синои шумои ҷамъияти ва таваҷҷӯ ба дигарон мебарояд шумо дар маркази шабакаҳои касбӣ ҳастед. Маҳоратҳои ташкилии шумо имконат медиҳанд командаро ва лоиҳаҳоро муваффақ созед.",
            "Гедонист":
                "Шумо дар ҳаёти касбӣ шодиманӣ мепазиред. Маҳоратҳои шумо ба шумо имконияти лаззат бурдани карҳои худро ва нархҳои бахохуд карданро мепазиронанд. Шумо роҳатор, ҳамоятро қадр медонед ва роҳҳои ташкили кари худро ҳамчунин барои ташкили кари худ тавонистан ва тавонистан густариш мепазиред.",
            "Дипломати мутасаллит":
                "Шумо ба саҳнаи ғарибана барои паёмрасони ва таъсири барраси мардум доред. Талантҳои шумо ба шумо имкон медиҳанд ба ҷамъияти ягона муроҷиат пайдо кунед ва қадамҳои худро андозаш кунед, ки шумо ба навиштаи мухтарам дар соҳаи касби ба дастовард омадаед. Шумо муроҷиат ба ариза ва муваффақият, ва хоҳиши пешниҳодв ё пампери эҳтиёј мебароред. Баробар бо ҳама ин, шумо тавонед бо табаррӯ ва нармаҳоратра шинонидани таффакуроти наврӯзандаро эҳтимол бардоред, ки шумо чунин ба баҳши конунии худ бархазават касбиро рӯи кор мекунед.",
            "Лоббикунон":
                "Шумо маҳоратҳои беҳтарини таъсири ва таъсири махсус доред. Тавонандагиҳои шумо ба шумо имкон метавонанд соҳиланно рабта барқарор кунанд, ҳизматрасонии ихтиёъ диҳанд ва аллакай бироҳат худро ҳазинанаро рунд кунанд. Шумо тавонед процессҳоро идора кунед, стратегияро сохор диҳед ва тибқи муфаҳиматҳои маъруфӣ гирд нишон диҳед. Тавонандагии шумо барои афкор диҳивонии корро байни кормандон сохтан дорад. Иштиёҳо ва маҳоратҳои шумои ташкилотӣ имкон медиҳанд, ки шумо ба гоҳияти мушкилотӣ нигоҳ доред ва ба ҳамон ғайрат бархурдёб мекунед.",
            "Сиёсатмадор":
                "Тавонандагии шумо ба рошд ва таъсир кардани шумо дар арионаи сиёсӣ муваффақ мебосад. Шумо тавонед ба фаъолияти зарурӣ тасдиқ бидиҳед, конфликтҳоро ҳал кунед ва ягона раванди муштарокро оид ба халқи назарҳои мухталёф ёфтан пайдо кунед. Раванди фаъолии шумо ба навишта, хоҳиши ҳаётбаӣ ва тавонандагии шумо оид ба даргирӣ дигарон дар идеаҳои ба шума дорад. Метавонед сиёсатмадори самарандо шавад. Социальность ва иштиёҳи обшир аз шумо мебошанд, ки ба ҳадафҳои шумо даромад конед ва ба равандҳои ҷамъиятиёт мучакики шавад.",
            "Моддигар":
                "Шумо молазвание шуморо қадр медонед ва ба ҳолати камол метавонед кар кардашавӣ кунед. Прагматизми шумо ва ҳисияти муфидиат шуморо дар ҳама вақтҳо нарма менномонанд. Шумо ба идораи манбаъгаҳо, баримишавӣ идоракуни ва натиҷаҳои худро маконавӣ меномонед. Маҳоратҳои шумои шукрўгоҳӣ идоракунии доранд забонва тасдиқ мекунад ва ҳар тим ё лоиҳаро беъзаз мекунанд.",

            "Муҳофизатгар":
                "Истиъдодҳо ва салоҳиятҳои шумо ба рӯи назари баҳоҳоандайи идеали мувофиқат мекунанд. Шумо стабилот, интизом ва суннатро арзиш мекунед ва метавонед ин арзишҳоро ба ҳар соҳаи касбӣ бардоред. Шумо салоҳияти ташкил ва систематизатсия карданро доред, ҳамчунин бо диққат ба афзоиш дода мешавад. Замонӣ ва пайванддории шумо дар дастрасии аҳдофҳо созмон ва стабил тавлид кардаанд.",
            "Социализатор":
                "Қобилияти шумо дар ҷалб кардани одамон ва эҷоди пайвандҳои иҷтимоии қавӣ шуморо социализатори идеалӣ месозад. Маҳоратҳои шумо дар соҳаи коммуникация ва муоширати иҷтимоӣ ба шумо кӯмак мекунанд, ки шабакаҳоро дар вуҷуд меоред ва нигоҳ медоред, ки самарабахшии кори шуморо ба таври назаррас беҳтар месозанд.",
            "Пайрав":
                "Шумо ба назари ҳалкарсози паёмро идеали мувофиқат дода мешавед. Салоҳиятҳо ва истиъдодҳои шумо метавонад бошад, ки вофадор ва мутабиқ ба аҳдофҳои умумӣ бошед. Дақиқатӣ, пасохгӯӣ ва салоҳиятҳои коргарӣ шуморо ҳамкори лозимӣ ба ҳар лоиҳа мебаранд.",
            "Нигоҳдор":
                "Салоҳияти шумо барои муҳофизати дигарон ва нигоҳ доштани интизом шуморо муҳофиъи идеали мебаранд. Салоҳиятҳои шумо шуморо иҷозат медиҳанд, ки арзишҳо ва манобееро нигоҳ доред, дар ҳамин замон масулият ва интизоми шумо шуморо ба стабилот ва вофадорӣ дар ҳар ҳолат партофта мекунанд.",
            "Оммавигардон":
                "Бо истеъдодон ва маҳоратҳои худ, шумо бе таври унікал барои нақши баҳрадор муносиб ҳастед. Шумо қобилияти унікал дарид, ки баҳшад, ташкил диҳед ва шахсонро бо идеаҳои ҷалб интихоб кунед. Ибтидоиёт ва энгусарии шумо ба шумо имкон медиҳад, ки ба тези ба муамоятҳои нав мутобиқат диҳед ва чунин ҳам дар бораи чорабиниҳо бо мотиватсияи баланд барпа мегардед. Энергия ва ҳамдарди шумо шуморо аъзои себоварзи ҳар гуруҳ медиҳад.",
            "Ташкилотчи":
                "Шумо салоҳияти ташкил ва барномаризӣ карданро доред. Салоҳиятҳои шумо дар замини алоқаи муносебати мардум ва идораи манобеа шумо иҷозат медиҳанд, ки муносибат бо насл ва вазифаҳои муайянро ба воситагӣ табдил кунед. Шумо медонед, ки навбатро чигуна ташкил диҳед, ки он не танҳо сония, балки барои ҳамаи мутолибон шинохтат мешавад. Раҳбарии шумо ва салоҳияти барпоӣ кардани рӯҳият доштани гурӯҳ шуморо элементи гавҳами дар ҳар лоиҳа мебаранд.",
            "Хушбин":
                "Оптимизми шумо ва барномаи позитиви шумо барои ҳаёт ба шумо ба ҳар соҳаи касбии мебаранд. Имконияти шумо ба як афкор дидан ва имкониятаро дорад, ки як хубро гарм кунад, чаваб дода мешавад, ки шумо тезро ҳаллҳо ёфтани ва бо мушкилот сухбат кунед. Истиъморӣ ва истробани шумо энергияи позитивӣ ба гурӯҳ ворид мекунад, ки амалкории умумиро афзояд.",
            "Фаъол":
                "Энергияи шумо, алоқамандӣ ва салоҳияти ихтиёр кардани одамон шуморо активист мувофиқати идеали боданд. Не танҳо шумо метавонед ба размонданӣ иштирок кунед, балки ба ҳамин ҳолаташ метавонак дигаронро ба он огӯ зада мешавад. Салоҳиятҳои шумо дар замини элокиҳо ва салоҳияти ташкилдиҳӣ шуморо қодир мешавад, ки идеаҳои худро барои итминанбахшии аҳдофҳои худ тавлид кунед. Истиъморӣ ва автономияи шумо ба дигарон илҳом мебарад ва иҷозат медиҳад, ки натиҷаҳои забончӯй ро шавад.",
            "Идеалистеро рӯҳ диҳанда":
                "Салоҳияти шумо дар дидани ҷаҳон дар контексти васеъ ва як раҳёии креативи ба ҳалли мушкилот кардан шуморо андешманддербоги идеали мебаранд. Идеаҳои шумо метавонанд дар дилҳои одамон шола еҷод кунанд, ки онҳо ба иҷод кардани чизҳои нав омадаанд. Интуицият ва тӯфонати заҳматрасондари шумо шуморо иҷозат медиҳанд, ки майлбаҳоиро пешбинӣ кунед ва роҳҳои нави бехтарини эҷод кунед.",
            "Романтик":
                "Тафаккури шумо ва дидани 한гоми ҷаҳон шуморо ёке ёке истинода мебаранд. Шумо сифати беҳтаринро доред, ки шавк дар муточиқонро бийкунад ва эҳсосотро ба кортон табдил кунед. Салоҳияти шумо барои илҳом ба дигарон метавонад калид ба идеаҳои нав ва шикастаҳо бошад.",
            "Ҳомии фарҳанг":
                "Шумо дар интиқолу салоҳият доред хубонават ва мувофиқсозиро дарк кунед ва салоҳияти дастгири ва пешрафт додани идеаҳои креатив. Арзуи шумо барои кӯмак кардани дигарон, фарогирии креативияти онҳо ва ихтиром ба ҳонар шуморо ба наразаи идеали идеали мебаранд.",

            "Хобгардон":
                "Қодирати шумо барои тавлиди идеяҳо, дастгирии эҳсосоти омокї ва нигаҳ оид ба акси куллӣ шуморо армуғони идеолог меовард. Шумо аз хаб супоридани идеяҳо ва заҳмат накашон кардани онҳо иттирозӣ намекунед. Илҳом ва ин бозмеояи шумо ба шумо имкон медиҳад, ки шумо дар ҷахони, ки дар ҳар соҳа, ки хоҳиштонра маврид ба кадам бозӣ гузаронед, ба идеяҳои нав ва имкониятҳои нав бинигаред.",
            "Раҳбари идеалӣ":
                "Қодирати шумо барои тавлид ва иҷрои идеяҳои нав, якҷоя шуда бо қодирати шумо барои илҳом ва мотиватсияи дигарон, шуморо идеологии фикр меоярад. Шумо қобилият доред, ки аз мавориди оддиӣ бинигаред ва уфуқҳои навро бароят ва гурӯҳи худ боз кунед. Тафаккури шумои пешро, бараварди тақлидӣ ва вазни пандиш барои навсозӣ як суратҳои таҳрикатбахш барои онҳое, ки паси шумо мераванд, мебошад.",
            "Таҳиягари образ":
                "Мушаҳидаи шумои таъсири ва нигаҳи ҳамчун ҳаёти шумо раъояти ва танозандагии идеяҳои ба муассирати онҳо, ки таъсир ба асъоб мекунанд, мехонад. Қодирати шумои монаҳид барои ифодаи идеяҳо ва арзишҳо тариқи акси берунӣ, идеаҳои пуроз ва таъсирпул мебошанд. Қодирати шумои йодгирии ҳусни муассир ва сохтани идеаҳои ба ҳамраҳи вазни муассир ба шумо ҳамсухгарии гурӯҳи ҳар якӣ мемард.",
            "Идеаолог":
                "Қодирати шумо барои тавлид ва рушди идеаҳои нав шуморо идеологии идеолог мекунад. Раванди шумои таъсири ба ҳали мушкилот, андешаи non-traditional ва қобилият дар бинии имкониятҳо, ки дигарон онҳоро маворидчии мушкилот мебинанд, ва қобилият дар иҷрои онҳо. Идеяҳои шумо дигаронро илҳом мекунад ва тағйироти муассира мебароянд.",
            "Ибтидогар":
                "Энергияи шумо ва таҷлили шумо барои тағйирот қодирати идеонасб мераванд. Шумо қобилият доред, ки лоиҳаҳои навро пусҳонед, идеяҳои гуногунро якҷоя кунед ва мардмонро ба ҳадафи мусштарӣ ҷамъгузор кунед. Қодирати шумо барои оғози кори нав бархелафи мушкилот ва таваҷҷӯҳи шумо ба идеяҳои нав мегӯянд, ки шумо дар ҳар лоиҳа, алалхусус дар онҳое, ки навсозӣ лозим аст, ноябондагӣ мепардозед.",
            "Мавъизагар":
                "Эътиқоди шумо, таъсири шумо ба рангҳои нармаи эҳсосиати дигарон ва ҳаёзи холисанаи шумо барои афзоиши дунё мекашонад, ки шумо мубаллиги идеолог мебошед. Ишқи шумо ба кӯмаки беҳтарин адамон ва қобилияти шумо барои истиқомати бо эҳсосҳои дигарон мегӯяд, ки шумо бо одамон нарма мепарёзед ва таъсири пуроз ба онҳо гузаронед. Шумо дар миёни чизҳо мебинед ва фикрҳо ва идеяҳои худро рошан тус мегузаронед.",
            "Ахлоқшинос":
                "Шумо эҳтироми омокї доред ва хоҳиш барои нигоҳ доштани адолаткашӣ ва давлаткашӣ, ки шуморо моралисти идеолог мекунад. Идрави шумо аз мардум, эҳтиром ба арзишҳо ва принсипҳо, ва хоҳиши шумо барои афзоиши дунёи атрофи шумо шуморо барои ҳар гурӯҳ ё ташкилот ноябон мекунад.",
            "Илҳомбахш":
                "Қобилияти шумо барои илҳом додан ва мотиватсия кардани дигарон, инчунин муносибати шумои эмпатияи нисбат ба муҳит, шуморо илҳомбахши идеалӣ месозад. Ҳассосияти амиқ ва дарки шумо аз табиати инсон ба шумо имкон медиҳад бар мардум таъсир расонед ва ба онҳо дар кошоиши салоҳиятҳои худ кӯмак расонед. Қобилияти шумо ба ҳамдардӣ ва гӯш кардани бодиққат шуморо дар ҳар даста ё ташкилот беҳад муҳим месозад.",
            "Муаллим":
                "Ишқи шумо ба кӯмак ва ҳақиқати идравии чунин қобилият доред, ки сабаби воситагӣ дар тарбия мебошад. Шумо дастрасӣ дар то мушкилот хатм кунед ва ҳалл кунед, гумроҳ кунед, чунинки дигарон қобилиятҳоро пайдо ва рушд диҳанд. Қобилияти шумо барои нигаҳдории сабр, эътибор ва семилуут далеляи эътиром ва ишончи шогирдони шумо менамоянд.",
            "Раҳбари ҳиссиётӣ":
                "Ӯдибаҳсаи шумои эҳсосӣ, таъсири тавоно ба дигарон ва қодират барои нармаандсозии сабаби шумо воситагӣ дар гуногунбахшӣ мебошад. Қодирати шумо барои гирифтани ва инъикоси эҳсосоти ба муассирати гурӯҳи шумо ва таянӣ ва таъмини гурӯҳи шумо метавонад шарпаи баланди онҳо ва роҳдарбарии бози шумо хасрооҳои шумо аз эҳсосҳои худ.",
            "Шавқовар":
                "Энергияи шумо, бознависӣ ва оптимизми шумо ба шумо фурсат медиҳад, ки шумо ба илҳом ва мотивацияи беҳтарин адамон пешгирӣ кунед. Шумо барои ҳамаи кори худ барои илҳом ва эҳсоси мусбат ғайрихазонаконанда мебошед. Қодирати шумо барои бинии баҳори миёна ва ба онҳоро бо дигарон баҳам кардани ин имконият дар шумо ҳамсуҳи гурӯҳ мебошад.",
            "Андешаманд":
                "Таъсири шумо ба ниёзҳои дигарон ва имконияти шумо барои ниёзмандият кардани мардум шуморо мураббии идеали таъйин мекунад. Сабри шумо, даркории ва имконияти шумо барои гузориши маърифати худ ва таҷрибаи худ ба шумо ёрии мекунад, ки дигаронро омуза ва ба наидаи ҳадафҳо олӣ кунед.",
            "Санъаткор":
                "Афкори иновативии шумо, ифодаи эҳсосот ва нигоҳи уник шумо ба дунё шуморо санҷидаи идеали таъйин мекунад. Имконияти шумо барои ифодаи худ ва андешаи афкори, ва низ нигоҳи шумо ба зебоӣ оғоз мекунад ва онро ба дигарон ифода мекунад, шуморо имкон медиҳад, ки асарҳои беҳтарин ва муассири эчод кунед.",
            "Беҳтаргар": `Қувваи шумо барои самароқандӣ, вақеигарӣ ва имконияти шумо барои ёфтани ҷавобҳои мантиқӣ шуморо беҳтарин муандис мекунанд. Шумо ҳамеша дар ҷустуҷӯи усулҳо барои самарафири кор ҳастед, ки корро осон ва беҳтар кунед. Имконияти шумо барои дидани "акси калон", таҳлили системаҳои мураккаб ва қабул кардани онҳо ҷавони асосии шумо мебошад.`,
            "Техник":
                "Огоҳии техникӣ, имконияти андешаи ташриҳӣ ва тавонисти шумо ба дарки системаҳои мураккаб шуморо техникӣ идеали мегардонанд. Шумо имконият дорад, ки бо технология кор кунед, дар он муфассал шавед ва бо он масъалаҳоро ҳалл кунед. Имконияти шумо барои омӯзиши зуд ва адаптасия ба технологияҳои нав бузурги мебошад.",
            "Маҳоратманд":
                "Маҳорати дастаи шумо, вақеигарӣ ва рӯиҳи тафаккури шумо ба ҳали масъала шуморо дасткара интихоби идеали мекунанд. Шумо имконият дорад, ки чизҳоро бо дастҳои худ эчод, ислоҳ ва таъмир кунед. Таваҷҷӯи шумо ба тафсилот, имконияти кори шумо бо материалҳо ва асбобҳо, ва ишфи шумо барои эчоди чизи нав ва муфидињати шумо мебошанд.",
            "Мутасаддии пурқувват":
                "Қатъияти шумо, имконияти тасодуфи тез тез ва хосияти раҳнавоии интибохёи шумо шуморо инвақори зордор идеал мекунанд. Шумо имконият дорад, ки фурсатҳои ҳаҷ ку ҳам гум бошанд, ва ҳамеша барои қабулгӣ кори чунинатон омода ҳастед. Шумо ҷамъаи худро барои ҳусули наидаҳо сакша мекунад, агар ин аз шумо қувваи калон ва хатарат талаб кунад.",
            "Роҳбар":
                "Шуҷоати шумо, ишонсиати шумо ба шумо ва имконияти шумо ба хамохангсозӣ ҳамкорон шуморо раҳбари идеали мегардонанд. Шумо ҳамеша дар қароргирӣҳои худ умедвор ҳастед ва медонед, ки чӣ тавр бо ишонсизии ҷамъа ҳамгаштан бояд. Раүиати шумои табиӣ, шуҷоат ва тавонисти шумо барои гирифтани масулият дар шароити слукат бар меъморҳои асосии шумо мебошанд.",
            "Идоракунанда":
                "Пайдории шумо, маҳоратҳои кор дар тим ва стаминай ҷисмонӣ шуморо легионери идеали мегардонанд. Тулӣ, оқибатӣ ва хоҳиши паёз шуморо барои ҳусули наидаҳо ҳамгаштан мекунад. Хоҳиши шумо барои ҳамеша дар старати ҷашн ва имконияти шумо барои нигоҳдории худ, дар шароити слукат, шуморо аз вакил баҷан кунад.",
            "Инспектор":
                "Маҳоратҳои таҳлили точикӣ ва диққати шумо, ишфи низом ва систематизация, ва бардошт ва пешбинии шуморо барои нақшиҳои ходиш кардани назорат ва ибтидоии боядхӣ идеал таъйин мекунанд. Имконияти шумо барои андешаи вақеигарӣ ва гузаравани стандартҳо ва тартибот ба кори бозӣрни сифат мебароянд.",
            "Назоратгар":
                "Тавони шумо, ки дар шароити фароро бо тандурѣ монда шавед, таваҷҷуҳоти шумо ба ҷузъиёт ва шауқи шумо ба идеализм ба шумо имкон медиҳад, ки процесҳои мураккабро бо муваффақият назорат ва идора кунед. Шумо дар таъминоти робитии қоида ва меъёрҳо маҳорат доред, ки ба самарманди ва амнияти лоиҳаҳои шумо илова мекунад.",
            "Муътадилкунанда":
                "Истеқомати шумо дар қабили ҳарорат, вафодорӣ ва аътимоди шумо ба мақсад дар ҳар гурӯҳ ё лоиҳа барои пойгоҳӣ ва давомманди меҳмондори шумо яки даъват мебошад. Имконияти шумо барои эҷод ва таъминоти тартиб ва арзумандии шумо барои пойгоҳӣ ва пешбинии шумо барои пайваст тур ба тими ҳар ҳост дар ҳар майдон мебошад.",
            "Муҳандис":
                "Салоҳиятҳои шумои андиша ва анализи система, шаҳвати шумо барои ҳали маслаҳат ва ишқи шумо ба ҷузъиёт шуморо намояндаи идеалии инжинерон ва номаҳсулот",
            "Амалиётгар":
                "Равишҳои корӣ истеъмол ва тавоноии шумо baroi руизиҳои ҳақиқӣ кор карда мебошад, ва шаҳвати шуморо ба насиҷҳои конкрет ва пайовари мутасил, раввии кории корӣ нишон медиҳад, ки шумо практикрана дар ҳақиқат ҳастед.",
            "Амалгар":
                "Тавони шумо барои коррасонии нақшҳо, гардиши шумо ба мақсад ва тарикаҳои работаши шумо шумо воридакунии идеал мебошад.",
            "Мудир":
                "Салоҳиятҳои идоракунии шумо, воридкунӣ ба шикаст, ва тавони шумо барои ҳамоҳангкунӣи аморрасии коррасі мебояд ба ин администратори намоянда бошад.",
            "Серии мухфӣ": `Тавони шумо, ки ба тафаккури стратегики, шаҳвати шумо барои таҳлил ва тавони шумо барои ҳисоби ва коррасои ҳамаи сценариоманди намоянандои намоянда барои нақши "кардинали хоки" мебошад. Шумо ба коркунӣ пас аз саҳна мебахшед, коррасои тафаккури наздик ояд, дар ҳама вақт ки хеш бемахфӯз мебошед.`,
            "Пешгӯ":
                "Воридкунӣ ба шикаст, андиши таҳлилӣ ва тавони шумо барои пешбинии баҳоҳои табақа бақает менба хуш аст.",
            "Шубҳаовар":
                "Тавони шумо, ки ба санҷедан критикӣ, таҳлил ва идёру шекаста мебошад, гумонкун не мебошад. Шумо ҳамеша омодаед, ки нархҳо ва идеаҳоро пурсед, онҳоро аз дидгаҳи гуногун барраси кунед ва даст ками доштани онҳоро шиносоед.",
            "Танқидгар":
                "Сахтмонии шумо барои арзкунӣ ва таҳлили иттилоот, ва ҳамчунин барои кайфият ва дурустӣ, шумо ро критик идеали мекунад. Шумо метавонед нокисиҳои ерро мекунед ва ислоҳотро пешниҳод кунед, ки дар давоми зудрасӣ ба дастоварди камолият ва савоҷиш кӯмак мекунад.",
            "Новатори пуршавқат":
                "Энергияи бемарзи шумо, рагбати шумо барои чизҳои нав ва илтиҷои ҳамешагии шумо барои кашфкунӣ, шуморо ҳарӯсаи гарми инноватор мебахшад. Шумо ҳамеша дар ҷустуҷуи идеаҳои нав ҳастед, барои таҷробакунӣ кушода ҳастед ва берои чизе кардани чизе новаторӣ омадаед риска гирифта бошед. Ин шуморо ба шумо имкон медиҳад, ки бо мушкилот борам баред, маҳдудиятҳои муқарраркунӣро аз нав бификред ва аз ҳудудҳои маҳрумият бирун баред. Шумо зиндагӣ ва инновациониро ба гурӯҳе, ки дар он мишавкед, мебахшед, ва идеаҳои шумо ҳамеша манбаъи инновацион хост.",
            "Тадқиқотгар":
                "Қуиши шумо, марои зишу ба афзоиши худ ва тавонаии шумо барои таҳлили амқонии муомала, шуморо дар ҳақиқат пажӯҳишгар мебахшад. Қуиши шумо барои илм ва тавонаии шумо барои пурсиши масъалаи муносиб, ба даркории амёқотерии муомала ва мушкилотҳо мерасонад. Шумо талант доред, ки ҷайгоҳдорише кунед, ки дигарон натавонанд онро дидан, ки тавонаии шумо барои ташилот додани роҳҳои нав ва роишаҳо бошад. Салоҳияти пажӯҳишӣ ва хоҳиши шумо ба расидани ба дил дар осоваи таъмири ва инновацион қарор дорад.",
            "Новатор":
                "Ҳоҷати доимии шумо барои навоварӣ, мойил ба фикри ностандартӣ ва омодагии шумо ба рӯйдоди чолишҳои статус-кво шуморо янгтози ҳақиқӣ месозад. Шумо на танҳо воситаҳоро ҳамон тавре қабул мекунед, ки ҳастанд, балки ҳамеша дар ҷустуҷӯи роҳҳои нав ва ҳалли беҳтар ҳастед. Шумо метавонед имкониятҳоро дар ҷое бинед, ки дигарон мавонеъро мебинанд, ва қобилияти шумо ба навоварӣ ба раҳбарӣ кардани дастаи шумо ё ташкилот ба муваффақият кӯмак мекунад.",
            "Таҳлилгар":
                "Таланти шумо барои таҳлил ва шикастани чизҳо, шуморо таҳлилгаре лозим мебахшад. Шумо ҳамеша мебинед, ки ҳар элемент чӣ тавр асорат мебард, ва тавонаии шумо барои баҳои объективии иттилоот ва маълумот, шуморо ба шумо имкон медиҳад, ки безанед дидгаҳҳои асосшаванда ва марамқул. Салоҳияти таҳлилкунӣи шумо кӯмак мебарояд оидаба корҳои иттилоотӣ мебошанд ва стратегияҳои самараҳо таҳия кунанд.",
            "Системагар":
                "Маҳалшинокии шумо барои интизом, тартиб ва систематизатсия, шуморо систематизатори аъла мебахшад. Тавонаии шумо барои калима додани иттилоот, пайдо кардани роҳҳо ва сохтани системаҳои вазеҳ ва мантиқӣ, шуморо тавона мебахшад, ки бадазро ба тартиб табдил кунед. Ин созмонҳоро кӯмак мекунад, ки равандҳои худро ислоҳ кунанд, самаранокии худро афзоиш диҳанд ва продуктивитетро афзоиш диҳанд.",
            "Комилгарой":
                "Ҷариияти шумо ба покистон, дастовардилии шумо ва назар шумо ба дотох бурдан, шуморо перфекционисти идеалӣ мебахшад. Шумо то вақтии ки кор ба беҳтарин натиҷа расонеда нестанед. Миёдонҳои алии шумо ва қуиши бемарзи шумо барои кайфият, ба тамоми лоиҳаҳои шумо асорат мебаранд, ки ба натиҷаи мубозир дар форми кори бо фаолият нишон медиҳад.",
            "Рационализатор":
                "Тавонаии шумо барои таҳлил ва оптимизатсия, шуморо инноватори аъла мебахшад. Шумо ҳамеша роҳҳо мепайдоед, ки чизҳо ба иқтисодӣ ва самаранок гардонед. Ишқи шумо барои оптимизатсия ва салоҳияти шумо барои табдил додани печида ба содона, созмонҳоро кӯмак мекунад ба афзоиши муассасаи худ ва ислоҳ кардани кайфияти хизматрасониҳо ва маҳсулот.",
            "Тадбиркори новатор":
                "Тавонаии шумо барои дидани акси калон, пайдо кардани фурсатҳо, ҳаҷе ки дигарон намебинанд, ва кушодаи шумо ба идеаҳои нав, шуморо инноватори корхона мебахшад. Шумо мо ҳаҷе істақлолгоҳи риск, ва ислохи шумо ва энергия, шуморо кӯмак мекунад, идеаҳоро ба воқеи мебарояд. Менталитети корхонаии шумо метавонад катализатори рӯиши ва инновациониро дар ҳар корхонӣ ё лоиҳа бошад.",
            "Алгоритмист":
                "Тавонаии шумо барои пайда кардани иттилоот, сохтани алгоритмҳо ва созмондани донои, шуморо алгоритмӣ мохассис мебахшад. Шумо логика ва тартибро ба ҳар раҷи, пешниҳод мекунед, ки созмонҳоро ба кори самаровартар ва пешбинишаванда мебарояд. Фикркунӣи алгоритморо ба афзоиши продуктивитет ва кайфияти кор мебарояд.",
            "Интегратор":
                "Тавонаии шумо барои пайвастани одамон, идеаҳо ва сарвасиятшавии, шуморо таъриф мекунад ба одоби интегратор. Шумо метавонед бебинед, ки чӣ гуна элементҳо гузошта мешаванд ва ҳамдигарро таъсири мекунанд, ки ба шумо имкон мебахшад, ки гурӯҳҳои бештар ва самарагузарторо сохта бошед. Илтидоди шумо ба интеграция, созмонҳоро кӯмак мекунад синергияҳо эҷод кунанд ва ба киши мебахшанд.",
            "Таҷрибагар":
                "Маҳалшинокии шумо барои санҷиши гипотеза, санҷиши ифода ва такомили беҳтарин, шуморо эксперименткунадаи маҳорати мебахшад. Шумо аз хотиракунӣ наметарсед ва онҳоро ба хато мебароред. Тавонаии шумо барои эксперименткунӣ ва сабр шумо барои ноумедӣ, метавонад созмонҳоро кӯмак кунад пешрасонӣ ва иваз кунад дар рӯбарҳои тағйировоти давомпазир.",
        },
    },
    WelcomeSubscribe: {
        title: "Ман шуморо ташвиқ мекунам, кӯмак мекунам, то муваффақият барсанед ва дар соҳаи интихобшуда пул бигиред",
        subtitle:
            "Қабл аз он, ки ба профили худ бегиред - барои шумо ҳамин ҳол дарози зиёдӣ мавҷуд аст<br/><br/> он чизҳое, ки ан мишавад:",
        pay: "Ба ҳама чизҳо барои {price} RUB/маҳ дастрасӣ пайдо кунед",
        "to-profile": "Ба профил равед",
        list: [
            {
                id: 1,
                icon: "people-recommendations",
                text: "Ҳар рӯз 5 корбар аз сайт ба шумо пешниҳод карда мешавад, ки бо онҳо совъ тиҷорати қисматдороне доред.",
            },
            {
                id: 2,
                icon: "text-recommendations",
                text: "Таҳлили қатъӣ дар бораи психотипи шумо ва тафсири он барои шумо пайдо кунед.",
            },
            {
                id: 3,
                icon: "brain",
                text: "Тавсифи шахсияти ва рафтари шуморо гиред.",
            },
            {
                id: 4,
                icon: "environment",
                text: "Бояд бештар аз 10 пешниҳод ва тавсияҳо барои психотипи шумо барои рушидани шумо дар ин бизнес",
            },
            {
                id: 5,
                icon: "stars",
                text: "Шумо ба пойгоҳи зери навбаҳои намдидагӣ дастрасӣ хоҳед дорад ва имконияти воқеи ё иштроки ё омӯзиши хулқи онҳоро дорад.",
            },
            {
                id: 6,
                icon: "photo",
                text: "Шумо метавонед ҳар шахсе дар сайт ва ҳатто барои ҳамсовигӣ тиҷоратӣ дар сайт ва ҳатто вақардо бубинед.",
            },
        ],
    },
};
